import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 2fr;
  gap: 3rem;
`;

export const StyledImageSection = styled.div`
  position: relative;
  width: 100%;
  
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;

export const StyledInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledArtist = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  color: ${({ theme }) => theme.TextColorPrimary};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
`;

export const StyledTitle = styled.h2`
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  margin: 0;
  color: ${({ theme }) => theme.TextColorPrimary};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
`;

export const StyledDateLocation = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.TextColorPrimaryLighter};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
`;

export const StyledPriceSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem 0;
  border-top: 1px solid ${({ theme }) => theme.BorderColor};
  border-bottom: 1px solid ${({ theme }) => theme.BorderColor};
`;

export const StyledPriceRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledPriceLabel = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.TextColorPrimaryLighter};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
`;

export const StyledPrice = styled.span`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.TextColorPrimary};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
`;

export const StyledEstimateChange = styled.span<{ isPositive: boolean }>`
  color: ${({ isPositive }) => isPositive ? '#00A651' : '#E0426F'};
  font-size: 0.875rem;
  margin-left: 0.5rem;
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
`;

export const StyledDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem 2rem;
  font-size: 0.875rem;
`;

export const StyledDetailLabel = styled.div`
  color: ${({ theme }) => theme.TextColorPrimaryLighter};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
`;

export const StyledDetailValue = styled.div`
  color: ${({ theme }) => theme.TextColorPrimary};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
  line-height: 1.4;
`;
