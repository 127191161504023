import { View } from "../../View"
import { StyledShowHeader } from "./styled"
import {ReactComponent as CloseIcon} from '../../../icons/close.svg'
import { Image } from "../../Image"
import { Button } from "../../Button"
import { useFullScreen, usePostStore } from "../../../stores/web.store"
import { useState, useEffect } from "react"
import { Text } from "../../Text"
import { ReactComponent as ChevronUpIcon } from '../../../icons/chevronUp.svg'
import { ReactComponent as ChevronDownIcon } from '../../../icons/chevronDown.svg'
import { ReactComponent as ChevronLeftIcon } from '../../../icons/chevronLeft.svg'
import { ReactComponent as ChevronRightIcon } from '../../../icons/chevronRight.svg'
import { ReactComponent as SlideIcon } from '../../../icons/slide.svg'
import { useThemeStore } from "../../../stores/theme.store"
import { ProfilePicture } from "../../ProfilePicture"
import { toCurrency, toDate } from "../../../utils/app.util"


const ShowHeader = ({post}: any) => {
    const setPost = usePostStore((state: any) => state.setPost)
    const setFullScreenView = useFullScreen((state: any) => state.setFullScreenView)
    const onClickClose = () => {
        setPost(null)
        setFullScreenView(null)
    }
    return (
        <StyledShowHeader>
            <View flexGrow="1">
                <b>{'Kate Smith '}</b><span>{`Here today, Gone`}</span><b>{` ${toCurrency(post.metadata.price)}`}</b>
            </View>
            <View width="max-content">
                <Button noStyle onClick={onClickClose}><CloseIcon /></Button>
            </View>
        </StyledShowHeader>
    )
}

const ProductDetails = ({post}: any) => {
    const [isExpanded, setIsExpanded] = useState(false);

    console.log(post)

    return (
        <View 
            position="absolute" 
            bottom="12px" 
            left="24px" 
            right="0" 
            background="white" 
            borderRadius="16px" 
            width="628px"
        >
            <View padding="24px">
                <View display="flex" flexDirection="column" gap="16px" width="100%">
                    <View display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
                        <View display="flex" flexDirection="column" gap="12px">
                            <View textAlign="left">
                                <ProfilePicture account={post.owner} username fontSize="20px" />
                                <Text 
                                    fontSize="16px" 
                                    color="gray.600" 
                                    style={{ fontStyle: 'italic' }}
                                >{`${post.title}, ${toDate(post.artwork_date || post.created_date, true)}`}</Text>
                            </View>
                        </View>
                        <Button noStyle onClick={() => setIsExpanded(!isExpanded)}>
                            <View style={{ transform: `rotate(${isExpanded ? 180 : 0}deg)`, transition: 'transform 0.3s ease' }}>
                                <ChevronDownIcon />
                            </View>
                        </Button>
                    </View>
                    
                    <View 
                        style={{
                            transform: `translateY(${isExpanded ? '0' : '-10px'})`,
                            opacity: isExpanded ? 1 : 0,
                            visibility: isExpanded ? 'visible' : 'hidden',
                            height: isExpanded ? 'auto' : '0',
                            transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
                            pointerEvents: isExpanded ? 'auto' : 'none'
                        }}
                    >
                        <View textAlign="left" display="flex" flexDirection="column" gap="8px">
                            <Text>Glass tile mounted on maple in maple float frame</Text>
                            <Text>48 x 40 in 1 121.9 x 101.6 cm</Text>
                            <Text>Unique work</Text>
                            <Text>Includes a Certificate of authenticity</Text>
                        </View>
                    </View>
                    {isExpanded && <View textAlign="left" display="flex" flexDirection="column" gap="8px">
                        <View>{toCurrency(post.metadata.price)}</View>
                        <Button 
                            background="black" 
                            color="white" 
                            width="100%"
                            padding="12px"
                            borderRadius="24px"
                            onClick={(e: any) => {
                                e.stopPropagation();
                                // Add make offer handler here
                            }}
                        >
                            Purchase
                        </Button>
                        <Button 
                            background="white" 
                            color="black" 
                            border="1px solid black"
                            width="100%"
                            padding="12px"
                            borderRadius="24px"
                            onClick={(e: any) => {
                                e.stopPropagation();
                                // Add make offer handler here
                            }}
                        >
                            Make An Offer
                        </Button>
                    </View>}
                </View>
            </View>
        </View>
    )
}

// Gallery item component with proper handling of selection state
const GalleryItem = ({ item, activePost, onSelect }: any) => {
    const isSelected = item.id === activePost?.id
    
    return (
        <Button 
            noStyle
            onClick={() => onSelect(item)}
            border={isSelected ? "2px solid black" : "none"}
            borderRadius="4px"
            cursor="pointer"
            padding="0"
        >
            <Image 
                src={item.media?.length > 0 ? item.media[0].url : ""} 
                width="80px" 
                height="80px" 
                objectFit="cover"
            />
        </Button>
    )
}

const ShowController = ({activePost, setActivePost}: any) => {
    const theme = useThemeStore((state: any) => state.theme)
    const post = usePostStore((state: any) => state.post)
    const [showGallery, setShowGallery] = useState(false)
    
    // Handle navigation between posts
    const handlePrevPost = () => {
        if (post?.posts && post.posts.length > 0 && activePost) {
            const currentIndex = post.posts.findIndex((p: any) => p.id === activePost.id)
            if (currentIndex > 0) {
                const prevPost = post.posts[currentIndex - 1]
                setActivePost(prevPost)
            }
        }
    }
    
    const handleNextPost = () => {
        if (post?.posts && post.posts.length > 0 && activePost) {
            const currentIndex = post.posts.findIndex((p: any) => p.id === activePost.id)
            if (currentIndex < post.posts.length - 1) {
                const nextPost = post.posts[currentIndex + 1]
                setActivePost(nextPost)
            }
        }
    }
    
    const toggleGallery = () => {
        setShowGallery(!showGallery)
    }
    
    const handleSelectPost = (item: any) => {
        setActivePost(item)
        setShowGallery(false)
    }
    
    // Calculate current index for button disabled states
    const currentIndex = post?.posts?.findIndex((p: any) => p.id === activePost?.id) || 0
    const hasPosts = post?.posts && post.posts.length > 0
    const isPrevDisabled = !hasPosts || currentIndex <= 0
    const isNextDisabled = !hasPosts || currentIndex >= (post.posts.length - 1) || currentIndex === -1
    
    return (
        <View 
            position="absolute" 
            bottom="12px" 
            right="24px" 
            display="flex" 
            alignItems="center" 
            gap="8px"
            background={theme.CardPrimaryBackgroundColor}
            padding="8px 12px"
            borderRadius="8px"
        >
            <Button 
                noStyle 
                onClick={handlePrevPost}
                disabled={isPrevDisabled}
                opacity={isPrevDisabled ? 0.5 : 1}
            >
                <ChevronLeftIcon />
            </Button>
            
            <Button 
                noStyle 
                onClick={handleNextPost}
                disabled={isNextDisabled}
                opacity={isNextDisabled ? 0.5 : 1}
            >
                <ChevronRightIcon />
            </Button>
            
            {/* Separator line */}
            <View 
                height="24px" 
                width="1px" 
                background="#E0E0E0" 
                margin="0 4px"
            />
            
            <Button noStyle onClick={toggleGallery}>
                <SlideIcon />
            </Button>
            
            {showGallery && (
                <View 
                    position="absolute" 
                    bottom="48px" 
                    right="0" 
                    background="white" 
                    borderRadius="8px" 
                    padding="16px"
                    boxShadow="0 4px 12px rgba(0,0,0,0.1)"
                    width="max-content"
                >
                    <View>
                        <Text fontSize="14px" fontWeight="600" margin="0px 0px 8px 0px">
                            {post?.posts?.length || 0} shows
                        </Text>
                        <View 
                            display="flex" 
                            gap="8px" 
                            overflowX="auto" 
                            maxWidth="600px"
                            padding="4px"
                        >
                            {post?.posts?.map((item: any, index: number) => (
                                <GalleryItem 
                                    key={item.id || index}
                                    item={item}
                                    activePost={activePost}
                                    onSelect={handleSelectPost}
                                />
                            ))}
                        </View>
                    </View>
                </View>
            )}
        </View>
    )
}

const PostImage = ({post}: any) => {
    return (
        <Image 
            src={post.media?.length > 0 ? post.media[0].url : ''}
            width="100%"
            height="100%"
            objectFit="contain"
            enablePanZoom={true}
        />
    )
}

const Show2D = () => {
    const post = usePostStore((state: any) => state.post)
    const [activePost, setActivePost] = useState(null)

    useEffect(() => {
        if (post?.posts) {
            setActivePost(post.posts[0])
        }
    }, [post])
    
    if (!activePost) return <div />
    return (
        <View position="relative" height="100vh">
            <View display="flex" flexDirection="column" gap="0" width="100%" height="100%">
                <View padding="24px">
                    <ShowHeader post={activePost}/>
                </View>
                <View flexGrow="1">
                    <PostImage post={activePost} />
                </View>
            </View>
            <ProductDetails post={activePost}/>
            <ShowController setActivePost={setActivePost} activePost={activePost}/>
        </View>
    )
}

export default Show2D
