import styled from 'styled-components'


interface DashboardLayoutProps {
    theme: any;
    isModal?: boolean;
}


export const StyledDashboardLayout = styled.div<DashboardLayoutProps>`
    min-height: 100vh;
    width: 100%;
    background: ${props => props.theme.BackgroundColorTheme};
    overflow: hidden;
    font-family: ${props => props.theme.FontFamilyPrimary};
    position: relative;

    ${props => props.isModal && `
        height: 100vh;
    `}
`

interface DashboardContainerProps {
    isModal?: boolean;
    isExpanded?: boolean;
    isCreate?: boolean;
    overflow?: string;
    height?: string;
}


export const StyledDashboardContainer = styled.div<DashboardContainerProps>`
    width: 100%;
    margin: 0;

    @media (min-width: 768px) {
        margin: ${props => props.isCreate ? '0' : '0px 0px 0px 284px'};
        width: ${props => props.isCreate ? '100%' : 'calc(100% - 284px)'};
        height: 100vh;
        display: flex;
        flex-direction: column;
        gap: 0px;
        overflow-x: hidden;
        overflow-y: ${props => props.isModal ? 'hidden' : props.overflow || 'auto'};
        position: relative;
        
    }

    @media (min-width: 1024px) {

    }
    
`

interface DashboardProps {
    
}


export const StyledDashboard = styled.div<DashboardProps>`
    
`

interface HeaderContainerProps {
    theme: any;
}

export const StyledHeaderContainer = styled.div<HeaderContainerProps>`
    background: ${props => props.theme.CardPrimaryBackgroundColor};
    border-bottom: solid 1px ${props => props.theme.BorderColor};

    @media (min-width: 768px) {
        padding: 0px;
    }

    @media (min-width: 1024px) {

    }
`

interface HeaderProps { 
    padding?: string;
}

export const StyledHeader = styled.div<HeaderProps>`
    display: inline-flex;
    gap: 24px;
    width: 100%;
    width: max-content;
    padding: ${props => props.padding || '0px 24px'};
`