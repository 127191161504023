import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
`;

export const StyledTitle = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.TextColorPrimary};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
  font-weight: 400;
  margin: 0;
`;

export const StyledResultCount = styled.span`
  color: ${({ theme }) => theme.TextColorPrimaryLighter};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
  font-size: 0.875rem;
  margin-top: 0.25rem;
  display: block;
`;

export const StyledSortSelect = styled.select`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  background: ${({ theme }) => theme.BackgroundColorTheme};
  color: ${({ theme }) => theme.TextColorPrimary};
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
  cursor: pointer;
  outline: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
  padding-right: 2.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.HoverPrimaryBackgroundColor};
  }
`;

export const StyledAuctionGrid = styled.div`
  display: grid;
  gap: 1rem;
  padding: 0.5rem;
`;

export const StyledAuctionItem = styled.div`
  display: grid;
  width: 100%;  
  grid-template-columns: 200px 1fr auto;
  gap: 2rem;
  padding: 1.5rem;
  text-align: left;
  background: ${({ theme }) => theme.CardPrimaryBackgroundColor};
  border-radius: 12px;
  position: relative;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02), 
              0 4px 8px rgba(0, 0, 0, 0.02);

  &:nth-child(even) {
    background: ${({ theme }) => theme.BackgroundColorTheme};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.BorderColor};
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 
                0 8px 16px rgba(0, 0, 0, 0.04);
  }
`;

export const StyledImageContainer = styled.div`
  position: relative;
  width: 200px;
  height: 150px;
  min-height: 150px;
  border-radius: 8px;
  overflow: hidden;
  background: ${({ theme }) => theme.BackgroundColorTheme};
  flex-shrink: 0;
`;

export const StyledArtworkImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: ${({ theme }) => theme.BackgroundColorTheme};
`;

export const StyledArtworkInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledArtworkTitle = styled.h2`
  font-size: 1rem;
  font-style: italic;
  margin: 0;
  color: ${({ theme }) => theme.TextColorPrimary};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
  font-weight: 400;
`;

export const StyledArtworkDetails = styled.div`
  color: ${({ theme }) => theme.TextColorPrimaryLighter};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
  font-size: 0.875rem;
  line-height: 1.4;
  
  div {
    margin-bottom: 0.25rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledSaleInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 200px;
  text-align: right;
`;

export const StyledPrice = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.TextColorPrimary};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
`;

export const StyledEstimate = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.TextColorPrimaryLighter};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
`;

export const StyledEstimateChange = styled.div<{ isPositive: boolean }>`
  font-size: 0.875rem;
  color: ${({ isPositive }) => isPositive ? '#00A651' : '#E0426F'};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
`;

export const StyledSaleDate = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.TextColorPrimaryLighter};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
`;

export const StyledLotNumber = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.TextColorPrimaryLighter};
  font-family: ${({ theme }) => theme.FontFamilyPrimary};
  margin-top: 0.25rem;
`;

export const StyledAuctionHouse = styled.div`
  font-size: 13px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.TextColorPrimaryLighter};
  line-height: 1.4;
`;