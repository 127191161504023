import styled from "styled-components"


interface FeedNavigationContainerProps {
    theme: any;
    isActive?: boolean;
}

export const StyledFeedNavigationContainer = styled.div<FeedNavigationContainerProps>`
    display: ${props => props.isActive ? 'block' : 'none'};
    background: ${props => props.theme.CardPrimaryBackgroundColor};
    border-bottom: solid 1px ${props => props.theme.BorderColor};

    @media (min-width: 768px) {
        padding: 0px;
        display: block;
    }

    @media (min-width: 1024px) {

    }
`

interface FeedNavigationProps {

}

export const StyledFeedNavigation = styled.div<FeedNavigationProps>`
    display: inline-flex;
    gap: 24px;
    width: 100%;
    width: max-content;
`