import styled, { keyframes } from "styled-components"
import { animated } from 'react-spring';


interface FeedPostContainerProps {
    theme: any;
}

export const StyledFeedPostContainer = styled.div<FeedPostContainerProps>`
    background: ${props => props.theme.CardPrimaryBackgroundColor};
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px rgba(213, 213, 205, 0.25);

`

interface FeedPostProps {

}

export const StyledFeedPost = styled.div<FeedPostProps>`

`
 interface MobileCommentsContainerProps {
    isActive: boolean;
 }

export const StyledMobileCommentsContainer = styled.div<MobileCommentsContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${props => props.isActive ? '100%' : '0'};
    background: rgba(0, 0, 0, 0.5);
    z-index: 4;
    overflow: hidden;
`;

export const StyledMobileCommentHeader = styled.div`
    padding: 16px;
    border-bottom: solid 1px #EAEAEA;
`

export const StyledSettingsIconContainer = styled.div<{ isActive?: boolean }>`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    background-color: ${props => props.isActive ? 'rgba(0, 0, 0, 0.05)' : 'transparent'};
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    
    svg {
        width: 20px;
        height: 20px;
    }
`

interface MobileCommentsProps {
    isActive: boolean;
}

export const StyledMobileComments = styled(animated.div)<MobileCommentsProps>`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: ${props => props.isActive ? '80%' : '0'};
    background: rgba(255, 255, 255, 0.95);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 5;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
`
