import styled from "styled-components"


interface FeedContainerProps {

}

export const StyledFeedContainer = styled.div<FeedContainerProps>`
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    overflow: scroll;


    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
`

interface FeedProps {

}

export const StyledFeed = styled.div<FeedProps>`
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100vh;
    overflow-y: scroll;

    @media (min-width: 768px) {
        gap: 24px;
    }

    @media (min-width: 1024px) {

    }
    
`