import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import {Artworks as ArtworksSection} from '../../sections/Artworks'
import { useArtworks } from "../../hooks/api.hook"
import { useEffect, useState } from "react"
import { useAccountStore, useFiltersStore } from "../../stores/web.store"
import { View } from "../../components/View"
import api from "../../services/api.service"
import { Filters } from "../../components/Filters"
import { StyledHeader } from "./styled"
import { Sort } from "../../components/Sort"


const FilterSort = () => {
    return (
        <StyledHeader>
            <Filters allFilters/>
            <Sort />
        </StyledHeader>
    )
}


const Artworks = () => {
    const account = useAccountStore((state: any) => state.account) 
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const [apiResp, setApiResp] = useState<any>({})
    const [artworks, setArtworks] = useState<any>({})

    const {data} = useArtworks({query: {}})

    useEffect(() => {
        if (data && data.is_loaded) {
            setApiResp(data)
            setArtworks(data.data)
        }
    }, [data])
    

    useEffect(() => {
        const fetch = async () => {
            const {query, filters} = filterStore
            const resp = await api.post('/search/artworks', {query: {query, ...filters}})
            setArtworks(resp.data)
            setFilterStore({...filterStore, loaded: false})
        }

        if (filterStore.loaded)
            fetch()
    }, [filterStore])

    if (account === null) return <div />

    if (!apiResp.is_loaded) return <div />

    return (
        <DashboardLayout header={<FilterSort/>}>
            <ArtworksSection data={artworks} isResultsPage/>
        </DashboardLayout>
    )
}

export default Artworks