import styled, { keyframes, css } from "styled-components";

interface StoriesContainerProps {
  theme: any;
}

interface StoryItemProps {
  theme: any;
  hasUnseenStory?: boolean;
}

interface StoryImageProps {
  isActive: boolean;
  direction: 'next' | 'prev' | 'none';
  state: 'entering' | 'exiting' | 'active';
}

export const StyledStoriesContainer = styled.div<StoriesContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  overflow-x: auto;
  padding: 0 16px;
  
  /* Hide scrollbar but keep functionality */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;

export const StyledStoriesWrapper = styled.div<StoriesContainerProps>`
  position: relative;
  width: 100%;
  padding: 8px 0;
`;

export const StyledScrollButtonContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledStoriesScrollButton = styled.div<{ direction: 'left' | 'right'; theme: any }>`
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #0a0a0a;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  svg {
    width: 20px;
    height: 20px;
    stroke: #fff;
  }
`;

export const StyledStoryItem = styled.div<StoryItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

export const StyledStoryRing = styled.div<StoryItemProps>`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.hasUnseenStory 
    ? `linear-gradient(45deg, ${props.theme.PrimaryYellow}, #FF5E3A)` 
    : props.theme.BorderColor};
  padding: 2px;
`;

export const StyledStoryImage = styled.div<StoryItemProps>`
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background: ${props => props.theme.CardPrimaryBackgroundColor};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledStoryUsername = styled.div<StoryItemProps>`
  font-size: 12px;
  color: ${props => props.theme.TextColorPrimary};
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const StyledStoryViewerContainer = styled.div<StoriesContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledStoryContent = styled.div<StoriesContainerProps>`
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 100%;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  
  @media (min-width: 768px) {
    max-width: 400px;
  }
`;

export const StyledStoryHeader = styled.div<StoriesContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  color: white;
  z-index: 10;
`;

export const StyledStoryProgressContainer = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
  padding: 8px 16px;
  z-index: 10;
`;

export const StyledStoryProgressBar = styled.div<{ isActive: boolean; isPast: boolean }>`
  height: 2px;
  flex-basis: 0;
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.3);
  background: ${props => 
    props.isPast 
      ? 'rgba(255, 255, 255, 0.8)' 
      : 'rgba(255, 255, 255, 0.3)'
  };
  overflow: hidden;
`;

// Cube rotation animations
const rotateCubeToLeft = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(90deg);
  }
`;

const rotateCubeFromLeft = keyframes`
  0% {
    transform: rotateY(-90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

const rotateCubeToRight = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(-90deg);
  }
`;

const rotateCubeFromRight = keyframes`
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

export const StyledStoryImage2 = styled.div<StoryImageProps>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  position: relative;
  
  img, video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    
    ${props => {
      if (props.state === 'active') {
        return css`
          animation: none;
        `;
      }
      
      if (props.direction === 'next') {
        if (props.state === 'entering') {
          return css`
            animation: ${rotateCubeFromRight} 0.4s forwards;
          `;
        }
        if (props.state === 'exiting') {
          return css`
            animation: ${rotateCubeToLeft} 0.4s forwards;
          `;
        }
      }
      
      if (props.direction === 'prev') {
        if (props.state === 'entering') {
          return css`
            animation: ${rotateCubeFromLeft} 0.4s forwards;
          `;
        }
        if (props.state === 'exiting') {
          return css`
            animation: ${rotateCubeToRight} 0.4s forwards;
          `;
        }
      }
      
      return css`
        animation: none;
      `;
    }}
  }
`;

export const StyledStoryControls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 5;
`;

export const StyledStoryControlPrev = styled.div`
  width: 30%;
  height: 100%;
  cursor: pointer;
`;

export const StyledStoryControlNext = styled.div`
  width: 70%;
  height: 100%;
  cursor: pointer;
`;

export const StyledStoryCreationContainer = styled.div<StoriesContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.CardPrimaryBackgroundColor};
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

export const StyledStoryCreationHeader = styled.div<StoriesContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: solid 1px ${props => props.theme.BorderColor};
`;

export const StyledStoryCreationContent = styled.div<StoriesContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const StyledStoryCreationFooter = styled.div<StoriesContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-top: solid 1px ${props => props.theme.BorderColor};
`;

export const StyledStoryAddButton = styled.div<StoryItemProps>`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.CardSecondaryBackgroundColor};
  border: 1px dashed ${props => props.theme.BorderColor};
  cursor: pointer;
`;
