import { Button } from "../../Button"
import { ReactComponent as FilterIcon } from '../../../icons/filter.svg';
import { StyledAllFilters, StyledAllFiltersContainer } from "./styled"
import { Text } from "../../Text";
import { useSlideMenuStore } from "../../../stores/web.store";


const AllFilters = ({iconOnly}: any) => {
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const setSlideMenu = useSlideMenuStore((state: any) => state.setSlideMenu)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)

    const onClick = () => {
        if (slideMenu.isActive) {
            resetSlideMenu()
        } else {
            setSlideMenu({...slideMenu, isActive: true, key: 'filters'})
        }
    }

    return (
        <StyledAllFiltersContainer>
            <StyledAllFilters>
                <Button 
                    noStyle 
                    borderRadius="24px" 
                    color="#737373"
                    fontSize="14px"
                    border={iconOnly ? "solid 1px rgba(0, 0, 0, 0)" : "solid 1px #e5e5e5" }
                    padding={iconOnly ? "0" : "8px 12px"}
                    gap="12px" 
                    onClick={onClick}
                >
                    {!iconOnly && <Text>Filters</Text>}
                    <FilterIcon />
                </Button>
            </StyledAllFilters>
        </StyledAllFiltersContainer>
    )
}

export default AllFilters