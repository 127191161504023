import { View } from "../../View"
import { 
    StyledAccountActionsContainer, 
    StyledAccountActions, 
    StyledAccountActionItem, 
    StyledAccountActionText,
    StyledAccountActionDanger
} from "./styled"
import { Button } from "../../Button"
import { useModalStore } from "../../../stores/web.store"
import { ReactComponent as ShareIcon } from "../../../icons/shareTo.svg"
import { ReactComponent as AccountIcon } from "../../../icons/account.svg"
import { ReactComponent as ReportIcon } from "../../../icons/report.svg"
import { ReactComponent as BlockIcon } from "../../../icons/block.svg"
import { ReactComponent as RestrictIcon } from "../../../icons/restrict.svg"

const AccountActions = ({setIsModal}: any) => {
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    
    return (
        <StyledAccountActionsContainer>
            <StyledAccountActions>
                <Button noStyle>
                    <StyledAccountActionItem>
                        <ShareIcon />
                        <StyledAccountActionText>Share to</StyledAccountActionText>
                    </StyledAccountActionItem>
                </Button>
                
                <Button 
                    noStyle 
                    onClick={() => {
                        setModalKey('accountAbout');
                        if (setIsModal) setIsModal(false);
                    }}
                >
                    <StyledAccountActionItem>
                        <AccountIcon />
                        <StyledAccountActionText>About this account</StyledAccountActionText>
                    </StyledAccountActionItem>
                </Button>
                
                <Button noStyle>
                    <StyledAccountActionItem>
                        <ReportIcon />
                        <StyledAccountActionText>Report</StyledAccountActionText>
                    </StyledAccountActionItem>
                </Button>
                
                <Button noStyle>
                    <StyledAccountActionItem>
                        <BlockIcon />
                        <StyledAccountActionDanger>Block</StyledAccountActionDanger>
                    </StyledAccountActionItem>
                </Button>
                
                <Button noStyle>
                    <StyledAccountActionItem>
                        <RestrictIcon />
                        <StyledAccountActionText>Restrict</StyledAccountActionText>
                    </StyledAccountActionItem>
                </Button>
            </StyledAccountActions>
        </StyledAccountActionsContainer>
    )
}

export default AccountActions