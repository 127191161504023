import { ReactComponent as ChevronUp } from '../../icons/chevronUp.svg';
import { ReactComponent as ChevronDown } from '../../icons/chevronDown.svg';
import { Button } from '../Button';
import { StyledNavigator, StyledNavigatorButton } from './styled';
import { useThemeStore } from '../../stores/theme.store';

interface PostNavigatorProps {
  posts: any[];
  currentIndex: number;
  height?: string;
  onNavigate: (index: number) => void;
}

const PostNavigator = ({ posts, currentIndex, height, onNavigate }: PostNavigatorProps) => {
  const theme = useThemeStore((state: any) => state.theme)
  const hasPrevious = currentIndex > 0;
  const hasNext = currentIndex < posts.length - 1;

  return (
    <StyledNavigator height={height}>
      <StyledNavigatorButton position="top">
        <Button
          noStyle
          onClick={() => hasPrevious && onNavigate(currentIndex - 1)}
          opacity={hasPrevious ? 1 : 0.5}
          disabled={!hasPrevious}
          padding="4px"
          background={theme.ButtonBackgroundPrimaryLight}
          borderRadius="50%"
        >
          <ChevronUp />
        </Button>
      </StyledNavigatorButton>
      <StyledNavigatorButton position="bottom">
        <Button
          noStyle
          onClick={() => hasNext && onNavigate(currentIndex + 1)}
          opacity={hasNext ? 1 : 0.5}
          disabled={!hasNext}
          padding="4px"
          background={theme.ButtonBackgroundPrimaryLight}
          borderRadius="50%"
        >
          <ChevronDown />
        </Button>
      </StyledNavigatorButton>
    </StyledNavigator>
  );
};

export default PostNavigator;
