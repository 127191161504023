import styled from "styled-components";


interface ButtonsProps {
    flexDirection?: string;
    gap?: string;
    justifyContent?: string;
    height?: string;
}

export const StyledButtons = styled.div<ButtonsProps>`
    display: flex;
    flex-direction: ${props => props.flexDirection || 'row'};
    gap: ${props => props.gap || '16px'};

    ${props => props.height && `height: ${props.height};`}
    ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
`