import styled from 'styled-components'


interface ExploreContainerProps {
    width: number;

}

export const StyledExploreContainer = styled.div<ExploreContainerProps>`
    display: flex;
    flex-direction: column;
    gap: ${props => props.width > 768 ? "32px" : "4px"};
    overflow: hidden;
`


interface ExplorePostsContainerProps {
    height?: string;
    overflow?: string
    ref?: any;
    theme: any;
}


export const StyledExplorePostsContainer = styled.div<ExplorePostsContainerProps>`
    padding: 0px 0px;
    overflow-y: ${props => props.overflow || 'auto'};
    height: ${props => props.height || 'auto'};
    box-shadow: 0px 0px 8px 0px rgba(213, 213, 205, 0.25);
    background: ${props => props.theme.CardPrimaryBackgroundColor};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 768px) {
        padding: 24px;
        
    }

    @media (min-width: 1024px) {

    }
`

interface ExplorePostsProps {

}


export const StyledExplorePosts = styled.div<ExplorePostsProps>`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 27px;
    width: calc(100% - 12px);

    @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    @media (min-width: 1024px) {

    }
`

interface ShowMorePostsProps {

}


export const StyledShowMorePosts = styled.div<ShowMorePostsProps>`
    text-align: center;
    width: 100%;
    padding: 0px 0px 28px 0px;
`

interface QuickFiltersContainerProps {

}


export const StyledQuickFiltersContainer = styled.div<QuickFiltersContainerProps>`
    overflow-x: auto;
    padding: 4px 0px;
    @media (min-width: 768px) {
        display: block;
    }

    @media (min-width: 1024px) {

    }
`

export const StyledCategoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 24px;
  
  & > button {
    flex: 1;
    width: 100%;
  }
`;

export const StyledCategoryItem = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  color: ${props => props.isActive 
    ? (props.theme.mode === 'dark' ? '#FFFFFF' : '#000000') 
    : (props.theme.mode === 'dark' ? '#AAAAAA' : '#666666')};
  transition: color 0.2s ease;
  
  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
    opacity: ${props => props.isActive ? 1 : 0.7};
    transition: opacity 0.2s ease;
  }
  
  &:hover {
    color: ${props => props.theme.mode === 'dark' ? '#FFFFFF' : '#000000'};
    
    svg {
      opacity: 1;
    }
  }
`;
