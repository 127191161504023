import { useNavigate } from "react-router-dom"
import Button from "../Button"
import { useThemeStore } from "../../../stores/theme.store"

const Checkout = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const navigate = useNavigate()

    const onClick = async () => {
        navigate('/checkout')
    }

    return (
        <Button 
            width="100%" 
            height="42px" 
            borderRadius="42px" 
            fontWeight="500"
            fontSize="15px"
            onClick={onClick}
            background={theme.ButtonBackgroundPrimary}
            color={theme.TextColorOffset}
            border={`solid 1px ${theme.ButtonBackgroundPrimary}`}
        >Proceed to Checkout</Button>
    )
}

export default Checkout