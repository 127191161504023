import styled from 'styled-components'


interface DiscoverPeopleContainerProps {
    theme: any
}

export const StyledDiscoverPeopleContainer = styled.div<DiscoverPeopleContainerProps>`
    background: ${props => props.theme.CardPrimaryBackgroundColor};
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px rgba(213, 213, 205, 0.25);

`

interface DiscoverPeopleProps {

}

export const StyledDiscoverPeople = styled.div<DiscoverPeopleProps>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0px 24px;

    height: fit-content;

    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;

    @media (min-width: 768px) {
        height: calc(60vh - 120px);
        overflow-y: scroll;
    }

    @media (min-width: 1024px) {

    }
`


