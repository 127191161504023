import styled from 'styled-components'


interface InlineContainerProps {

}


export const StyledInlineContainer = styled.div<InlineContainerProps>`
    display: inline-flex;
    gap: 4px;
    width: 100%;
    align-items: center;
`

interface BoldProps {

}


export const Bold = styled.b<BoldProps>`
    
`

interface OLProps {

}


export const OL = styled.ol<OLProps>`
`

interface ULProps {
    color?: string;
    fontSize?: string;
}


export const UL = styled.ul<ULProps>`
    font-size: ${props => props.fontSize || '13px'};
    color: ${props => props.color || '#000'};
`

interface LIProps {
    padding?: string;
}


export const LI = styled.li<LIProps>`
    text-align: left;
    padding: ${props => props.padding || '12px 0px'};
`

interface FullScreenProps {
    isActive?: boolean;
    theme?: any;
}

export const FullScreen = styled.div<FullScreenProps>`
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: 9999;
    background: #fff;
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
    
    ${props => props.isActive && `
        transform: translateY(0);
    `}
`