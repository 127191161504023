import styled from 'styled-components'
import { useResponsify } from '../../utils/responsive.util';


interface InputContainerProps {

}


export const StyledInputContainer = styled.div<InputContainerProps>`
   position: relative;
   width: 100%;
   box-sizing: border-box;
`


interface InputProps {
    theme?: any;
    padding?: string;
    width?: string;
    background?: string;
    fontSize?: string;
    border?: string;
    borderBottom?: string;
    borderRadius?: string;
    invalid?: boolean;
    textIndent?: string;
    outline?: string;
    rounded?: boolean;
}

export const StyledInput = styled.input<InputProps>`
    font-family: ${props => props.theme.global.fontFamily};
    background: ${props => props.background || props.theme.input.background};
    padding: ${props => props.padding || props.theme.input.padding};
    width: ${props => props.width || '100%'};
    max-width: 100%;
    box-sizing: border-box;
    font-size: ${props => props.fontSize || props.theme.input.fontSize};
    border: ${props => props.border || props.theme.input.border};
    border-radius: ${props => props.borderRadius || props.theme.input.borderRadius};
    color: ${props => props.color || props.theme.input.color};
    text-indent: ${props => props.textIndent || '12px'};
    font-weight: 400;

    &:focus {
        outline: ${props => props.outline || props.border || props.theme.input.border};
        border: 1px solid rgba(255, 255, 255, 0);
    }

    &:disabled {
        background: #f2f2f2;
        color: #bfbfbf;
    }

    ${props => props.invalid && `
        border: solid 2px #EA4335;
    `}

    ${props => props.borderBottom && `
        border-bottom: ${props.borderBottom};
    `}

    ${props => props.rounded && `
        width:20px;
        height: 20px;
        border-radius: 50%;
        vertical-align: middle;
      
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
        scale: .7;

        &:checked {
            appearance: auto;
            clip-path: circle(50% at 50% 50%);
            background-color: #0938F0;
        }
    `}

    @media (min-height: 768px) {
        padding: ${props => props.padding || props.theme.input.padding};
    }

    @media (min-width: 1024px) {
    }
`

interface LabelProps {
    theme?: any;
    invalid?: boolean;
    isFocus?: boolean;
    value?: any;
    border?: string;
}


export const StyledLabel = styled.div<LabelProps>`
    position: absolute;
    top: -10px;
    left: 24px;
    padding: 0px 12px;
    background: ${props => (props.isFocus || props.value) ? '#fff' : 'none'};
    color: ${props => props.isFocus ? '#2f36b1' : props.border || props.theme.input.border};
    height: ${props => (props.isFocus || props.value) ? '24px' : '0px'};
    overflow: hidden;
    transition: all .2s ease-in;
    border-radius: 4px;
    font-size: 12px;

    ${props => props.invalid && !props.isFocus && `
        color: #EA4335;
    `}
`

interface ValidationMessageContainerProps {

}


export const StyledValidationMessageContainer = styled.div<ValidationMessageContainerProps>`
    position: absolute;
    left: 12px;
    bottom: -20px;

    @media (min-width: 768px) {
        left: 24px;
        bottom: -20px;
    }

    @media (min-width: 1024px) {
    }
`

interface ValidationMessageProps {

}


export const StyledValidationMessage = styled.div<ValidationMessageProps>`
    color: #EA4335;
    font-weight: 500;
    font-size: 13px;
`

interface PasswordValidationListProps {
    isVisible: boolean;
}

export const StyledPasswordValidationList = styled.div<PasswordValidationListProps>`
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    opacity: ${props => props.isVisible ? 1 : 0};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    transition: opacity 0.2s ease, visibility 0.2s ease;
`

interface PasswordCriteriaItemProps {
    isValid: boolean;
}

export const StyledPasswordCriteriaItem = styled.div<PasswordCriteriaItemProps>`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${props => props.isValid ? '#4CAF50' : '#787878'};
    font-size: 12px;
    margin-bottom: 4px;
    
    &:last-child {
        margin-bottom: 0;
    }

    &:before {
        content: '';
        width: 14px;
        height: 14px;
        background-image: url(${props => props.isValid ? 
            "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%234CAF50'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'/%3E%3C/svg%3E" :
            "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23787878'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3E%3C/svg%3E"
        });
        background-size: contain;
        background-repeat: no-repeat;
        flex-shrink: 0;
    }
`

interface SuccessCheckmarkProps {
    isVisible: boolean;
}

export const StyledSuccessCheckmark = styled.div<SuccessCheckmarkProps>`
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%234CAF50'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: ${props => props.isVisible ? 1 : 0};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    transition: opacity 0.2s ease, visibility 0.2s ease;
`

interface PasswordMatchIndicatorProps {
    isVisible: boolean;
    isMatching: boolean;
}

export const StyledPasswordMatchIndicator = styled.div<PasswordMatchIndicatorProps>`
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url(${props => props.isMatching ? 
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%234CAF50'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'/%3E%3C/svg%3E" :
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23EA4335'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E"
    });
    background-size: contain;
    background-repeat: no-repeat;
    opacity: ${props => props.isVisible ? 1 : 0};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    transition: opacity 0.2s ease, visibility 0.2s ease, background-image 0.2s ease;
`

interface ErrorCheckmarkProps {
    isVisible: boolean;
}

export const StyledErrorCheckmark = styled.div<ErrorCheckmarkProps>`
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23EA4335'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: ${props => props.isVisible ? 1 : 0};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    transition: opacity 0.2s ease, visibility 0.2s ease;
`