import { useEffect, useState } from "react"
import { View } from "../../components/View"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { useAccountStore, useFiltersStore, useFullScreen, usePostStore, useProductDetailPageStore, useViewNavigatorStore } from "../../stores/web.store"
import { useShows } from "../../hooks/api.hook"
import InfiniteScroll from "react-infinite-scroll-component"
import api from "../../services/api.service"
import { StyledShow, StyledShowDetails, StyledShows, StyledShowsContainer } from "./styled"
import { Post } from "../../components/Post"
import { Text } from "../../components/Text"
import { toDateRange } from "../../utils/app.util"
import { Buttons } from "../../components/Button/Buttons"
import Share from "../../components/Button/Share/Share"
import Like from "../../components/Button/Like/Like"
import { SaveButton } from "../../components/Button/Save"
import { Comment } from "../../components/Button/Comment"
import { ShowsNavigation } from "../../components/ShowsNavigation"
import { StyledHeader } from "../../sections/Layout/DashboardLayout/styled"
import { PageLoader } from "../../components/Loader/PageLoader"
import { useNavigate } from "react-router-dom"
import { PostNavigator } from "../../components/PostNavigator"


const ShowDetails = ({show}: any) => {
    return (
        <StyledShowDetails className="show-details">
            <View padding="24px">
                <View display="flex" flexDirection="column" gap="12px" width="70%">
                    <View>
                        <Text fontSize="20px" fontWeight="500">{show.title}</Text>
                    </View>
                    <View>
                        <Text fontSize="16px" fontStyle="italic">{show.caption}</Text>
                    </View>
                    <View>
                        <Text fontSize="14px">{toDateRange(show.metadata.start_datetime, show.metadata.end_datetime)}</Text>
                    </View>
                </View>
            </View>
        </StyledShowDetails>
    )
}

const ShowsHeader = () => {
    return (
        <StyledHeader padding="0px">
            <ShowsNavigation />
        </StyledHeader>
    )
}

const Shows = () => {
    const navigate = useNavigate()
    const setPost = usePostStore((state: any) => state.setPost)
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const setFullScreenView = useFullScreen((state: any) => state.setFullScreenView)
    const account = useAccountStore((state: any) => state.account) 
    const [apiResp, setApiResp] = useState<any>({})
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const {data} = useShows({query: {}})

    const [showPosts, setShowPosts] = useState<any>([])
    const [currentPostIndex, setCurrentPostIndex] = useState(0)

    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    

    useEffect(() => {
        if (data && data.is_loaded) {
            setApiResp(data)
            setShowPosts(data.data)
        }
        setActiveView('artists')
    }, [data])

    const fetchMoreData = async () => {
        const newOffset = offset + 1
        const resp = await api.post(`/search/shows`, {query: {}, offset: newOffset})

        setShowPosts([...showPosts, ...resp.data])
        setHasMore(resp.data.length > 0) 
        setOffset(newOffset);
    };

    const onClick = (post: any, index: number) => {
        setPost(post)
        console.log('click')
        setFullScreenView('show')
        setCurrentPostIndex(index)
    }

    const handleNavigate = (index: number) => {
        const post = showPosts[index]
        setPost(post)
        setCurrentPostIndex(index)
        const element = document.getElementById(`show-${index}`)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    useEffect(() => {
        const fetch = async () => {
            const {query, filters} = filterStore
            const resp = await api.post('/search/shows', {query: {query, ...filters}})
            setShowPosts(resp.data)
            setFilterStore({...filterStore, loaded: false})
        }

        if (filterStore.loaded)
            fetch()
    }, [filterStore])


    if (account === null) return <PageLoader />

    if (!apiResp.data) return <PageLoader />

    return (
        <DashboardLayout header={<ShowsHeader/>}>
            <View>
                <StyledShowsContainer id="Shows">
                    <InfiniteScroll
                        dataLength={showPosts.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<div />}
                        scrollableTarget="Shows"
                    >
                        
                        <StyledShows>
                            {showPosts.map((show: any, index: number) => {
                                return (
                                    <StyledShow key={show.id + index} id={`show-${index}`}>
                                        <Post 
                                            data={show} 
                                            type={show.type} 
                                            setPost={setPost} 
                                            onClick={() => onClick(show, index)} 
                                            borderRadius="10px" 
                                            height="85vh" 
                                            noIcon
                                        >
                                            <ShowDetails show={show}/>
                                        </Post>
                                        <View position="absolute" right="-40px" top="0">
                                            <Buttons flexDirection="column" justifyContent="center" height="80vh">
                                                <Like data={show} display="block"/>
                                                <SaveButton data={show} noTitle/>
                                                <Share data={show} noTitle/>
                                            </Buttons>
                                        </View>
                                        
                                    </StyledShow>
                                )
                            })}
                        </StyledShows>
                    </InfiniteScroll>
                    <PostNavigator
                        posts={showPosts}
                        currentIndex={currentPostIndex}
                        height="85vh"
                        onNavigate={handleNavigate}
                    />
                </StyledShowsContainer>
            </View>
        </DashboardLayout>
    )
}

export default Shows