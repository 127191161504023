import { StyledLike, StyledLikeContainer } from "./styled"
import {ReactComponent as HeartIcon} from '../../../icons/heart.svg'
import { Text } from "../../Text";
import Button from "../Button";
import api from "../../../services/api.service";
import { useState } from "react";

type LikeProps = {
    data?: any;
    noCount?: boolean;
    display?: string;
}


const Like = ({data, noCount, display}: LikeProps) => {
    const [postData, setPostData] = useState(data)
    console.log(postData)
    const [isLiked, setIsLiked] = useState(postData.liked?.status === 'active')
    const onClick = async () => {
        setIsLiked(!isLiked)
        if (postData.liked?.id) {
            const updatedStatus = !isLiked ? 'active': 'inactive'
            const resp = await api.put(`/likes/${postData.liked?.id}`, {
                status: updatedStatus
            })
            setPostData({...postData, liked: {...postData.liked, status: updatedStatus}, total_likes: resp.data?.post.total_likes})
        } else {
            const resp = await api.post('/likes', {
                source: postData.type,
                source_id: postData.id
            })

            setPostData({...postData, liked: resp.data, total_likes: postData.total_likes + 1})
        }
        
    }

    return (
        <StyledLikeContainer>
            <StyledLike>
                <Button onClick={onClick} display={display || "inline-flex"} noStyle>
                    <HeartIcon fill={isLiked ? '#ef4444' : '#FFF'} stroke={isLiked ? '#ef4444' : '#0a0a0a'}/>
                    {!noCount && !data.privacy_settings?.is_hidden_likes && postData.total_likes > 0 && <Text>{postData.total_likes}</Text>}
                </Button>
            </StyledLike>
        </StyledLikeContainer>
    )
}

export default Like