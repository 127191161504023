import styled from 'styled-components'


interface ViewProps {
    theme: any;
    display?: string;
    justifyContent?: string;
    alignItems?: string;
    padding?: string;
    margin?: string;
    width?: string;
    height?: string;
    minHeight?: string;
    maxHeight?: string;
    flexGrow?: string;
    flexDirection?: string;
    flexWrap?: string;
    flex?: string;
    gridTemplateColumns?: string;
    gridGap?: string;
    overflow?: string;
    overflowX?: string;
    overflowY?: string;
    textAlign?: string;
    border?: string;
    transform?: string;
    borderBottom?: string;
    borderTop?: string;
    background?: string;
    borderRadius?: string;
    gap?: string;
    color?: string;
    float?: string;
    position?: string;
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    zIndex?: string;
    ref?: any;
    boxShadow?: string;
    maxWidth?: string;
}


export const StyledView = styled.div<ViewProps>`
    ${props => props.maxWidth && `
        max-width: ${props.maxWidth};
    `}
    
    ${props => props.boxShadow && `
        box-shadow: ${props.boxShadow};
    `}
    
    ${props => props.maxHeight && `
        max-height: ${props.maxHeight};
    `}
    ${props => props.float && `
        float: ${props.float};
    `}
    ${props => props.position && `
        position: ${props.position};
    `}
    ${props => props.top && `
        top: ${props.top};
    `}
    ${props => props.bottom && `
        bottom: ${props.bottom};
    `}
    ${props => props.left && `
        left: ${props.left};
    `}
    ${props => props.right && `
        right: ${props.right};
    `}
    ${props => props.color && `
        color: ${props.color};
    `}

    ${props => props.color && `
        color: ${props.color};
    `}
    ${props => props.display && `
        display: ${props.display};
    `}

    ${props => props.textAlign && `
        text-align: ${props.textAlign};
    `}

    ${props => props.gap && `
        gap: ${props.gap};
    `}

    ${props => props.justifyContent && `
        justify-content: ${props.justifyContent};
    `}

    ${props => props.alignItems && `
        align-items: ${props.alignItems};
    `}

    ${props => props.flex && `
        flex: ${props.flex};
    `}

    ${props => props.flexDirection && `
        flex-direction: ${props.flexDirection};
    `}

    ${props => props.flexWrap && `
        flex-wrap: ${props.flexWrap};
    `}

    ${props => props.padding && `
        padding: ${props.padding};
    `}

    ${props => props.margin && `
        margin: ${props.margin};
    `}

    ${props => props.width && `
        width: ${props.width};
    `}

    ${props => props.height && `
        height: ${props.height};
    `}

    ${props => props.minHeight && `
        min-height: ${props.minHeight};
    `}

    ${props => props.flexGrow && `
        flex-grow: ${props.flexGrow};
    `}

    ${props => props.overflow && `
        overflow: ${props.overflow};
    `}
    
    ${props => props.overflowX && `
        overflow-x: ${props.overflowX};
    `}
    
    ${props => props.overflowY && `
        overflow-y: ${props.overflowY};
    `}

    ${props => props.border && `
        border: ${props.border};
    `}
    
    ${props => props.borderBottom && `
        border-bottom: ${props.borderBottom};
    `}

    ${props => props.borderTop && `
        border-top: ${props.borderTop};
    `}

    ${props => props.zIndex && `
        z-index: ${props.zIndex};
    `}

    ${props => props.background && `
        background: ${props.background};
    `}
    
    ${props => props.borderRadius && `
        border-radius: ${props.borderRadius};
    `}

    ${props => props.transform && `
        transform: ${props.transform};
    `}

    ${props => props.display === 'grid' && props.gridTemplateColumns && `
        grid-template-columns: ${props.gridTemplateColumns};
    `}

    ${props => props.display === 'grid' && props.gridGap && `
        grid-gap: ${props.gridGap};
    `}
    
`