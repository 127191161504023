import styled from 'styled-components'

interface SortContainerProps {
}

export const StyledSortContainer = styled.div<SortContainerProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
`

interface SortProps {
}

export const StyledSort = styled.div<SortProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`

export const StyledDropdown = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    min-width: 220px;
    margin-top: 8px;
    z-index: 1000;
`

export const StyledOption = styled.div<{ isActive?: boolean }>`
    padding: 12px 16px;
    cursor: pointer;
    font-size: 14px;
    color: ${props => props.isActive ? '#000' : '#333'};
    background: ${props => props.isActive ? '#f5f5f5' : 'transparent'};

    &:hover {
        background: #f5f5f5;
    }

    &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`