import React, { useEffect, useState } from "react";
import { useThemeStore } from "../../stores/web.store";
import { 
    StyledInput, 
    StyledInputContainer, 
    StyledLabel, 
    StyledValidationMessage, 
    StyledValidationMessageContainer, 
    StyledPasswordValidationList, 
    StyledPasswordCriteriaItem,
    StyledSuccessCheckmark,
    StyledErrorCheckmark
} from "./styled";
import { validatePassword, getCriteriaMessage, type PasswordValidation } from "../../utils/password.util";

type ValidationMessageProps = {
    message?: string;
}

const ValidationMessage = ({message}: ValidationMessageProps) => {
    return (
        <StyledValidationMessageContainer>
            <StyledValidationMessage>
                {message}
            </StyledValidationMessage>
        </StyledValidationMessageContainer>
    )
}

type InputProps = {
    type?: string;
    placeholder?: string;
    padding?: string;
    border?: string;
    borderBottom?: string;
    borderRadius?: string;
    background?: string;
    color?: string;
    width?: string;
    fontSize?: string;
    message?: any;
    setMessage?: any;
    invalid?: boolean;
    value?: any;
    id?: string;
    name?: string;
    onChange?: any;
    onBlur?: any;
    onFocus?: any;
    outline?: string;
    textIndent?: string;
    readOnly?: boolean;
    checked?: boolean;
    min?: string;
    max?: string;
    noPlaceholderPrefix?: boolean;
    rounded?: boolean;
    step?: string;
    disabled?: boolean;
    onKeyDown?: any;
    style?: React.CSSProperties;
    isCreatePassword?: boolean;
    matchValue?: string;
}

const Input = (props: InputProps) => {
    const theme = useThemeStore((state: any) => state.theme);
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [validation, setValidation] = useState<PasswordValidation | null>(null);

    const isPasswordCreate = props.isCreatePassword;
    const shouldShowMatchStatus = props.matchValue !== undefined && props.value;
    const isMatching = shouldShowMatchStatus && props.value === props.matchValue;

    const onFocus = (e: any) => {
        setIsFocus(true);
        if (isPasswordCreate && e.target.value) {
            setShowValidation(true);
        }
        if (props.onFocus) {
            props.onFocus(e);
        }
    }

    const onBlur = (e: any) => {
        setIsFocus(false);
        if (isPasswordCreate) {
            if (e.target.value) {
                setShowValidation(validation ? !validation.isValid : false);
            } else {
                setShowValidation(false);
            }
        }
        if (props.onBlur) {
            props.onBlur(e);
        }
    }

    const onChange = (e: any) => {
        if (isPasswordCreate) {
            if (e.target.value) {
                const newValidation = validatePassword(e.target.value);
                setValidation(newValidation);
                setShowValidation(!newValidation.isValid);
            } else {
                setShowValidation(false);
            }
        }
        if (props.onChange) {
            props.onChange(e);
        }
    }

    return (
        <StyledInputContainer>
            {props.message && <ValidationMessage message={props.message} />}
            <StyledInput
                {...props}
                theme={theme}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                style={{
                    ...props.style,
                    paddingRight: (isPasswordCreate || shouldShowMatchStatus) ? '40px' : props.style?.paddingRight
                }}
            />
            {isPasswordCreate && !shouldShowMatchStatus && (
                <>
                    <StyledSuccessCheckmark 
                        isVisible={validation?.isValid || false} 
                    />
                    <StyledPasswordValidationList isVisible={showValidation}>
                        {Object.entries(validation?.criteria || {}).map(([key, isValid]) => (
                            <StyledPasswordCriteriaItem
                                key={key}
                                isValid={isValid}
                            >
                                {getCriteriaMessage(key as keyof PasswordValidation['criteria'])}
                            </StyledPasswordCriteriaItem>
                        ))}
                    </StyledPasswordValidationList>
                </>
            )}
            {shouldShowMatchStatus && (
                isMatching ? (
                    <StyledSuccessCheckmark isVisible={true} />
                ) : (
                    <StyledErrorCheckmark isVisible={true} />
                )
            )}
        </StyledInputContainer>
    )
}

export default Input;