import { ReactNode, useEffect, useRef } from "react"
import { StyledModal, StyledModalContainer, StyledModalContent, StyledModalContentContainer } from "./styled"
import { useModalStore } from "../../stores/web.store";
import { MediaModal } from "./MediaModal";
import { PostModal } from "./PostModal";
import { CreateModal } from "./CreateModal";
import { View } from "../View";


type ModalProps = {
    children: ReactNode;
    setModal: any;
    position?: string;
    height?: any;
    width?: any;
    modalHeight?: any;
    top?: string;
    left?: string;
    margin?: any;
    padding?: string;
    background?: string;
    borderRadius?: string;
    isOverlay?: boolean;
    noPadding?: boolean;
}

const Modal = (props: ModalProps) => {
    const modalKey = useModalStore((state: any) => state.modalKey)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const contentRef = useRef<HTMLDivElement>(null);
    
    // Prevent scrolling on the body when modal is open
    useEffect(() => {
        // Save the original overflow style
        const originalOverflow = document.body.style.overflow;
        const originalPaddingRight = document.body.style.paddingRight;
        
        // Calculate scrollbar width to prevent layout shift
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        
        // Disable scrolling on the body
        document.body.style.overflow = 'hidden';
        
        // Add padding right to prevent layout shift when scrollbar disappears
        if (scrollbarWidth > 0) {
            document.body.style.paddingRight = `${scrollbarWidth}px`;
        }
        
        // Prevent touchmove events on mobile
        const preventTouchMove = (e: TouchEvent) => {
            // Allow scrolling within the modal content
            if (contentRef.current && contentRef.current.contains(e.target as Node)) {
                return;
            }
            e.preventDefault();
        };
        
        document.addEventListener('touchmove', preventTouchMove, { passive: false });
        
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = originalOverflow;
            document.body.style.paddingRight = originalPaddingRight;
            document.removeEventListener('touchmove', preventTouchMove);
        };
    }, []);
    
    const handleBackdropClick = (e: React.MouseEvent) => {
        // Only close if clicking directly on the backdrop, not on the modal content
        if (e.target === e.currentTarget) {
            props.setModal(false);
        }
    };
  
    if (!props.isOverlay && modalKey === 'create') return <CreateModal {...props} />
    if (!props.isOverlay && modalKey === 'media') return <MediaModal {...props} />
    if (!props.isOverlay && modalKey === 'post') return <PostModal {...props} />
    return (
        <StyledModalContainer 
            position={props.position} 
            top={props.top} 
            left={props.left} 
            background={props.background}
            onClick={handleBackdropClick}
        >
            <StyledModal width={props.width} height={props.modalHeight} margin={props.margin}>
                <StyledModalContentContainer>
                    <StyledModalContent 
                        height={props.height} 
                        background={props.noPadding ? 'transparent' : (props.background || undefined)} 
                        padding={props.padding} 
                        borderRadius={props.borderRadius}
                        ref={contentRef}
                    >
                        <View width="100%">
                            {props.children}
                        </View>
                    </StyledModalContent>
                </StyledModalContentContainer>
            </StyledModal>
        </StyledModalContainer>
    )
}

export default Modal