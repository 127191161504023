import { Button } from "../../../components/Button"
import { Image } from "../../../components/Image"
import { Text } from "../../../components/Text"
import { View } from "../../../components/View"
import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { FooterAd } from "../../../sections/Layout/Footer/FooterAd"
import { TrendingNow } from "../../../sections/TrendingNow"
import { useResponsiveStore } from "../../../stores/web.store"
import { StyledCards, StyledHeroContainer } from "./styled"


type CardProps = {
    title: string;
    description: string;
    image: string;
    onClick: any;
}


const Card = ({title, description, image, onClick}: CardProps) => {
    return (
        <View>
            <View display="flex" flexDirection="column" gap="24px">
                <View>
                    {image ? <Image 
                        src={image}
                        width="100%" 
                        height="422px" 
                        borderRadius="10px" 
                        isNoEffect
                     /> : <View height="422px" width="100%"/>}
                </View>
                <View>
                    <View display="inline-flex" gap="64px" width="100%">
                        <View width="85%">
                            <View display="flex" flexDirection="column">
                                <Text fontSize="24px" fontWeight="500">{title}</Text>
                                <Text fontSize="14px">{description}</Text>
                            </View>
                        </View>
                        <View textAlign="right" width="15%">
                            <Button 
                                onClick={onClick} 
                                background="#fff" 
                                border="solid 1px #000"
                                fontSize="16px"
                                padding="6px 12px"
                                borderRadius="24px"
                            >{'View'}</Button>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

const DiscoverCard = () => {
    const onClick = () => {

    }
    return (
        <Card
            title="Discover"
            description="Discover new talent on your feed or explore page dedicated
            only to art."
            image=""
            onClick={onClick}
        />
    )
}

const ShareCard = () => {
    const onClick = () => {

    }
    return (
        <Card
            title="Share"
            description="Share your art world adventures, gallery visits, images from 
            your collection, your studio, artwork you like, interiors and more."
            image=""
            onClick={onClick}
        />
    )
}

const BuyCard = () => {
    const onClick = () => {

    }
    return (
        <Card
            title="Buy"
            description="Buy art simply, on the primary or secondary market."
            image=""
            onClick={onClick}
        />
    )
}

const MonetizeCard = () => {
    const onClick = () => {

    }
    return (
        <Card
            title="Monetize Shows"
            description="Monetize your production with the option to make your shows pay-per-view.  "
            image=""
            onClick={onClick}
        />
    )
}

const SellCard = () => {
    const onClick = () => {

    }
    return (
        <Card
            title="Sell from your collection"
            description="Artists, galleries, dealers and collectors can all use the 
            platform to sell artwork."
            image=""
            onClick={onClick}
        />
    )
}

const LeverageCard = () => {
    const onClick = () => {

    }
    return (
        <Card
            title="Leverage your collection"
            description="Discover how you can use your collection as collateral 
            while maintaining possession of every piece;"
            image=""
            onClick={onClick}
        />
    )
}

const ManageCard = () => {
    const onClick = () => {

    }
    return (
        <Card
            title="Manage your collection"
            description="Create a visual catalog of your collection and manage the
            privacy settings. "
            image=""
            onClick={onClick}
        />
    )
}


const CommisionCard = () => {
    const onClick = () => {

    }
    return (
        <Card
            title="Commision artists"
            description="Send commission requests to artists for all kinds of projects."
            image=""
            onClick={onClick}
        />
    )
}

const VideoCard = () => {
    const onClick = () => {

    }
    return (
        <Card
            title="Video Chat 1:1"
            description="Video chat 1:1 with artists, art world professionals and
            interior designers for advice."
            image=""
            onClick={onClick}
        />
    )
}

const ShowCaseCard = () => {
    const onClick = () => {

    }
    return (
        <Card
            title="Showcase artwork in AR/VR"
            description="Build out an entire museum in a VR model, collaborate with 
            friends on a group VR show or showcase single works in AR."
            image=""
            onClick={onClick}
        />
    )
}



const AboutUs = () => {
    const width = useResponsiveStore((state: any) => state.width)
    return (
        <DashboardLayout>
            <StyledHeroContainer>
                <View display="flex" alignItems="center" height={width > 768 ? "405px": '264px'}>
                    <Text padding="0px 0px 0px 64px" fontSize={width > 768 ? "42px" : "32px"} color="#fff" fontWeight="600">The Future of Art Collecting</Text>
                </View>
            </StyledHeroContainer>
            <View padding="48px" textAlign={width > 768 ? "center" : "left"} display="flex" flexDirection="column" gap="16px">
                <View>
                    <Text width="100%" fontSize={width > 768 ? "36px" : "28px"}  fontWeight="500">{"Collecting made easy, on Primary Yellow"}</Text>
                </View>
                <View>
                    <Text width="100%" fontSize={width > 768 ? "28px" : "20px"}>{"At Primary Yellow, we’re re-imagining the way people discover and view art with a social network built for the art world. We believe the future of remote viewing is in augmented and virtual reality and we’re creating the software to make it beautiful."}</Text>
                </View>
            </View>
            <View padding={width > 768 ? "48px" : "12px"} display="flex" flexDirection="column" gap="64px">
                <StyledCards>
                    <DiscoverCard />
                    <ShareCard />
                    <BuyCard />
                    <MonetizeCard />
                    <SellCard />
                    <LeverageCard />
                    <ManageCard />
                    <CommisionCard />
                    <VideoCard />
                    <ShowCaseCard />
                </StyledCards>
                <View>
                    <TrendingNow />
                </View>
                <View>
                    <FooterAd image={''} />
                </View>
            </View>
        </DashboardLayout>
    )
}

export default AboutUs