import styled from 'styled-components'


interface SignupContainerProps {

}


export const StyledSignupContainer = styled.div<SignupContainerProps>`
    
`


interface SignupProps {

}


export const StyledSignup = styled.div<SignupProps>`

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {
    }
`

interface SignupTitleContainerProps {

}


export const StyledSignupTitleContainer = styled.div<SignupTitleContainerProps>`
    width: 90%;
    margin: auto;
`

interface SignupLogoContainerProps {

}


export const StyledSignupLogoContainer = styled.div<SignupLogoContainerProps>`
    text-align: center;

    @media (min-width: 768px) {
        padding: 16px 0px 0px 0px;
    }

    @media (min-width: 1024px) {

    }
`

interface SignupFormContainerProps {

}


export const StyledSignupFormContainer = styled.div<SignupFormContainerProps>`

`

interface SignupFormProps {

}


export const StyledSignupForm = styled.div<SignupFormProps>`
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 24px;
    justify-content: center;
    margin: auto;

    @media (min-width: 768px) {
        
    }

    @media (min-width: 1024px) {

    }
`

interface FullNameInputContainerProps {
    
}


export const StyledFullNameContainer = styled.div<FullNameInputContainerProps>` 
    position: relative;

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
    }
`

interface EmailInputContainerProps {

}


export const StyledEmailInputContainer = styled.div<EmailInputContainerProps>`
    position: relative;

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
    }
`

interface PasswordnputContainerProps {

}


export const StyledPasswordInputContainer = styled.div<PasswordnputContainerProps>`
    position: relative;

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
    }
`

interface SignupButtonContainerProps {
    padding?: string;
}


export const StyledSignupButtonContainer = styled.div<SignupButtonContainerProps>`
    padding: ${props => props.padding || '0px 0px 0px 0px'};

    @media (min-width: 768px) {
        
    }

    @media (min-width: 1024px) {

    }
`

interface ProvidersContainerProps {

}


export const StyledProvidersContainer = styled.div<ProvidersContainerProps>`
    padding: 12px 0px;

    @media (min-width: 768px) {
        
    }

    @media (min-width: 1024px) {

    }
`

interface ProvidersProps {

}


export const StyledProviders = styled.div<ProvidersProps>`
    text-align: center;

    @media (min-width: 768px) {
        
    }

    @media (min-width: 1024px) {

    }
`

interface ShowPasswordProps {

}

export const StyledShowPassword = styled.div<ShowPasswordProps>`
    position: absolute;
    right: 12px;
    top: 16px;

    @media (min-width: 768px) {
        right: 12px;
        top: 12px;
    }

    @media (min-width: 1024px) {
        
    }
`

interface RoleSwitchContainerProps {

}

export const StyledRoleSwitchContainer = styled.div<RoleSwitchContainerProps>`
    padding: 0px 0px 24px 0px;

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {
        
    }
`

interface AccountTypeContainerProps {

}

export const StyledAccountTypeContainer = styled.div<AccountTypeContainerProps>`
    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {
        
    }
`


interface RoleSelectContainerProps {

}

export const StyledRoleSelectContainer = styled.div<RoleSelectContainerProps>`
    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {
        
    }
`

interface BackToSignInContainerProps {

}

export const StyledBackToSignIn = styled.div<BackToSignInContainerProps>`
    padding: 20px 0px 12px 0px;
    width: 100%;

    @media (min-width: 768px) {
        display: inline-flex;
        width: 100%;
        justify-content: center;
        align-items: left;
        gap: 12px;
    }

    @media (min-width: 1024px) {
        
    }
`


