import styled from "styled-components";

interface MainContentContainerProps {

}

export const StyledMainContentContainer = styled.div<MainContentContainerProps>`
    padding: 20px;
`

interface MainContentProps {

}

export const StyledMainContent = styled.div<MainContentProps>`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 24px;
    width: calc(100% - 24px);
`

interface HeaderContainerProps {
    theme: any;
}

export const StyledHeaderContainer = styled.div<HeaderContainerProps>`
    background: ${props => props.theme.CardPrimaryBackgroundColor};
    border-bottom: solid 1px ${props => props.theme.BorderColor};

    @media (min-width: 768px) {
        padding: 0px;
    }

    @media (min-width: 1024px) {

    }
`

interface HeaderProps {

}

export const StyledHeader = styled.div<HeaderProps>`
    display: inline-flex;
    gap: 8px;
    width: 100%;
    width: max-content;
    padding: 12px 24px;
`