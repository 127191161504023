import { useState } from "react"
import { View } from "../../components/View"
import { ArtistSearch } from "../../components/SearchBar/ArtistSearch"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { Button } from "../../components/Button"
import { Text } from "../../components/Text"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { useModalStore, usePostStore, useProfileStore, useResponsiveStore } from "../../stores/web.store"

const GradientBackground = styled(View)`
    background: linear-gradient(135deg, #f8f9ff 0%, #ffffff 100%);
    position: relative;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: 
            radial-gradient(circle at 15% 15%, rgba(255, 220, 57, 0.15) 0%, transparent 35%),
            radial-gradient(circle at 85% 85%, rgba(255, 191, 0, 0.1) 0%, transparent 35%);
        pointer-events: none;
    }
`

const MonetAI = () => {
    const navigate = useNavigate()
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const setProfile = useProfileStore((state: any) => state.setProfile)
    const setPost = usePostStore((state: any) => state.setPost)
    const [searchData, setSearchData] = useState([])
    const [searchFilters, setSearchFilters] = useState<any>({})
    const width = useResponsiveStore((state: any) => state.width)

    const onClick = () => {
        navigate(`/${searchFilters.selected.username || searchFilters.selected.id}`)
        setProfile(searchFilters.selected)
        setIsModal(false)
        setModalKey(null)
        setPost(null)
    }

    return (
        <DashboardLayout>
            <GradientBackground height="100vh" overflowY="scroll" overflowX="hidden">
                <View padding="24px">
                    <View display="flex" flexDirection="column" gap="32px" width={width <= 768 ? "100%" : "600px"} margin="0px auto" justifyContent="center" height="90vh">
                        <View textAlign="center" display="flex" flexDirection="column" gap="4px">
                            <Text fontSize="32px" aiButtonSubtle>Monet AI</Text>
                            <Text fontSize="15px">Search our price database while Monet AI is under construction.</Text>
                        </View>
                        
                        <View>
                            <ArtistSearch 
                                searchData={searchData} 
                                setSearchData={setSearchData} 
                                searchFilters={searchFilters} 
                                setSearchFilters={setSearchFilters} 
                            />
                        </View>
                        <View width="100%" textAlign="center">
                            <Button 
                                disabled={!searchFilters.selected} 
                                width="200px" 
                                padding="12px 20px" 
                                fontSize="16px" 
                                borderRadius="24px"
                                aiButton
                                onClick={onClick}
                            >Search</Button>
                        </View>
                        
                    </View>
                </View>
            </GradientBackground>
        </DashboardLayout>
    )
}

export default MonetAI