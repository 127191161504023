import { useEffect, useState, useCallback } from "react"
import { useAccountAudit } from "../../../hooks/api.hook"
import Table from "../Table"
import { Text } from "../../Text";
import { StyledCopyButton, StyledCopyButtonCell, StyledCopyButtonContainer, StyledDeleteContainer, StyledDeleteModal, StyledPreDeleteButton, StyledPreDeleteButtonCell, StyledPreDeleteButtonContainer, StyledSignupCodesTable, StyledStatusCell } from "./styled"
import {ReactComponent as StatusIcon} from '../../../img/status.svg';
import {ReactComponent as DeleteIcon} from '../../../img/delete.svg';
import {ReactComponent as CopyIcon} from '../../../img/copy.svg';
import { Modal } from "../../Modal";
import { Button } from "../../Button";
import api from "../../../services/api.service";
import { useSuperAdminAccessStore } from "../../../stores/web.store";
import { GridApi } from 'ag-grid-community';


const StatusCell = ({data}: any) => {
    const color = data.value === 'active' ? '#58A65C' : data.value === 'pending' ? '#F1BF42' : '#D85140'
    return (
        <StyledStatusCell>
            <StatusIcon fill={color} />
        </StyledStatusCell>
    )
}

const ActionsCell = ({data}: any) => {
    const isSuperAdmin = useSuperAdminAccessStore((state: any) => state.isSuperAdmin)
    const [isHover, setIsHover] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    const onClickDelete = async () => {
        await api.delete(`/accounts/${data.data.uid}?key=uid`)
        data.api.applyTransaction({ remove: [data.data] }) 
        setIsClicked(false)
    }

    return (
        <div>
            {isSuperAdmin && <StyledPreDeleteButtonCell isClicked={isClicked}>
                <StyledPreDeleteButtonContainer>
                    <StyledPreDeleteButton
                        onClick={() => setIsClicked(true)}
                        onMouseEnter={() => setIsHover(true)} 
                        onMouseLeave={() => setIsHover(false)}
                    >
                        <DeleteIcon fill={isHover ? '#EA4335' : '#fff'} />
                    </StyledPreDeleteButton> 
                </StyledPreDeleteButtonContainer>
                {isClicked && <Modal setModal={setIsClicked} height={'fit-content'}>
                    <StyledDeleteModal>
                        <Text fontSize={'20px'}>Are You Sure?</Text>
                        <Text>Permenantly Delete <b>{data.data.email}</b> account.</Text>
                        <StyledDeleteContainer>
                            <Button padding={'4px 20px'} onClick={() => setIsClicked(false)}>Cancel</Button>
                            <Button padding={'4px 20px'} onClick={onClickDelete} background={'#EA4335'} border={'solid 1px #EA4335'}>Delete</Button>
                        </StyledDeleteContainer>
                    </StyledDeleteModal>
                </Modal>}
            </StyledPreDeleteButtonCell>}
        </div>
    )
}

const CopyButtonCell = ({data}: any) => {
    const [isHover, setIsHover] = useState(false)
    const [isClicked, setIsClicked] = useState(false)
    const onClick = (value: any) => {
        setIsClicked(true)
        navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}?code=${value}`)

        setTimeout(() => {
            setIsClicked(false)
        }, 400);
        
    }

    return (
        <StyledCopyButtonCell isClicked={isClicked}>
            <div>{data.value.code}</div>
            {data.value.code && <StyledCopyButtonContainer>
                <StyledCopyButton 
                    onClick={() => onClick(data.value.code)}
                    onMouseEnter={() => setIsHover(true)} 
                    onMouseLeave={() => setIsHover(false)}
                >
                    <CopyIcon fill={isHover ? '#9fbbd0' : '#fff'} />
                </StyledCopyButton>
            </StyledCopyButtonContainer>}
        </StyledCopyButtonCell>
    )
}


const AccountAuditTable = () => {
    const { isLoading, data } = useAccountAudit()
    const isSuperAdmin = useSuperAdminAccessStore((state: any) => state.isSuperAdmin)
    const [tableData, setTableData] = useState<any>(null)
    const [columns, setColumns] = useState<any>(null)
    const [gridApi, setGridApi] = useState<GridApi | null>(null)

    const onGridReady = (params: any) => {
        setGridApi(params.api)
    }

    const onExportClick = useCallback(() => {
        if (gridApi) {
            const params = {
                fileName: `account_audit_${new Date().toISOString().split('T')[0]}.csv`,
            }
            gridApi.exportDataAsCsv(params)
        }
    }, [gridApi])

    const updateAccount = async (params: any) => {
        const payload = {} as any
        payload[params.colDef.field] = params.newValue
        await api.put(`/accounts/${params.data.id}`, payload)
    }

    useEffect(() => {
        if (!isLoading) {
            setTableData(data?.data)
            if (data?.data.length > 0) {
                const activeColumns = ['status', 'email', 'name', 'role', 'is_verified', 'is_beta_access', 'code', 'actions']

                setColumns(activeColumns.map((key: string) => {
                    if (key === 'name') {
                        return {
                            field: key,
                            flex: .5,
                            filter: 'agTextColumnFilter',
                            valueGetter: (params: any) => `${params.data.first_name} ${params.data.last_name}`,
                            cellRenderer: (data: any) => {
                                return (
                                    <Text color={'#fff'} fontSize={'14px'}>{`${data.data.first_name} ${data.data.last_name}`}</Text>
                                )
                            }
                        }
                    }
                    if (key === 'code') {
                        return {
                            field: key,
                            headerName: 'Code',
                            flex: .5,
                            filter: 'agTextColumnFilter',
                            cellRenderer: (data: any) => {
                                return (
                                    <CopyButtonCell data={data} />
                                )
                            }
                        }
                    }
                    if (key === 'is_beta_access') {
                        return {
                            field: key,
                            headerName: 'Beta Access',
                            flex: .5,
                            filter: 'agTextColumnFilter',
                            editable: isSuperAdmin,
                            cellRenderer: 'agCheckboxCellRenderer',
                            cellEditor: 'agCheckboxCellEditor',
                        }
                    }
                    if (key === 'is_verified') {
                        return {
                            field: key,
                            headerName: 'Is Verified',
                            flex: .5,
                            filter: 'agTextColumnFilter',
                            editable: isSuperAdmin,
                            cellRenderer: 'agCheckboxCellRenderer',
                            cellEditor: 'agCheckboxCellEditor',
                        }
                    }
                    if (key === 'status') {
                        return {
                            field: key,
                            headerName: '',
                            width: 60,
                            filter: 'agTextColumnFilter',
                            cellRenderer: (data: any) => {
                                return (
                                    <StatusCell data={data} />
                                )
                            }
                        }
                    }
                    if (key === 'actions') {
                        return {
                            field: key,
                            headerName: 'Actions',
                            width: 120,
                            cellRenderer: (data: any) => {
                                return (
                                    <ActionsCell data={data} />
                                )
                            }
                        }
                    }
                    if (key === 'role') {
                        return {
                            field: key,
                            headerName: 'Role',
                            flex: .5,
                            editable: isSuperAdmin,
                            filter: 'agSetColumnFilter',
                            filterParams: {
                                values: ['admin', 'artist', 'collector', 'gallery', 'museum', 'art_advisor', 'art_critic', 'art_dealer', 'art_writer', 'collector', 'content_creator', 'curator', 'interior_designer']
                            },
                            cellEditor: 'agSelectCellEditor',
                            cellEditorParams: {
                                values: ['admin', 'artist', 'collector', 'gallery', 'museum', 'art_advisor', 'art_critic', 'art_dealer', 'art_writer', 'collector', 'content_creator', 'curator', 'interior_designer'],
                            }
                        }
                    }
                    return {field: key, flex: .5, filter: 'agTextColumnFilter'}
                }) as any)
            } else {
                setColumns([])
            }
            
        }
    }, [isLoading])


    if (tableData === null ) return <div/>
    
    return (
        <StyledSignupCodesTable>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px 4px' }}>
                <Text 
                    fontSize={'20px'} 
                    fontWeight={'300'} 
                    color={'#fff'}
                >{'Account Audit'}</Text>
                <Button 
                    onClick={onExportClick}
                    padding={'4px 20px'}
                >
                    Export CSV
                </Button>
            </div>
            <Table 
                data={tableData} 
                columns={columns} 
                searchPlaceholder={'Search Accounts...'} 
                hasSearchBar 
                onCellValueChanged={(params: any) => {
                    updateAccount(params)
                }}
                onGridReady={onGridReady}
            />
        </StyledSignupCodesTable>
    )
}

export default AccountAuditTable