import styled from "styled-components"


interface ShareModalContainerProps {

}

export const StyledShareModalContainer = styled.div<ShareModalContainerProps>`
    max-width: 600px;
    min-width: 500px;
    background: #fff;
    border-radius: 12px;
    position: relative;
    margin: 0;
    overflow: hidden;
    -webkit-box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
`

interface ShareModalProps {
    
}

export const StyledShareModal = styled.div<ShareModalProps>`
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
`

interface CloseModalContainerProps {

}

export const StyledCloseModalContainer = styled.div<CloseModalContainerProps>`

`

export const StyledProfilesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 0px 24px;
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 16px;
`

export const StyledDivider = styled.div`
    height: 1px;
    background-color: #eaeaea;
    width: 100%;
`