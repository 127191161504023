import { useEffect, useState } from "react"
import { RippleLoader } from "../../components/Loader/Loaders/RippleLoader"
import { RecentActivityPost } from "../../components/RecentActivityPost"
import { Text } from "../../components/Text"
import { StyledRecentActivity, StyledRecentActivityContainer } from "./styled"
import api from "../../services/api.service"
import { useFollowingPosts, useRecentActivity } from "../../hooks/api.hook"
import { useThemeStore } from "../../stores/theme.store"
import { View } from "../../components/View"

const RecentActivity = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const {data} = useRecentActivity()
    const [apiResp, setApiResp] = useState<any>({})

    useEffect(() => {
        if (data && data.is_loaded) {
            setApiResp(data)
        }
    }, [data])

    if (!apiResp.is_loaded) return <RippleLoader />
    return (
        <StyledRecentActivityContainer theme={theme}>
            <Text fontSize="16px" padding="16px 24px 12px 24px" fontWeight="500">Recent Activity</Text>
            <StyledRecentActivity>
                <View padding="0px 24px">
                    {data.data.map((notification: any) => {
                        return (
                            <RecentActivityPost data={notification} />
                        )
                    })}
                </View>
            </StyledRecentActivity>
        </StyledRecentActivityContainer>
    )
}

export default RecentActivity