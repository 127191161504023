import { View } from "../../View"
import { Text } from "../../Text"
import { Button } from "../../Button"
import { StyledCartFooter, StyledCheckoutButton } from "./styled"
import { toCurrency } from "../../../utils/app.util"
import { Checkout } from "../../Button/Checkout"

interface CartFooterProps {
  subtotal: number
}

const CartFooter = ({ subtotal }: CartFooterProps) => {
  return (
    <StyledCartFooter>
      <View display="flex" justifyContent="space-between" width="100%" padding="16px 0">
        <Text fontSize="16px" fontWeight="600">Subtotal</Text>
        <Text fontSize="16px" fontWeight="600">{toCurrency(subtotal)}</Text>
      </View>
      
      <Checkout />
    </StyledCartFooter>
  )
}

export default CartFooter 