import { useState } from "react"
import { DiscoverPeople } from "../../sections/DiscoverPeople"
import { Feed } from "../../sections/Feed"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { RecentActivity } from "../../sections/RecentActivity"
import { StyledDashboard, StyledDashboardContainer, StyledDashboardContent, StyledInfoSection } from "./styled"
import { FeedNavigation } from "../../components/FeedNavigation"
import { View } from "../../components/View"
import { Stories } from "../../components/Stories"
import { mockStories, currentUserMock } from "../../components/Stories/mockData"



const Dashboard = () => {
    const [stories, setStories] = useState(mockStories);
    
    const handleStoryCreate = (newStory: any) => {
        setStories(prev => [
            {
                ...newStory,
                id: `story-${Date.now()}`, // Generate a unique ID
            },
            ...prev
        ]);
    };
    
    return (
        <DashboardLayout header={<FeedNavigation />}>
            <StyledDashboardContainer>
                <StyledDashboard>
                    <StyledDashboardContent>
                        <View width="60%">
                            <Feed />
                        </View>
                        <View width="40%">
                            <StyledInfoSection>
                                <View>
                                    <Stories 
                                        stories={stories} 
                                        currentUser={currentUserMock}
                                        onStoryCreate={handleStoryCreate}
                                    />
                                </View>
                                <RecentActivity />
                                <DiscoverPeople />
                            </StyledInfoSection>
                        </View>
                    </StyledDashboardContent>
                </StyledDashboard>
            </StyledDashboardContainer>
        </DashboardLayout>
    )
}

export default Dashboard