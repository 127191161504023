import {  useEffect, useRef, useState } from "react"
import { StyledModal, StyledModalContainer, StyledModalContent, StyledModalContentContainer } from "./styled"
import { toCurrency, useComponentVisible } from "../../../utils/app.util"
import { usePostStore } from "../../../stores/web.store";
import { Text } from "../../Text";
import { Input } from "../../Input";
import { View } from "../../View";
import { Button } from "../../Button";
import {ReactComponent as XIcon} from '../../../img/x.svg'
import { PlaceBid } from "../../Button/PlaceBid";


type BidModalProps = {
    setModal: any;
    position?: string;
    height?: any;
    width?: any;
    modalHeight?: any;
    top?: string;
    left?: string;
    margin?: any;
    padding?: string;
    background?: string;
    borderRadius?: string;
}

const useOutsideAlerter = (ref: any, setModal: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}


const BidModal = ({setModal, position, height, width, modalHeight, top, left, margin, background, padding, borderRadius}: BidModalProps) => {
    const { ref } = useComponentVisible(setModal);
    const wrapperRef = useRef<HTMLDivElement>(null);

    // Prevent scrolling on the body when modal is open
    useEffect(() => {
        // Save the original overflow style
        const originalOverflow = document.body.style.overflow;
        const originalPaddingRight = document.body.style.paddingRight;
        
        // Calculate scrollbar width to prevent layout shift
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        
        // Disable scrolling on the body
        document.body.style.overflow = 'hidden';
        
        // Add padding right to prevent layout shift when scrollbar disappears
        if (scrollbarWidth > 0) {
            document.body.style.paddingRight = `${scrollbarWidth}px`;
        }
        
        // Prevent touchmove events on mobile
        const preventTouchMove = (e: TouchEvent) => {
            // Allow scrolling within the modal content
            if (wrapperRef.current && wrapperRef.current.contains(e.target as Node)) {
                return;
            }
            e.preventDefault();
        };
        
        document.addEventListener('touchmove', preventTouchMove, { passive: false });
        
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = originalOverflow;
            document.body.style.paddingRight = originalPaddingRight;
            document.removeEventListener('touchmove', preventTouchMove);
        };
    }, []);

    useOutsideAlerter(wrapperRef, setModal);

    const [bidAmount, setBidAmount] = useState(0)

    const onChangeBid = (e: any) => {
        setBidAmount(e.target.value)
    }

    return (
        <StyledModalContainer position={position} top={top} left={left}  ref={ref}>
            <StyledModal width={width} height={modalHeight} margin={margin}>
                <StyledModalContentContainer>
                    <StyledModalContent height={height} background={background} padding={padding} borderRadius={borderRadius} ref={wrapperRef}>
                        <View display="flex" flexDirection="column" gap="12px" width="100%" textAlign="center" padding="24px 0px">
                            <Text fontSize="20px" fontWeight="500" width="100%">Enter your Bid Amount</Text>
                            <Text 
                                textAlign="center" 
                                width="100%"
                                fontSize="20px" 
                                fontWeight="500" 
                            >{toCurrency(bidAmount)}</Text>
                            <Input 
                                min="0" 
                                placeholder="0" 
                                noPlaceholderPrefix 
                                value={bidAmount}
                                onChange={onChangeBid}
                            />
                            <View>
                                <PlaceBid setIsModal={setModal} amount={bidAmount}/>
                            </View>
                            
                        </View>
                        <View display="flex" flexDirection="column" gap="4px" width="100%" textAlign="center">
                            <Text width="100%" fontSize="10px">{`By placing your offer you are agreeing to purchase this item at your submitted offer price.`}</Text>
                            <Text width="100%" fontSize="10px">Review our <Button noStyle fontSize="10px" color="#0938F0" fontWeight="500">Terms of Service</Button> for more information.</Text>
                        </View>
                        
                        
                    </StyledModalContent>
                    <View position="absolute" top="12px" right="-8px">
                        <Button noStyle onClick={() => setModal(false)}><XIcon height={20} width={20}/></Button>
                    </View>
                </StyledModalContentContainer>
            </StyledModal>
        </StyledModalContainer>
    )
}

export default BidModal