import { useEffect, useRef, useState } from "react"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { useExplorePostsStore, useModalStore, usePostStore, useProductDetailPageStore, useResponsiveStore } from "../../stores/web.store"
import { StyledExploreContainer, StyledExplorePosts, StyledExplorePostsContainer, StyledQuickFiltersContainer } from "./styled"
import { Post } from "../../components/Post"
import InfiniteScroll from "react-infinite-scroll-component";
import api from "../../services/api.service"
import { View } from "../../components/View"
import { QuickFilters } from "../../components/Filters/QuickFilters"
import { Boards } from "../../components/Boards"
import { PageLoader } from "../../components/Loader/PageLoader"
import { set } from "lodash"
import Skeleton from "react-loading-skeleton"
import {ReactComponent as AILoading} from '../../img/aiLoader.svg'
import { Button } from "../../components/Button"
import { StyledHeader } from "../Artworks/styled"
import { Text } from "../../components/Text"
import { useThemeStore } from "../../stores/theme.store"
import { ReactComponent as MediaIcon } from "../../icons/explore/header1.svg"
import { ReactComponent as ArtworkIcon } from "../../icons/explore/header2.svg"
import { ReactComponent as BoardsIcon } from "../../icons/explore/header3.svg"
import { ReactComponent as ShowsIcon } from "../../icons/explore/header4.svg"
import { ReactComponent as AuctionsIcon } from "../../icons/explore/header5.svg"
import { StyledCategoryItem, StyledCategoryContainer } from "./styled"


const UserBoards = ({apiResp, setApiResp}: any) => {
    const setFilteredData = useExplorePostsStore((state: any) => state.setFilteredData) 
    useEffect(() => {
        const fetch = async () => {
            const resp = await api.get('/boards')
            setApiResp(resp)
            setFilteredData(resp.data)
        }

        fetch()
    }, [])

    if (!apiResp.is_loaded) return <PageLoader />

    return (
        <Boards api={apiResp}/>
    )
}

type CategoryItemProps = {
  icon: React.ReactNode;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const CategoryItem = ({ icon, label, isActive, onClick }: CategoryItemProps) => {
  const theme = useThemeStore((state: any) => state.theme)
  
  return (
    <Button noStyle onClick={onClick} isNoEffect style={{ width: '100%' }}>
        <View display="flex" flexDirection="column" alignItems="center" gap="8px" style={{ width: '100%' }}>
            <View 
                style={{ 
                    width: '100%', 
                    borderRadius: '10px',
                    border: isActive ? '2px solid #000000' : 'none',
                    overflow: 'hidden'
                }}
            >
                {icon}
            </View>
            <Text 
                fontWeight={isActive ? "600" : "400"} 
                fontSize="16px" 
                textAlign="left"
                width="100%"
                padding="0px 4px"
                color={isActive ? "inherit" : theme === 'dark' ? "#AAAAAA" : "#666666"}
            >
                {label}
            </Text>
        </View>
    </Button>
  )
}

const ExploreHeader = () => {
  return (
    <StyledHeader>
      <View>
        <Text fontWeight="500" fontSize="16px">Explore</Text>
      </View>
    </StyledHeader>
  )
}

const ExploreNavigation = ({ selectedFilter, setSelectedFilter }: { selectedFilter: string, setSelectedFilter: (filter: string) => void }) => {
    const svgStyle = { width: '100%', height: 'auto' };
    
    return (
        <StyledCategoryContainer style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            width: '100%', 
            padding: '0 16px', 
            marginBottom: '24px',
            gap: '12px'
        }}>
            <View style={{ flex: 1, width: '100%' }}>
                <CategoryItem 
                    icon={<MediaIcon style={svgStyle} />} 
                    label="Media" 
                    isActive={selectedFilter === 'media'} 
                    onClick={() => setSelectedFilter('media')} 
                />
            </View>
            <View style={{ flex: 1, width: '100%' }}>
                <CategoryItem 
                    icon={<ArtworkIcon style={svgStyle} />} 
                    label="Artwork" 
                    isActive={selectedFilter === 'artwork'} 
                    onClick={() => setSelectedFilter('artwork')} 
                />
            </View>
            <View style={{ flex: 1, width: '100%' }}>
                <CategoryItem 
                    icon={<BoardsIcon style={svgStyle} />} 
                    label="Boards" 
                    isActive={selectedFilter === 'boards'} 
                    onClick={() => setSelectedFilter('boards')} 
                />
            </View>
            <View style={{ flex: 1, width: '100%' }}>
                <CategoryItem 
                    icon={<ShowsIcon style={svgStyle} />} 
                    label="Shows" 
                    isActive={selectedFilter === 'shows'} 
                    onClick={() => setSelectedFilter('shows')} 
                />
            </View>
            <View style={{ flex: 1, width: '100%' }}>
                <CategoryItem 
                    icon={<AuctionsIcon style={svgStyle} />} 
                    label="Auctions" 
                    isActive={selectedFilter === 'auctions'} 
                    onClick={() => setSelectedFilter('auctions')} 
                />
            </View>
        </StyledCategoryContainer>
    )
}

const Explore = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const ref = useRef<any>(null)
    const explorePosts = useExplorePostsStore((state: any) => state.explorePosts) 
    const setExplorePosts = useExplorePostsStore((state: any) => state.setExplorePosts) 

    const filteredData = useExplorePostsStore((state: any) => state.filteredData) 
    const setFilteredData = useExplorePostsStore((state: any) => state.setFilteredData) 

    const [selectedFilter, setSelectedFilter] = useState<any>('all')

    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const setPost = usePostStore((state: any) => state.setPost)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const width = useResponsiveStore((state: any) => state.width)

    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);

    const [boardsApiResp, setBoardsApiResp] = useState<any>({})
    const [height, setHeight] = useState(0);

    const [isLoading, setIsLoading] = useState(false)

    const scrollContainerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const fetchFilteredData = async () => {
            setIsLoading(true);
            try {
                if (selectedFilter === 'boards') {
                    // Boards are handled separately in the UserBoards component
                    return;
                }
                
                // Construct the query based on the selected filter
                const query = selectedFilter === 'all' ? {} : { type: selectedFilter };
                
                const resp = await api.post(`/search/explore`, {
                    query: query,
                    offset: 0
                });
                
                setExplorePosts(resp.data);
                setFilteredData(resp.data);
                setHasMore(resp.data.length > 0);
                setOffset(0); // Reset offset when changing filters
            } catch (error) {
                console.error(`Error fetching ${selectedFilter} data:`, error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchFilteredData();
    }, [selectedFilter]);

    useEffect(() => { 
        const handleScroll = (event: WheelEvent) => { 
            const scrollContainer = scrollContainerRef.current; 
            if (scrollContainer && isLoading) { 
                const { scrollTop, scrollHeight, clientHeight } = scrollContainer; 
                const isAtBottom = scrollTop + clientHeight >= scrollHeight;
                if (event.deltaY < 0) { return; }
                if (isAtBottom) { event.preventDefault(); } 
                // scrollContainer.scrollTop = scrollTop; 
            } 
        }; 

        const scrollContainer = scrollContainerRef.current; 
        if (scrollContainer) { 
            scrollContainer.addEventListener('wheel', handleScroll, { passive: false }); 
        } 
        return () => { if (scrollContainer) { scrollContainer.removeEventListener('wheel', handleScroll); } }; 
    }, [isLoading]);

    const fetchMoreData = async () => {
        setIsLoading(true)
        const newOffset = offset + 1 
        const resp = await api.post(`/search/explore`, {query: {}, offset: explorePosts.length})
        setIsLoading(false)
        setExplorePosts([...explorePosts, ...resp.data])
        setFilteredData([...filteredData, ...resp.data])
        setHasMore(resp.data.length > 0) 
        setOffset(newOffset);
        
        
    };

    const onClick = (obj: any) => {
        if (obj.type === 'artwork') {
            setPdp(obj.type)
        } else {
            setModalKey('post')
        }
        
        setPost(obj)
    }

    return (
        <DashboardLayout 
            header={
                <ExploreHeader />
            }>
            <StyledExploreContainer width={width}>
                <StyledExplorePostsContainer theme={theme} height={`calc(100vh - ${height}px - 64px)`} id="Explore" ref={scrollContainerRef}> 
                    <View>
                        <ExploreNavigation selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
                    </View>
                    {selectedFilter === 'boards' ? <UserBoards apiResp={boardsApiResp} setApiResp={setBoardsApiResp}/> :
                    <InfiniteScroll
                        dataLength={explorePosts.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<div />}
                        scrollableTarget="Explore"
                        scrollThreshold={0.9}
                    >
                        <StyledExplorePosts>
                            {filteredData.map((obj: any) => {
                                return (
                                    <Post data={obj} setPost={setPost} onClick={() => onClick(obj)} height={width > 768 ? 'auto' : '120px'} borderRadius="15px"/>
                                )
                            })}
                        </StyledExplorePosts>
                    </InfiniteScroll>}
                </StyledExplorePostsContainer>
                {isLoading && <View position="fixed" bottom="0" left="64px" width="calc(100% - 64px)" textAlign="center"><AILoading height={18} width={18} /></View>}
            </StyledExploreContainer>
        </DashboardLayout>
    )
}

export default Explore