import { useState, useRef, useEffect } from "react";
import { 
  StyledStoriesContainer, 
  StyledStoryItem, 
  StyledStoryRing, 
  StyledStoryImage, 
  StyledStoryUsername,
  StyledStoryAddButton,
  StyledStoriesWrapper,
  StyledStoriesScrollButton,
  StyledScrollButtonContainer
} from "./styled";
import { useThemeStore } from "../../stores/theme.store";
import { View } from "../View";
import { Button } from "../Button";
import { StoryViewer } from "./StoryViewer";
import { StoryCreator } from "./StoryCreator";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as ChevronRightIcon } from "../../icons/chevronRight.svg";
import { ReactComponent as ChevronLeftIcon } from "../../icons/chevronLeft.svg";

export interface StoryMedia {
  type: "image" | "video";
  url: string;
  createdAt: string;
}

export interface Story {
  id: string;
  userId: string;
  username: string;
  userImage: string;
  media: StoryMedia[];
  viewed: boolean;
}

interface CurrentUser {
  id: string;
  username: string;
  profileImage: string;
}

interface StoriesProps {
  stories: Story[];
  currentUser: CurrentUser;
  onStoryCreate: (media: StoryMedia[]) => void;
}

export const Stories = ({ stories, currentUser, onStoryCreate }: StoriesProps) => {
  const theme = useThemeStore((state: any) => state.theme);
  const [selectedStory, setSelectedStory] = useState<Story | null>(null);
  const [isCreatingStory, setIsCreatingStory] = useState(false);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  const storiesContainerRef = useRef<HTMLDivElement>(null);

  const handleStoryClick = (story: Story) => {
    setSelectedStory(story);
  };

  const handleCloseViewer = () => {
    setSelectedStory(null);
  };

  const handleCreateStory = () => {
    setIsCreatingStory(true);
  };

  const handleCloseCreator = () => {
    setIsCreatingStory(false);
  };

  const handleStorySubmit = (media: StoryMedia[]) => {
    onStoryCreate(media);
    setIsCreatingStory(false);
  };

  const checkScrollButtons = () => {
    if (storiesContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = storiesContainerRef.current;
      setShowLeftScroll(scrollLeft > 0);
      setShowRightScroll(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener('resize', checkScrollButtons);
    
    return () => {
      window.removeEventListener('resize', checkScrollButtons);
    };
  }, [stories]);

  const handleScroll = () => {
    checkScrollButtons();
  };

  const scrollLeft = () => {
    if (storiesContainerRef.current) {
      storiesContainerRef.current.scrollBy({
        left: -200,
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    if (storiesContainerRef.current) {
      storiesContainerRef.current.scrollBy({
        left: 200,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <StyledStoriesWrapper theme={theme}>
        {showLeftScroll && (
          <StyledScrollButtonContainer style={{ left: '4px', top: '45%', transform: 'translateY(-50%)' }}>
            <Button 
              onClick={scrollLeft}
              noStyle
            >
              <StyledStoriesScrollButton 
                direction="left" 
                theme={theme}
              >
                <ChevronLeftIcon />
              </StyledStoriesScrollButton>
            </Button>
          </StyledScrollButtonContainer>
        )}
        
        {showRightScroll && (
          <StyledScrollButtonContainer style={{ right: '4px', top: '45%', transform: 'translateY(-50%)' }}>
            <Button 
              onClick={scrollRight}
              noStyle
            >
              <StyledStoriesScrollButton 
                direction="right" 
                theme={theme}
              >
                <ChevronRightIcon />
              </StyledStoriesScrollButton>
            </Button>
          </StyledScrollButtonContainer>
        )}
        
        <StyledStoriesContainer 
          theme={theme} 
          ref={storiesContainerRef}
          onScroll={handleScroll}
        >
          <StyledStoryItem theme={theme}>
            <Button onClick={handleCreateStory} noStyle>
              <StyledStoryAddButton theme={theme}>
                <PlusIcon />
              </StyledStoryAddButton>
            </Button>
            <StyledStoryUsername theme={theme}>
              {currentUser.username}
            </StyledStoryUsername>
          </StyledStoryItem>
          
          {stories.map(story => (
            <Button 
              key={story.id} 
              onClick={() => handleStoryClick(story)} 
              noStyle
            >
              <StyledStoryItem theme={theme}>
                <StyledStoryRing 
                  theme={theme} 
                  hasUnseenStory={!story.viewed}
                >
                  <StyledStoryImage theme={theme}>
                    <img src={story.userImage} alt={story.username} />
                  </StyledStoryImage>
                </StyledStoryRing>
                <StyledStoryUsername theme={theme}>
                  {story.username}
                </StyledStoryUsername>
              </StyledStoryItem>
            </Button>
          ))}
        </StyledStoriesContainer>
      </StyledStoriesWrapper>
      
      {selectedStory && (
        <StoryViewer 
          story={selectedStory} 
          onClose={handleCloseViewer} 
        />
      )}
      
      {isCreatingStory && (
        <StoryCreator 
          onClose={handleCloseCreator} 
          onSubmit={handleStorySubmit} 
        />
      )}
    </>
  );
};

export default Stories;
