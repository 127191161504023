import styled, { css } from 'styled-components'


interface SearchBarContainerProps {
    isFocus?: boolean;
    width?: string;
    isAISearch?: boolean;
}


export const StyledSearchBarContainer = styled.div<SearchBarContainerProps>`
    position: relative;
    width: ${props => props.width || '100%'} !important;
    transition: all .25s ease-out;
    width: 100%;

    ${props => props.isAISearch && props.isFocus && css`
        &::after {
            content: '';
            position: absolute;
            inset: -1px;
            background: linear-gradient(90deg, #007CF0, #FFDC39, #7928CA, #FF0080);
            background-size: 300% 100%;
            border-radius: 24px;
            -webkit-mask: 
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            mask: 
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            padding: 1px;
            animation: gradientFlow 8s ease infinite;
            pointer-events: none;
        }

        @keyframes gradientFlow {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
        }
    `}
`

interface SearchBarProps {
    textIndent?: string;
    borderRadius?: string;
    background?: string;
    color?: string;
    border?: string;
    width?: string;
    fontSize?: string;
    padding?: string;
}


export const StyledSearchBar = styled.input<SearchBarProps>`
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: ${props => props.padding || '8px 4px'};
    background: ${props => props.background || '#fff'};
    color: ${props => props.color || '#000'};
    border: ${props => props.border || "solid 1px #808080"};
    font-size: ${props => props.fontSize || '16px'};
    border-radius: ${props => props.borderRadius || '24px'};
    text-indent: ${props => props.textIndent || '40px'};

    &:focus {
        outline: none;
        border-color: transparent;
    }

    &::placeholder {
        color: #808080;
        font-size: ${props => props.fontSize || '16px'};
    }
`

interface SearchBarIconProps {

}


export const StyledSearchBarIcon = styled.div<SearchBarIconProps>`
    position: absolute;
    top: 5px;
    left: 12px;
`

interface AILensIconProps {

}


export const StyledAILensIcon = styled.div<AILensIconProps>`
    position: absolute;
    top: 8px;
    right: 12px;
`

interface SearchBarExitIconProps {

}


export const StyledSearchBarExitIcon = styled.div<SearchBarExitIconProps>`
    position: absolute;
    top: 10px;
    right: 12px;
`

interface SearchResultsDropdownProps {
    show: boolean;
}

export const StyledSearchResultsDropdown = styled.div<SearchResultsDropdownProps>`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-top: 4px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: ${props => props.show ? 'block' : 'none'};
`

export const StyledSearchResultItem = styled.div`
    padding: 12px 16px;
    cursor: pointer;
    &:hover {
        background: #f5f5f5;
    }
`
