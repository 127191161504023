import styled from 'styled-components'
import { StringLiteral } from 'typescript';


interface ButtonProps {
    theme: any;
    noStyle?: boolean;
    noEffect?: boolean;
    background?: string;
    border?: string;
    borderBottom?: string;
    borderRadius?: string;
    padding?: string;
    fontSize?: string;
    fontStyle?: string;
    fontWeight?: string;
    display?: string;
    color?: string
    cursor?: string;
    width?: string;
    minWidth?: string;
    height?: string;
    position?: string;
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
    gap?: string;
    lineHeight?: string;
    disbaled?: boolean
    hoverEffect?: any;
    textAlign?: string;
    justifyContent?: string;
    alignItems?: string;
    textDecoration?: string;
    textTransform?: string;
    isPressed?: boolean;
    isVideo?: boolean;
    isNoEffect?: boolean;
    isActive?: boolean;
    float?: string;
    aiButton?: boolean;
    aiButtonSubtle?: boolean;
    opacity?: number;
}


export const StyledButton = styled.button<ButtonProps>`
    cursor: pointer;
    font-family: ${props => props.theme.global.fontFamily};
    background: ${props => props.background || (props.noStyle ? 'unset' : props.theme.button.background)};
    border: ${props => props.border || (props.noStyle ? 'unset' : props.theme.button.border)};
    text-transform: ${props => props.textTransform || 'none'};
    text-decoration: ${props => props.textDecoration || 'none'};
    border-radius: ${props => props.borderRadius || (props.noStyle ? 'unset' : props.theme.button.borderRadius)};
    color: ${props => props.color || props.theme.button.color};
    padding: ${props => props.padding || (props.noStyle ? '0' : props.theme.button.padding)};
    font-size: ${props => props.fontSize || props.theme.button.fontSize};
    font-weight: ${props => props.fontWeight || props.theme.button.fontWeight};
    line-height: ${props => props.lineHeight || props.theme.button.lineHeight};
    width: ${props => props.width || props.theme.button.width};
    height: ${props => props.height || props.theme.button.height};
    text-align: ${props => props.textAlign || props.theme.button.textAlign};
    display: ${props => props.display || props.theme.button.display};
    font-style: ${props => props.fontStyle || 'normal'};
    opacity: ${props => props.opacity || 1};
    

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: ${props => (props.isVideo || props.isNoEffect || props.disabled) ? '1' : '.5'};
    }

    &:active:hover {
        opacity: ${props => props.isNoEffect ? '1' : '.6'};
        background: ${props => props.isNoEffect ? 'none' : '#DDE3FD'};
    }

    ${props => props.position && `
        position: ${props.position};
    `}

    ${props => props.top && `
        top: ${props.top};
    `}

    ${props => props.left && `
        left: ${props.left};
    `}

    ${props => props.right && `
        right: ${props.right};
    `}

    ${props => props.bottom && `
        bottom: ${props.bottom};
    `}

    ${props => props.gap && `
        gap: ${props.gap};
    `}

    ${props => props.disabled  && `
        color: rgba(0, 0, 0, .35);
        cursor: unset;
    `};

    ${props => props.minWidth && `
        min-width: ${props.minWidth};
    `}

    ${props => props.float && `
        float: ${props.float};
    `}

    ${props => props.noStyle && props.border && !props.isActive &&`
        &:hover {
            background: #FFFFFF;
            color: #0938F0;
        }
        
    `}

    ${props => props.isPressed && props.noStyle &&`
    
    `}

    ${props => props.isPressed && `
        opacity: .7;
    `}

    ${props => props.cursor && `
        cursor: ${props.cursor};
    `}

    ${props => props.borderBottom && `
        border-bottom: ${props.borderBottom};
    `}

    ${props => props.disabled && !props.noStyle && `
        background: rgba(207, 207, 207, 1);
        cursor: not-allowed;
        border: rgba(207, 207, 207, 1);
        color: #000;
    `};

    ${props => !props.disabled && props.hoverEffect && `
        &:hover {
            background: ${props.hoverEffect.background || 'none'};
            color: ${props.hoverEffect.color || props.color || props.theme.button.color};
            border: 1px solid rgba(255, 255, 255, 0);
        }
    `};

    ${props => props.aiButtonSubtle && `
        background: linear-gradient(90deg, #0EA5E9, #2563EB);
        background-size: 200% 100%;
        color: white;
        border: none;
        transition: all 0.2s ease;
        animation: subtleFlow 4s ease infinite;

        &:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
        }

        @keyframes subtleFlow {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
        }

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
            transform: none;
            box-shadow: none;
        }
    `}

    ${props => props.aiButton && `
        background: linear-gradient(90deg, 
            rgba(64, 149, 222, 0.9), 
            rgba(232, 205, 75, 0.85), 
            rgba(138, 84, 192, 0.9), 
            rgba(222, 69, 146, 0.85)
        );
        background-size: 300% 100%;
        color: white;
        border: none;
        animation: gradientFlow 12s ease infinite;
        transition: transform 0.2s ease;

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }

        @keyframes gradientFlow {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
        }

        &:disabled {
            opacity: 0.35;
            cursor: not-allowed;
            transform: none;
            box-shadow: none;
            filter: saturate(0.5) brightness(0.97);
            animation: none;
            background-size: 100% 100%;
            background-position: 0% 50%;
        }
    `}
`

interface ButtonContentContainerProps {
    theme: any;
    height?: string;
}


export const StyledButtonContentContainer = styled.div<ButtonContentContainerProps>`
    
    height: ${props => props.height || props.theme.button.height};
    width: 100%;
`

interface ButtonContentProps {
    theme: any;
    height?: string;
    justifyContent?: string;
}


export const StyledButtonContent = styled.div<ButtonContentProps>`
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyContent || 'center'};
    width: 100%;
    height: ${props => props.height || props.theme.button.height};
`

interface ButtonContentWrapperProps {
    display?: string;
    justifyContent?: string;
    gap?: string;
    width?: string;
    flexDirection?: string;
    alignItems?: string;
}



export const StyledButtonContentWrapper = styled.div<ButtonContentWrapperProps>`
    display: ${props => props.display || 'flex'};
    align-items: ${props => props.alignItems || 'center'};
    flex-direction: ${props => props.flexDirection || 'inline-flex'};
    justify-content: ${props => props.justifyContent || 'center'};
    width: ${props => props.width || '100%'};
    height: auto;
    gap: ${props => props.gap || '4px'};


`