import styled from 'styled-components'


interface ModalContainerProps {
    position?: string;
    top?: string;
    left?: string;
}


export const StyledModalContainer = styled.div<ModalContainerProps>`
    background: ${'rgba(0, 0, 0, .7)'};
    position: ${props => props.position || 'fixed'};
    top: ${props => props.top || '0'};
    left: ${props => props.left || '0'};
    height: 100vh;
    width: 100%;
    z-index: 9999;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
        height: 100vh;
    }

    @media (min-width: 1024px) {
    }
`


interface ModalProps {
    width?: any;
    height?: any;
    margin?: any;
}


export const StyledModal = styled.div<ModalProps>`
    width: 100%;
    @media (min-width: 768px) {
        margin: ${props => props.margin || 'auto'};
        width: ${props => props.width || '100%'};
        display: flex;
        flex-direction: column;
        height: ${props => props.height || '100vh'};
        align-items: center;
        
    }
    
    @media (min-width: 1600px) {
    }
`

interface ModalContentContainerProps {
    modalDimensions?: any;
}


export const StyledModalContentContainer = styled.div<ModalContentContainerProps>`
    height: calc(100vh - 64px);
    position: relative;
    
    @media (min-width: 768px) {
        height: 600px;
        width: ${props => props.modalDimensions.ratio < 1 ? `calc(${props.modalDimensions.modalWidth}px + 600px)` : '85%'};
        margin: auto;

    }

    @media (min-width: 1024px) {

    }
`

interface ModalContentProps {
    height?: string;
    background?: string;
    padding?: string;
    borderRadius?: string;
}


export const StyledModalContent = styled.div<ModalContentProps>`
    height: 100vh;
    border-radius: ${props => props.borderRadius || '0px'};
    overflow-y: hidden;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background: ${props => props.background || '#fff'};
    width: 100%;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
        border-radius: ${props => props.borderRadius || '8px'};
        padding: ${props => props.padding || '12px'};
        height: ${props => props.height || '600px'};
        max-height: 80vh;
    }

    @media (min-width: 1024px) {
    }
`

interface ExitButtonProps {

}


export const StyledExitButton = styled.div<ExitButtonProps>`
    position: absolute;
    top: 12px;
    right: 12px;

    @media (min-width: 768px) {
        top: 24px;
        right: 24px;
    }

    @media (min-width: 1024px) {
    }
`

interface BackNavigatorProps {

}

export const StyledBackNavigator = styled.div<BackNavigatorProps>`
    position: absolute;
    left: 0px;
    top: 50%;
    z-index: 10;

    @media (min-width: 768px) {
        left: 4px;
    }

    @media (min-width: 1024px) {

    }
`

interface NextNavigatorProps {

}

export const StyledNextNavigator = styled.div<NextNavigatorProps>`
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 10;

    @media (min-width: 768px) {
        right: 4px;
    }

    @media (min-width: 1024px) {

    }
`

