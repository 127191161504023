import styled from "styled-components";


interface ContainerProps {
    isActive?: boolean;
    isExpanded?: boolean;
}

export const StyledContainer = styled.div<ContainerProps>`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: ${props => props.isActive ? 'auto' : 'hidden'};
    background: #fff;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    

    @media (min-width: 768px) {
        left: 224px;
        width: calc(100% - 224px);
        height: ${props => props.isActive ? 'calc(100% - 64px)' : '0'};
    }

    @media (min-width: 1600px) {
    }

`