import { useEffect, useRef } from "react"
import { StyledSlideMenu, StyledSlideMenuContainer } from "./styled"
import { useDashboardMenuExpandedStore, useSlideMenuStore } from "../../stores/web.store";
import { Inbox } from "../../components/Inbox";
import { Search } from "../../components/Search";
import { Notifications } from "../../components/Notifications";
import { Filters } from "../Filters";
import { Cart } from "../../components/Shopping/Cart";



const useOutsideAlerter = (ref: any) => {
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        // Get the clicked element and check if it or any of its parents have menu item data attributes
        const target = event.target;
        const isMenuItemClick = target.hasAttribute('data-message-item') || 
                               target.hasAttribute('data-search-item') ||
                               target.hasAttribute('data-notifications-item') ||
                               target.closest('[data-message-item="true"]') !== null ||
                               target.closest('[data-search-item="true"]') !== null ||
                               target.closest('[data-notifications-item="true"]') !== null;

        // If it's not a menu item click and we clicked outside the menu, reset
        if (!isMenuItemClick && ref.current && !ref.current.contains(target)) {
          resetSlideMenu()
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}


const SlideMenu = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)

    // Prevent scrolling on the body when slide menu is active
    useEffect(() => {
        // Only apply when the slide menu is active
        if (!slideMenu.isActive) return;
        
        // Save the original overflow style
        const originalOverflow = document.body.style.overflow;
        const originalPaddingRight = document.body.style.paddingRight;
        
        // Calculate scrollbar width to prevent layout shift
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        
        // Disable scrolling on the body
        document.body.style.overflow = 'hidden';
        
        // Add padding right to prevent layout shift when scrollbar disappears
        if (scrollbarWidth > 0) {
            document.body.style.paddingRight = `${scrollbarWidth}px`;
        }
        
        // Prevent touchmove events on mobile
        const preventTouchMove = (e: TouchEvent) => {
            // Allow scrolling within the slide menu content
            if (wrapperRef.current && wrapperRef.current.contains(e.target as Node)) {
                return;
            }
            e.preventDefault();
        };
        
        document.addEventListener('touchmove', preventTouchMove, { passive: false });
        
        // Re-enable scrolling when component unmounts or slide menu is closed
        return () => {
            document.body.style.overflow = originalOverflow;
            document.body.style.paddingRight = originalPaddingRight;
            document.removeEventListener('touchmove', preventTouchMove);
        };
    }, [slideMenu.key]);

    useOutsideAlerter(wrapperRef);
    
    return (
        <StyledSlideMenuContainer 
          slideMenu={slideMenu} 
          isExpanded={isExpanded} 
          menuKey={slideMenu.key}
        >
            <StyledSlideMenu 
              slideMenu={slideMenu} 
              ref={wrapperRef}
              width={slideMenu.key === 'inbox' ? '100%' : '400px'} 
            >
                {slideMenu.key === 'inbox' && <Inbox />}
                {slideMenu.key === 'search' && <Search />}
                {slideMenu.key === 'notifications' && <Notifications />}
                {slideMenu.key === 'filters' && <Filters />}
                {slideMenu.key === 'cart' && <Cart />}
            </StyledSlideMenu>
        </StyledSlideMenuContainer>
    )
}

export default SlideMenu