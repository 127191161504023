import React, { useState, useRef, useEffect, useCallback } from 'react';
import { View } from '../../View';
import { Text } from '../../Text';
import { Button } from '../../Button';
import { Modal } from '../../Modal';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../icons/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from '../../../icons/chevronRight.svg';
import type { DraggableProvided, DraggableStateSnapshot, DroppableProvided, DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { ReactComponent as MinusIcon } from '../../../icons/minus.svg';
import { ReactComponent as AddIcon } from '../../../icons/add.svg';
import { ReactComponent as IterationIcon } from '../../../icons/iteration.svg';
import { ReactComponent as SlideIcon } from '../../../icons/slide.svg';


interface ImageState {
    scale: number;
    position: { x: number; y: number };
    rotation: number;
}

interface StyledImageProps extends ImageState {
    src: string;
}

interface MediaEditorProps {
    files: File[];
    onSave: (files: { file: File; preview: string; state: ImageState }[]) => void;
    onClose: () => void;
    isOpen: boolean;
    initialState?: ImageState;
}

interface ImageContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    isDragging?: boolean;
}

const StyledEditorContainer = styled(View)`
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const StyledImageContainer = styled(View)`
    position: relative;
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #f5f5f5;
`;

const ImageWrapper = styled.div<ImageContainerProps>`
    position: relative;
    width: calc(100% - 80px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: ${props => props.isDragging ? 'grabbing' : 'grab'};
    overflow: hidden;
`;

const GridOverlay = styled.div<{ isDragging: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 10;
    opacity: ${props => props.isDragging ? 1 : 0};
    transition: opacity 0.2s ease;
    background-image: ${props => props.isDragging ? `
        linear-gradient(90deg, transparent 49.5%, rgba(128, 128, 128, 0.15) 49.5%, rgba(128, 128, 128, 0.15) 50.5%, transparent 50.5%),
        linear-gradient(0deg, transparent 49.5%, rgba(128, 128, 128, 0.15) 49.5%, rgba(128, 128, 128, 0.15) 50.5%, transparent 50.5%)
    ` : 'none'};
    background-size: 50px 50px;
    
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: ${props => props.isDragging ? `
            linear-gradient(90deg, transparent 33%, rgba(128, 128, 128, 0.25) 33%, rgba(128, 128, 128, 0.25) 34%, transparent 34%, transparent 66%, rgba(128, 128, 128, 0.25) 66%, rgba(128, 128, 128, 0.25) 67%, transparent 67%),
            linear-gradient(0deg, transparent 33%, rgba(128, 128, 128, 0.25) 33%, rgba(128, 128, 128, 0.25) 34%, transparent 34%, transparent 66%, rgba(128, 128, 128, 0.25) 66%, rgba(128, 128, 128, 0.25) 67%, transparent 67%)
        ` : 'none'};
    }
`;

const StyledImage = styled.img<StyledImageProps>`
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: ${props => `
        translate(${props.position?.x || 0}px, ${props.position?.y || 0}px)
        scale(${props.scale || 1})
        rotate(${props.rotation || 0}deg)
    `};
    transition: transform 0.1s ease-out;
    max-width: none;
    position: relative;
    z-index: 5;
`;

const Controls = styled(View)`
    padding: 16px;
    border-top: 1px solid #eee;
`;

const NavigationButton = styled(Button)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`;

const LeftNavigationButton = styled(NavigationButton)`
    left: 0px;
`;

const RightNavigationButton = styled(NavigationButton)`
    right: 0px;
`;

const HeaderContainer = styled(View)`
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
`;

const ProgressBar = styled.div`
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0 20px;
    z-index: 20;
`;

const ProgressSegment = styled.div<{ isActive: boolean; isComplete: boolean }>`
    width: 40px;
    height: 4px;
    border-radius: 2px;
    background-color: ${props => 
        props.isComplete ? '#000' : 
        props.isActive ? '#666' : 
        '#ccc'
    };
    transition: background-color 0.2s ease;
`;

const ProgressButton = styled(Button)`
    padding: 0;
    margin: 0;
    height: 20px;
    display: flex;
    align-items: center;
`;

const PhotoReel = styled.div<{ isVisible: boolean }>`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.95);
    padding: 16px;
    display: ${props => props.isVisible ? 'block' : 'none'};
    z-index: 30;
    border-top: 1px solid #eee;
    max-height: 180px;
    overflow-y: auto;
`;

const PhotoReelGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 12px;
    padding: 8px 0;
`;

const PhotoThumbnail = styled.div<{ isSelected: boolean; isDragging?: boolean }>`
    position: relative;
    width: 100%;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid ${props => props.isSelected ? '#000' : 'transparent'};
    background-color: ${props => props.isSelected ? 'white' : '#f0f0f0'};
    transition: transform 0.2s, box-shadow 0.2s;
    
    ${props => props.isDragging && `
        transform: scale(1.05);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    `}
    
    &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
`;

const ThumbnailImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const RemoveButton = styled.button`
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease;
    
    ${PhotoThumbnail}:hover & {
        opacity: 1;
    }
    
    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
`;

const ConfirmationModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
`;

const ConfirmationContent = styled.div`
    background: white;
    border-radius: 12px;
    padding: 24px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
`;

const ConfirmationActions = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
`;

const MediaEditor: React.FC<MediaEditorProps> = ({ files, onSave, onClose, isOpen, initialState }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [scale, setScale] = useState(initialState?.scale || 1);
    const [position, setPosition] = useState(initialState?.position || { x: 0, y: 0 });
    const [rotation, setRotation] = useState(initialState?.rotation || 0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(null);
    const [editedFiles, setEditedFiles] = useState<{ file: File; preview: string; state: ImageState }[]>([]);
    const [isReelVisible, setIsReelVisible] = useState(false);
    const [filesToEdit, setFilesToEdit] = useState<File[]>([]);
    const [confirmDelete, setConfirmDelete] = useState<number | null>(null);
    
    const imageRef = useRef<HTMLImageElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    // Initialize filesToEdit with the provided files
    useEffect(() => {
        setFilesToEdit([...files]);
    }, [files]);

    useEffect(() => {
        if (!initialState) {
            setScale(1);
            setPosition({ x: 0, y: 0 });
            setRotation(0);
        }
    }, [initialState, currentIndex]);

    // Ensure position stays within bounds whenever scale changes
    useEffect(() => {
        // Skip constraint when scale is 1 (centered)
        if (scale <= 1) {
            setPosition({ x: 0, y: 0 });
            return;
        }
        
        // Apply constraints to current position based on current scale
        const constrainedPosition = constrainPosition(position, scale);
        
        // Only update if position changed to avoid infinite loops
        if (constrainedPosition.x !== position.x || constrainedPosition.y !== position.y) {
            setPosition(constrainedPosition);
        }
    }, [scale]);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        // Wheel event handler with passive: false to allow preventDefault
        const wheelHandler = (e: WheelEvent) => {
            e.preventDefault();
            
            // Adjust zoom sensitivity based on current scale
            // Lower sensitivity at higher zoom levels for finer control
            const zoomSensitivity = 0.05 / (scale > 2 ? 2 : scale > 1.5 ? 1.5 : 1);
            
            // Determine zoom direction
            const delta = e.deltaY < 0 ? zoomSensitivity : -zoomSensitivity;
            
            // Calculate new scale with constraints
            const newScale = Math.max(1, Math.min(3, scale + delta));
            
            // If scale didn't change, don't proceed
            if (newScale === scale) return;
            
            // If scale is 1, reset position to center
            if (newScale <= 1) {
                setScale(newScale);
                setPosition({ x: 0, y: 0 });
                return;
            }
            
            // Get container dimensions and position
            const rect = container.getBoundingClientRect();
            
            // Calculate cursor position relative to container center
            const cursorX = e.clientX - rect.left - rect.width / 2;
            const cursorY = e.clientY - rect.top - rect.height / 2;
            
            // Calculate new position to zoom toward cursor
            const scaleFactor = newScale / scale;
            const newX = position.x - (cursorX - position.x) * (scaleFactor - 1);
            const newY = position.y - (cursorY - position.y) * (scaleFactor - 1);
            
            // Apply constraints using our helper function
            const constrainedPosition = constrainPosition({ x: newX, y: newY }, newScale);
            
            // Update state
            setScale(newScale);
            setPosition(constrainedPosition);
        };

        // Add event listener with passive: false to allow preventDefault
        container.addEventListener('wheel', wheelHandler, { passive: false });
        
        // Clean up
        return () => {
            container.removeEventListener('wheel', wheelHandler);
        };
    }, [scale, position]);

    const handleDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!containerRef.current) return;
        
        setIsDragging(true);
        setDragStart({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const handleDrag = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging || !dragStart) return;

        const newX = e.clientX - dragStart.x;
        const newY = e.clientY - dragStart.y;

        // Apply constraints using our helper function
        const constrainedPosition = constrainPosition({ x: newX, y: newY }, scale);
        setPosition(constrainedPosition);
    };

    const handleDragEnd = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(false);
        setDragStart(null);
    };

    const handleReelDragEnd = (result: DropResult) => {
        // Dropped outside the list
        if (!result.destination) {
            return;
        }
        
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        
        // Reorder files
        const reorderedFiles = Array.from(filesToEdit);
        const [removed] = reorderedFiles.splice(sourceIndex, 1);
        reorderedFiles.splice(destinationIndex, 0, removed);
        
        // Reorder edited files if they exist
        const reorderedEditedFiles = Array.from(editedFiles);
        if (reorderedEditedFiles.length > 0) {
            const [removedEdit] = reorderedEditedFiles.splice(sourceIndex, 1);
            reorderedEditedFiles.splice(destinationIndex, 0, removedEdit);
            setEditedFiles(reorderedEditedFiles);
        }
        
        // Update current index if the active image was moved
        if (currentIndex === sourceIndex) {
            setCurrentIndex(destinationIndex);
        } else if (
            currentIndex > sourceIndex && 
            currentIndex <= destinationIndex
        ) {
            setCurrentIndex(currentIndex - 1);
        } else if (
            currentIndex < sourceIndex && 
            currentIndex >= destinationIndex
        ) {
            setCurrentIndex(currentIndex + 1);
        }
        
        setFilesToEdit(reorderedFiles);
    };

    const handleZoom = (delta: number) => {
        const newScale = Math.max(1, Math.min(3, scale + delta));
        
        // If scale is 1, reset position to center
        if (newScale <= 1) {
            setScale(newScale);
            setPosition({ x: 0, y: 0 });
            return;
        }
        
        // Apply constraints to the position based on new scale
        const newPosition = constrainPosition(position, newScale);
        
        setScale(newScale);
        setPosition(newPosition);
    };

    // Helper function to constrain position within bounds based on scale
    const constrainPosition = (pos: { x: number; y: number }, newScale: number) => {
        if (!containerRef.current) return pos;
        
        const containerRect = containerRef.current.getBoundingClientRect();
        const maxX = Math.max(0, (newScale - 1) * containerRect.width / 2);
        const maxY = Math.max(0, (newScale - 1) * containerRect.height / 2);
        
        return {
            x: Math.min(Math.max(pos.x, -maxX), maxX),
            y: Math.min(Math.max(pos.y, -maxY), maxY)
        };
    };

    const handleRotate = () => {
        setRotation((rotation + 90) % 360);
    };

    const captureEditedImage = useCallback(async () => {
        if (!imageRef.current || !containerRef.current) return null;

        // Create a canvas matching the container's visible dimensions
        const previewCanvas = document.createElement('canvas');
        const previewCtx = previewCanvas.getContext('2d');
        if (!previewCtx) return null;

        const containerRect = containerRef.current.getBoundingClientRect();
        previewCanvas.width = containerRect.width;
        previewCanvas.height = containerRect.height;

        // Draw the preview exactly as shown in the modal
        previewCtx.save();
        previewCtx.fillStyle = '#f5f5f5';
        previewCtx.fillRect(0, 0, previewCanvas.width, previewCanvas.height);
        
        previewCtx.translate(previewCanvas.width / 2, previewCanvas.height / 2);
        previewCtx.rotate((rotation * Math.PI) / 180);
        previewCtx.scale(scale, scale);
        previewCtx.translate(-previewCanvas.width / 2 + position.x, -previewCanvas.height / 2 + position.y);

        previewCtx.drawImage(
            imageRef.current,
            0, 0,
            previewCanvas.width,
            previewCanvas.height
        );
        previewCtx.restore();

        // Use original file with preview URL
        const previewUrl = previewCanvas.toDataURL('image/jpeg', 1.0);
        return {
            file: filesToEdit[currentIndex],
            preview: previewUrl,
            state: {
                scale,
                position,
                rotation,
            }
        };
    }, [currentIndex, filesToEdit, position, rotation, scale]);

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex < filesToEdit.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleRemoveImage = (index: number) => {
        // Create a new array without the removed file
        const updatedFiles = [...filesToEdit];
        updatedFiles.splice(index, 1);
        
        // Update edited files array if needed
        const updatedEditedFiles = [...editedFiles];
        updatedEditedFiles.splice(index, 1);
        
        // Update state
        setFilesToEdit(updatedFiles);
        setEditedFiles(updatedEditedFiles);
        
        // Adjust current index if needed
        if (index <= currentIndex && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        } else if (updatedFiles.length === 0) {
            // If all files were removed, close the editor
            onClose();
        }
        
        // Close confirmation modal
        setConfirmDelete(null);
    };

    const toggleReel = () => {
        setIsReelVisible(!isReelVisible);
    };

    const handleSubmit = async () => {
        const result = await captureEditedImage();
        
        if (result) {
            // Add current image to edited files
            const updatedFiles = [...editedFiles];
            updatedFiles[currentIndex] = result;
            
            // Check if we've edited all files
            const allFilesEdited = updatedFiles.filter(Boolean).length === filesToEdit.length;
            
            if (allFilesEdited) {
                onSave(updatedFiles);
                setEditedFiles([]);
                setCurrentIndex(0);
            } else {
                // Move to next unedited image
                setEditedFiles(updatedFiles);
                const nextIndex = updatedFiles.findIndex((file, idx) => idx > currentIndex && !file);
                if (nextIndex !== -1) {
                    setCurrentIndex(nextIndex);
                } else {
                    // If no unedited images after current, find first unedited
                    const firstUnedited = updatedFiles.findIndex(file => !file);
                    if (firstUnedited !== -1) {
                        setCurrentIndex(firstUnedited);
                    } else {
                        // All files edited, save
                        onSave(updatedFiles);
                        setEditedFiles([]);
                        setCurrentIndex(0);
                    }
                }
            }
        }
    };

    return (
        <Modal setModal={onClose} width="800px" height="fit-content">
            <StyledEditorContainer>
                <HeaderContainer>
                    <Button noStyle onClick={onClose}><CloseIcon height="16px" width="16px" /></Button>
                    <Text fontSize="18px" fontWeight="600">Edit Photo {currentIndex + 1} of {filesToEdit.length}</Text>
                    <Button noStyle onClick={handleSubmit} fontSize="16px" fontWeight="400">
                        Submit
                    </Button>
                </HeaderContainer>

                <StyledImageContainer>
                    {/* Navigation Arrows */}
                    {currentIndex > 0 && (
                        <LeftNavigationButton noStyle onClick={handlePrevious}>
                            <ChevronLeftIcon />
                        </LeftNavigationButton>
                    )}
                    
                    <ImageWrapper
                        ref={containerRef}
                        onMouseDown={handleDragStart}
                        onMouseMove={handleDrag}
                        onMouseUp={handleDragEnd}
                        onMouseLeave={handleDragEnd}
                    >
                        {filesToEdit[currentIndex] && (
                            <StyledImage
                                ref={imageRef}
                                src={URL.createObjectURL(filesToEdit[currentIndex])}
                                scale={scale}
                                position={position}
                                rotation={rotation}
                                alt=""
                                draggable={false}
                            />
                        )}
                        <GridOverlay isDragging={isDragging} />
                    </ImageWrapper>
                    
                    {currentIndex < filesToEdit.length - 1 && (
                        <RightNavigationButton noStyle onClick={handleNext}>
                            <ChevronRightIcon />
                        </RightNavigationButton>
                    )}
                    
                    {/* Progress Bar */}
                    <ProgressBar>
                        {filesToEdit.map((_, index) => (
                            <ProgressButton 
                                key={`progress-${index}`}
                                noStyle
                                onClick={() => setCurrentIndex(index)}
                            >
                                <ProgressSegment 
                                    isActive={index === currentIndex}
                                    isComplete={editedFiles[index] !== undefined}
                                />
                            </ProgressButton>
                        ))}
                    </ProgressBar>
                    
                    {/* Photo Reel */}
                    <PhotoReel isVisible={isReelVisible}>
                        <DragDropContext onDragEnd={handleReelDragEnd}>
                            <Droppable droppableId="photoReel" direction="horizontal">
                                {(provided: DroppableProvided) => (
                                    <PhotoReelGrid
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {filesToEdit.map((file, index) => (
                                            <Draggable 
                                                key={`thumbnail-${index}`} 
                                                draggableId={`thumbnail-${index}`} 
                                                index={index}
                                            >
                                                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <PhotoThumbnail 
                                                            isSelected={index === currentIndex}
                                                            isDragging={snapshot.isDragging}
                                                            onClick={() => setCurrentIndex(index)}
                                                        >
                                                            <ThumbnailImage 
                                                                src={URL.createObjectURL(file)} 
                                                                alt={`Thumbnail ${index + 1}`} 
                                                            />
                                                            <RemoveButton 
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setConfirmDelete(index);
                                                                }}
                                                            >
                                                                <CloseIcon height="12px" width="12px" stroke="#fff" />
                                                            </RemoveButton>
                                                        </PhotoThumbnail>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </PhotoReelGrid>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </PhotoReel>
                </StyledImageContainer>

                <Controls padding="16px" display="flex" justifyContent="center" gap="16px">
                    <Button noStyle onClick={() => handleZoom(-0.1)}><MinusIcon height="16px" width="16px" /></Button>
                    <Button noStyle onClick={() => handleZoom(0.1)}><AddIcon height="16px" width="16px" /></Button>
                    <Button noStyle onClick={handleRotate}><IterationIcon height="16px" width="16px" /></Button>
                    {filesToEdit.length > 1 && (
                        <Button 
                            noStyle 
                            onClick={toggleReel}
                            style={{ 
                                background: isReelVisible ? '#f0f0f0' : 'transparent',
                                borderRadius: '4px',
                                padding: '4px'
                            }}
                        >
                            <SlideIcon height="16px" width="16px" />
                        </Button>
                    )}
                </Controls>
            </StyledEditorContainer>
            
            {/* Confirmation Modal for Delete */}
            {confirmDelete !== null && (
                <ConfirmationModal>
                    <ConfirmationContent>
                        <Text fontSize="18px" fontWeight="600">Reject Photo?</Text>
                        <Text fontSize="14px" margin="16px 0 0 0">This will remove the photo from your post.</Text>
                        
                        <ConfirmationActions>
                            <Button 
                                noStyle 
                                onClick={() => setConfirmDelete(null)}
                                padding="8px 16px"
                                borderRadius="20px"
                                background="#f0f0f0"
                                fontSize="14px"
                            >
                                Cancel
                            </Button>
                            <Button 
                                noStyle 
                                onClick={() => handleRemoveImage(confirmDelete)}
                                padding="8px 16px"
                                borderRadius="20px"
                                background="#000"
                                color="#fff"
                                fontSize="14px"
                            >
                                Reject
                            </Button>
                        </ConfirmationActions>
                    </ConfirmationContent>
                </ConfirmationModal>
            )}
        </Modal>
    );
};

export default MediaEditor;
