import styled from 'styled-components'
import { useThemeStore } from '../../../stores/theme.store'

interface PriceRangeProps {
    theme: any
}

export const StyledPriceRange = styled.div<PriceRangeProps>`
    position: absolute;
    bottom: 28px;
    left: 0;
    right: 0;

    & .range-slider {
        height: 2px;
        background: #F5F5F5;
        border-radius: 1px;
    }
    
    & .range-slider .range-slider__thumb {
        background: white;
        height: 20px;
        width: 20px;
        border: 1px solid #E5E5E5;
        border-radius: 50%;
        cursor: pointer;
        transform: translateY(-50%);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    }

    & .range-slider .range-slider__range {
        background: ${props => props.theme.PrimaryYellow};
    }
`

export const StyledHistogramContainer = styled.div`
    padding: 0;
`

export const StyledHistogram = styled.div`
    display: flex;
    align-items: flex-end;
    height: 70px;
    gap: 2px;
    margin-bottom: 10px;
`

export const StyledBar = styled.div<{ height: number; isActive: boolean; theme: any }>`
    flex: 1;
    height: ${props => props.height}%;
    background-color: ${props => props.isActive ? props.theme.PrimaryYellow : '#F5F5F5'};
    border-radius: 1px;
`

interface InputWrapperProps {
    width?: string;
    textAlign?: string;
}

export const StyledInputWrapper = styled.div<InputWrapperProps>`
    position: relative;
    width: ${props => props.width || '100%'};
    text-align: ${props => props.textAlign || 'left'};

    &:before {
        content: '$';
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: #000;
        font-size: 16px;
        z-index: 1;
    }
`

export const StyledInput = styled.input`
    width: 100%;
    padding: 16px 16px 16px 28px;
    border: 1px solid #E5E5E5;
    border-radius: 100px;
    font-size: 16px;
    outline: none;
    background: white;
    
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    &[type=number] {
        -moz-appearance: textfield;
    }

    &::placeholder {
        color: #000;
    }
`
