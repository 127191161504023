import styled from 'styled-components'

interface PreviewContainerProps {
    ref?: any
}

export const StyledPreviewContainer  = styled.div<PreviewContainerProps>`
    
`

interface PreviewProps {

}

export const StyledPreview = styled.div<PreviewProps>`
    display: flex;
    flex-direction: column;
    gap: 12px;
`
