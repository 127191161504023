import { useEffect } from "react"
import { Button } from "../../components/Button"
import { QuickFilter } from "../../components/Filters/QuickFilters/QuickFilter"
import { Text } from "../../components/Text"
import { View } from "../../components/View"
import { useAccountStore, useAllMyPostsStore, useDashboardNavigationStore, useProfileStore } from "../../stores/web.store"
import { StyledDashboardNavigation, StyledDashboardNavigationContainer } from "./styled"


const DashboardNavigation = ({profile}: any) => {
    const allMyPosts = useAllMyPostsStore((state: any) => state.allMyPosts) 
    const setDashboardNavigation = useDashboardNavigationStore((state: any) => state.setDashboardNavigation)
    const dashboardNavigation = useDashboardNavigationStore((state: any) => state.dashboardNavigation)
    const account = useAccountStore((state: any) => state.account)
    
    useEffect(() => {
        if (profile.status === 'imported') {
            setDashboardNavigation('pricing-database')
        }
    }, [profile])

    const data = [
        {
            id: 'all',
            title: 'All',
            total: allMyPosts.length
        },
        {
            id: 'shows',
            title: 'Shows',
            total: allMyPosts.filter((post: any) => post.type === 'show').length
        },
        {
            id: 'artworks',
            title: 'Artworks',
            total: allMyPosts.filter((post: any) => post.type === 'artwork').length
        },
        {
            id: 'auctions',
            title: 'Auctions',
            total: allMyPosts.filter((post: any) => post.type === 'auction').length
        },
        {
            id: 'subscribers',
            title: 'Subscribers'
        },
        {
            id: 'saved',
            title: 'Boards',
        },
        {
            id: 'pricing-database',
            title: 'Auction Results',
        },
        // {
        //     id: 'insights',
        //     title: 'Insights'
        // }
    ]

    const onClick = (value: string) => {
        if (value !== dashboardNavigation) {
            setDashboardNavigation((value))
        }
    }

    return (
        <StyledDashboardNavigationContainer>
            <StyledDashboardNavigation>
                {data.map((obj: any) => {
                    if ((account.id === profile.id && ['subscribers', 'saved'].includes(obj.id)) || !['subscribers', 'saved'].includes(obj.id)) {
                        return (
                            <QuickFilter 
                                key={`nav-${obj.id}`} 
                                onClick={() => onClick(obj.id)} 
                                isSelected={dashboardNavigation === obj.id}
                                title={`${obj.title} ${obj.total ? '(' + obj.total  + ')' : ''}`}
                            />
                            
                        )  
                    }
                    return <View display="none"/>
                    
                })}
            </StyledDashboardNavigation>
        </StyledDashboardNavigationContainer>
    )
}

export default DashboardNavigation