import styled from "styled-components";

export const StyledNavigator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledStepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface StepProps {
  isActive: boolean;
  isCompleted: boolean;
}

export const StyledStep = styled.div<StepProps>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  
  ${({ isActive, isCompleted, theme }) => {
    if (isCompleted) {
      return `
        background-color: #000;
        color: #fff;
        border: none;
      `;
    } else if (isActive) {
      return `
        background-color: transparent;
        color: #000;
        border: 2px dashed #666;
      `;
    } else {
      return `
        background-color: transparent;
        color: #999;
        border: 2px dashed #ccc;
      `;
    }
  }}
`;

interface StepLineProps {
  isActive: boolean;
}

export const StyledStepLine = styled.div<StepLineProps>`
  height: 2px;
  width: 60px;
  background-color: ${({ isActive }) => isActive ? '#000' : '#ccc'};
`; 