import styled from 'styled-components'

interface PostContainerProps {

}


export const StyledPostContainer  = styled.div<PostContainerProps>`
    
`

interface PostProps {

}

export const StyledPost = styled.div<PostProps>`
    position: relative;
    

`

interface PostTypeIconContainerProps {

}

export const StyledPostTypeIcon = styled.div<PostTypeIconContainerProps>`
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 2;
    
`

