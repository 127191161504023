import { useState } from "react"
import { useAccountStore, useThemeStore } from "../../../stores/web.store"
import Button from "../Button"
import api from "../../../services/api.service"
import { View } from "../../View"


const Meet = ({title, data, noStyle, activeColor, inactiveColor, borderRadius, isFollowing}: any) => {
    const account = useAccountStore((state: any) => state.account)
    const setAccount = useAccountStore((state: any) => state.setAccount)
    const theme = useThemeStore((state: any) => state.theme)

    const onClick = async () => {
        
    }


    return (
        <Button 
            noStyle={noStyle} 
            textAlign="right" 
            width="fit-content" 
            fontSize="16px"
            padding={noStyle ? '0px' : "12px 16px"} 
            border={noStyle ? 'none' : `solid 1px ${activeColor || '#f5f5f5'}`}
            borderRadius={borderRadius || "24px"}
            background={inactiveColor || '#f5f5f5'}
            color={activeColor || theme.button.color}
            onClick={onClick}
        >{title || 'Meet'}</Button>
    )
}

export default Meet