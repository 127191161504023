import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { StyledDashboardContainer, StyledDashboardLayout, StyledHeader, StyledHeaderContainer } from "./styled";
// import { TopNavMenu } from "../../TopNav/TopNavMenu";
import { DashboardMenu } from "../DashboardMenu";
import { Text } from "../../../components/Text";
import { ButterBar } from "../../../components/ButterBar";
import { useAccessStore, useAccountStore, useDashboardMenuExpandedStore, useFiltersStore, useFullScreen, useMeetingStore, useModalStore, usePostStore, useProductDetailPageStore, useProfileStore, useSelectedPostStore, useSlideMenuStore, useViewNavigatorStore } from "../../../stores/web.store";
import { Footer } from "../Footer";
import SlideMenu from "../../SlideMenu/SlideMenu";
import { ProductDetailContainer } from "../../../components/ProductDetail/Container";
import Artwork from "../../../components/ProductDetail/Artwork/Artwork";
import Show from "../../../components/ProductDetail/Show/Show";
import { Auction } from "../../../components/ProductDetail/Auction";
import { Gallery } from "../../../components/ProductDetail/Gallery";
import { UserProfile } from "../../../pages/UserDashboard/UserDashboard";
import { useLocation } from "react-router-dom";
import { PostDetails } from "../../../components/PostDetails";
import { Modal } from "../../../components/Modal";
import { Lot } from "../../../components/ProductDetail/Lot";
import { Meeting } from "../../../components/Meeting";
import { Checkout } from "../../../components/Services/Stripe/Checkout";
import { Onboarding } from "../../../components/Services/Stripe/Onboarding";
import { PostModal } from "../../../components/Modal/PostModal";
import { CreateModal } from "../../../components/Modal/CreateModal";
import { ViewingRoom } from "../../../components/ViewingRoom";
import { useNavigate } from "react-router-dom";
import { MediaModal } from "../../../components/Modal/MediaModal";
import { ImageGallery } from "../../../components/ImageGallery";
import { useThemeStore } from "../../../stores/theme.store";
import { View } from "../../../components/View";
import { SaleArtwork } from "../../PricingDatabase/SaleArtwork";
import { SearchBar } from "../../../components/SearchBar";
import {ShoppingBag} from "../../../components/Button/ShoppingBag";
import { Show2D } from "../../../components/Show/Show2D";
import { FullScreen } from "../../../styled";
import { Button } from "../../../components/Button";


const SearchSection = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const setSlideMenu = useSlideMenuStore((state: any) => state.setSlideMenu)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)

    const onClick = () => {
        if (slideMenu.key === 'cart' && slideMenu.isActive) {
            resetSlideMenu()
        } else {
            setSlideMenu({...slideMenu, isActive: true, key: 'cart', location: 'right'})
        }
    }
    
    return (
        <View>
            <View 
                padding="12px 24px" 
                background={theme.CardPrimaryBackgroundColor} 
                display="inline-flex"
                alignItems="center"
                gap="12px"
                width="calc(100% - 48px)"
                borderBottom={`solid 1px ${theme.BorderColor}`}
            >
                <View flexGrow="1">
                    <SearchBar width="440px" placeholder="Search" border="none" background={theme.SearchBarBackgroundColor}/>
                </View>
                <View textAlign="right">
                    <View position="relative">
                        <Button noStyle onClick={onClick}>
                            <ShoppingBag />
                            <View 
                                position="absolute"
                                top="0"
                                right="0"
                                height="14px" 
                                width="14px" 
                                borderRadius="50%"
                                display="flex" 
                                alignItems="center" 
                                justifyContent="center"
                                background={theme.PrimaryYellow}
                                boxShadow="0px 0px 2px 0px rgba(125, 125, 125, 0.35);
    "
                            ><Text fontSize="10px">3</Text></View>
                        </Button>
                    </View>
                </View>
            </View>
        </View>
    )
}

type DashboardLayoutProps = {
    children: ReactNode;
    includeBanner?: boolean;
    overflow?: string;
    height?: string;
    header?: ReactNode;
}

const DashboardLayout = ({children, includeBanner, overflow, height, header}: DashboardLayoutProps) => {
    const theme = useThemeStore((state: any) => state.theme)
    const navigate = useNavigate()
    const location = useLocation()
    const isModal = useModalStore((state: any) => state.isModal)
    const resetFilterStore = useFiltersStore((state: any) => state.resetFilterStore)
    const setIsExpanded = useDashboardMenuExpandedStore((state: any) => state.setIsExpanded)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const access = useAccessStore((state: any) => state.access)
    const pdp = useProductDetailPageStore((state: any) => state.pdp)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const fullScreenView = useFullScreen((state: any) => state.fullScreenView)
    const setProfile = useProfileStore((state: any) => state.setProfile) 
    const setPost = usePostStore((state: any) => state.setPost)
    const account = useAccountStore((state: any) => state.account)
    const post = usePostStore((state: any) => state.post)
    const meeting = useMeetingStore((state: any) => state.meeting)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const modalKey = useModalStore((state: any) => state.modalKey)
    const selectedPost = useSelectedPostStore((state: any) => state.selectedPost)

    const memoizedSetPdp = useCallback(() => setPdp(null), [setPdp]);
    const memoizedResetSlideMenu = useCallback(() => resetSlideMenu(), [resetSlideMenu]);
    const memoizedResetFilterStore = useCallback(() => resetFilterStore(), [resetFilterStore]);
    const memoizedSetProfile = useCallback(() => setProfile(account), [setProfile, account]);

    useEffect(() => {
        window.scrollTo(0, 0);
        memoizedSetPdp();
        memoizedResetSlideMenu();
        memoizedResetFilterStore();

        if (location.pathname === '/profile') {
            memoizedSetProfile();
        }
    }, [location, memoizedSetPdp, memoizedResetSlideMenu, memoizedResetFilterStore, memoizedSetProfile]);

    useEffect(() => {
        if (fullScreenView !== null) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [fullScreenView]);


    const memoizedFooter = useMemo(() => (
        <Footer width={'calc(100% - 42px)'} isExpanded={isExpanded} />
    ), [isExpanded]);

    const memoizedButterBar = useMemo(() => (
        includeBanner && (
            <ButterBar background='rgba(9, 55, 241, 0.95)' color='#fff' width={'calc(100% - 42px)'} left="42px">
                <Text width='100%' textAlign='center' fontSize='24px'>Primary Yellow Beta</Text>
            </ButterBar>
        )
    ), [includeBanner]);

    const [modalDimensions, setModalDimensions] = useState({
        modalWidth: 0,
        modalHeight: 0,
        objectFit: 'contain',
        ratio: 0
    });

    return (
        <StyledDashboardLayout isModal={isModal} id="DashboardLayout" theme={theme}>
            
            {access && <DashboardMenu />}
            <FullScreen theme={theme} isActive={post && fullScreenView === 'show'}><Show2D /></FullScreen>
            <StyledDashboardContainer 
                isModal={isModal} 
                isExpanded={isExpanded} 
                // isCreate={selectedPost !== null} 
                overflow={overflow}
                height={height}
            >
                <SearchSection />

                {header && <StyledHeaderContainer theme={theme}>
                    <StyledHeader>
                        {header}
                    </StyledHeader>                    
                </StyledHeaderContainer>}
                
                <View flexGrow="1">
                    <View padding="24px 36px">
                        {children}
                    </View>
                </View>
                
            </StyledDashboardContainer>
            <SlideMenu />
            {memoizedFooter}
            {memoizedButterBar}
            <ProductDetailContainer isExpanded={isExpanded && pdp} padding={pdp === 'viewingRoom' ? '0px' : '24px'}>
                {post && pdp === 'artwork' && <Artwork />}
                {post && pdp === 'auctionItem' && <Auction />}
                {post && pdp === 'auction' && <Lot />}
                {post && pdp === 'gallery' && <Gallery />}
                {post && pdp === 'viewingRoom' && <ViewingRoom />}
                {post && pdp === 'saleArtwork' && <SaleArtwork />}
                {meeting && pdp === 'meeting' && <Meeting />}
                {/* {post && pdp === 'checkout' && <Checkout />} */}
                {pdp === 'profile' && <UserProfile />}
            </ProductDetailContainer>
            {(post && modalKey && modalKey === 'post') &&
                <PostModal setModal={setPost} borderRadius="12px" padding="0" modalDimensions={modalDimensions}>
                    <PostDetails data={post} isModal setModalDimensions={setModalDimensions} modalDimensions={modalDimensions}/>
                </PostModal>}
            {(modalKey === 'create') &&
                <CreateModal setModal={setPost} borderRadius="0" padding="0" />
            }
            {(post && modalKey && modalKey === 'media') &&
                <MediaModal setModal={setPost} borderRadius="0" padding="0" ><ImageGallery data={post} fullscreen /></MediaModal>
            }
            
        </StyledDashboardLayout>
    )
}

export default React.memo(DashboardLayout);