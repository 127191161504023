import styled, {keyframes, css} from 'styled-components'


interface TextProps {
    theme: any;
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    padding?: string;
    textAlign?: string;
    textTransform?: string;
    lineHeight?: string;
    width?: string;
    margin?: string;
    justifyContent?: string;
    textDecoration?: string;
    flexGrow?: string;
    textOverflow?: string;
    fontStyle?: string;
    isReadMore?: boolean;
    isExpanded?: boolean;
    aiify?: boolean;
    aiText?: boolean;
    aiButtonSubtle?: boolean;
    variant?: string;
}

const aiAnimation = keyframes`
  0% {
    background-position: -100% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;


export const StyledText = styled.div<TextProps>`
    font-family: ${props => props.theme.global.fontFamily};
    color: ${props => props.color || props.theme.text.color};
    font-size: ${props => props.fontSize ||  '13px'};
    font-weight: ${props => props.fontWeight ||  'normal'};
    padding: ${props => props.padding ||  '0'};
    text-align: ${props => props.textAlign ||  'inherit'};
    line-height: ${props => props.lineHeight ||  'normal'};
    width: ${props => props.width ||  'fit-content'};
    text-decoration: ${props => props.textDecoration || 'none'};
    text-transform: ${props => props.textTransform || 'none'};
    justify-content: ${props => props.justifyContent || 'left'};
    ${props => props.flexGrow && `
        flex-grow: ${props.flexGrow};
    `}

    ${props => props.variant && `
        variant: ${props.variant};
    `}

    ${props => props.margin && `
        margin: ${props.margin};
    `}

    ${props => props.textOverflow && `
        text-overflow: ${props.textOverflow};
    `}

    ${props => props.fontStyle && `
        font-style: ${props.fontStyle};
    `}

    ${props => props.isReadMore && `
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: ${props.isExpanded ? 'unset' : 2};
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        max-height: ${props.isExpanded ? 'none' : '3em'};
        // white-space: ${props.isExpanded ? 'normal' : 'nowrap'};
    `}

    ${props => props.aiify && css`
        background: linear-gradient(90deg, #E8C626, #F37F2B, #E8C626);
        background-size: 200%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: ${aiAnimation} 3s infinite linear;    
    `}

    ${props => props.aiText && css`
        background: linear-gradient(90deg, #007CF0, #FFDC39, #7928CA, #FF0080);
        background-size: 300% 100%;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: gradientFlow 8s ease infinite;

        @keyframes gradientFlow {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
        }  
    `}

    ${props => props.aiButtonSubtle && css`
        background: linear-gradient(90deg, #0EA5E9, #2563EB);
        background-size: 200% 100%;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: subtleFlow 4s ease infinite;

        @keyframes subtleFlow {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
        }  
    `}
`