import { Select } from "../Select"
import { ReactComponent as SortIcon } from '../../icons/sort.svg';
import { StyledSort, StyledSortContainer, StyledDropdown, StyledOption } from "./styled"
import { Text } from "../Text";
import { useEffect, useRef, useState } from "react";
import { Button } from "../Button";

const Sort = () => {
    const OPTIONS = [
        {
            label: "Recommended",
            value: "recommended"
        },
        {
            label: "Recently Updated",
            value: "modified_date:desc"
        },
        {
            label: "Recently Added",
            value: "created_date:desc"
        },
        {
            label: "Artwork Year (Ascending)",
            value: "date:asc"
        },
        {
            label: "Artwork Year (Descending)",
            value: "date:desc"
        }
    ]

    const [activeSort, setActiveSort] = useState("Recently Updated")
    const [isOpen, setIsOpen] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const onChange = (option: { label: string; value: string }) => {
        setActiveSort(option.label)
        setIsOpen(false)
    }

    const onClick = () => {
        setIsOpen(!isOpen)
    }
    
    return (
        <StyledSortContainer ref={containerRef}>
            <StyledSort>
                <Button 
                    noStyle 
                    borderRadius="24px" 
                    color="#737373"
                    fontSize="14px"
                    border={"solid 1px #e5e5e5"}
                    padding={"8px 12px"}
                    gap="12px" 
                    width="max-content"
                    onClick={onClick}
                >
                    <Text>Sort: {activeSort}</Text>
                    <SortIcon />
                </Button>
                {isOpen && (
                    <StyledDropdown>
                        {OPTIONS.map((option) => (
                            <StyledOption
                                key={option.value}
                                isActive={option.label === activeSort}
                                onClick={() => onChange(option)}
                            >
                                {option.label}
                            </StyledOption>
                        ))}
                    </StyledDropdown>
                )}
            </StyledSort>
        </StyledSortContainer>
    )
}

export default Sort