import styled from 'styled-components'


interface RecentActivityContainerProps {
    theme: any
}

export const StyledRecentActivityContainer = styled.div<RecentActivityContainerProps>`
    background: ${props => props.theme.CardPrimaryBackgroundColor};
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px rgba(213, 213, 205, 0.25);

`

interface RecentActivityProps {

}

export const StyledRecentActivity = styled.div<RecentActivityProps>`
    height: fit-content;

    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;

    @media (min-width: 768px) {
        height: calc(60vh - 120px);
        overflow-y: scroll;
    }

    @media (min-width: 1024px) {

    }

    // -webkit-mask-image: -webkit-gradient(linear,left 20%,left bottom,from(black),to(rgba(0,0,0,0)))
`


