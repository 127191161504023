import { ReactNode, useEffect, useRef } from "react"
import { StyledExitButton, StyledModal, StyledModalContainer, StyledModalContent, StyledModalContentContainer } from "./styled"
import { useModalStore, usePostStore, useResponsiveStore } from "../../../stores/web.store";
import { Button } from "../../Button";
import {ReactComponent as XIcon} from '../../../img/x.svg'

type PostModallProps = {
    children: ReactNode;
    setModal: any;
    position?: string;
    height?: any;
    width?: any;
    modalHeight?: any;
    top?: string;
    left?: string;
    margin?: any;
    padding?: string;
    background?: string;
    borderRadius?: string;
    modalDimensions?: any;
}

const useOutsideAlerter = (ref: any, setModal: any) => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const setPost = usePostStore((state: any) => state.setPost)

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(false)
            setIsModal(false)
            setPost(null)
            setModalKey('')
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}


const PostModal = ({setModal, modalDimensions, position, height, width, modalHeight, top, left, margin, background, padding, borderRadius, children}: PostModallProps) => {
    // const { ref } = useComponentVisible(setModal);
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const respWidth = useResponsiveStore((state: any) => state.width)
    const wrapperRef = useRef<HTMLDivElement>(null);

    // Prevent scrolling on the body when modal is open
    useEffect(() => {
        // Save the original overflow style
        const originalOverflow = document.body.style.overflow;
        const originalPaddingRight = document.body.style.paddingRight;
        
        // Calculate scrollbar width to prevent layout shift
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        
        // Disable scrolling on the body
        document.body.style.overflow = 'hidden';
        
        // Add padding right to prevent layout shift when scrollbar disappears
        if (scrollbarWidth > 0) {
            document.body.style.paddingRight = `${scrollbarWidth}px`;
        }
        
        // Prevent touchmove events on mobile
        const preventTouchMove = (e: TouchEvent) => {
            // Allow scrolling within the modal content
            if (wrapperRef.current && wrapperRef.current.contains(e.target as Node)) {
                return;
            }
            e.preventDefault();
        };
        
        document.addEventListener('touchmove', preventTouchMove, { passive: false });
        
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = originalOverflow;
            document.body.style.paddingRight = originalPaddingRight;
            document.removeEventListener('touchmove', preventTouchMove);
        };
    }, []);

    // TEMP: Comment out for now
    useOutsideAlerter(wrapperRef, setModal);

    const onClick = () => {
        setIsModal(false)
        setModalKey('')
        setModal(false)
    }

    return (
        <StyledModalContainer position={position} top={top} left={left}>
            <StyledModal width={width} height={modalHeight} margin={margin}>
                <StyledModalContentContainer modalDimensions={modalDimensions}>
                    
                    <StyledModalContent 
                        height={height} 
                        background={background} 
                        padding={padding} 
                        borderRadius={borderRadius} 
                        ref={wrapperRef}
                    >
                        {children}
                    </StyledModalContent>
                </StyledModalContentContainer>
            </StyledModal>
            <StyledExitButton>
                <Button onClick={onClick} noStyle background="#f5f5f5" borderRadius="50%" padding="0px" width="40px" height="40px">
                    {respWidth > 768 ? <XIcon height={20} width={20} stroke="#000" strokeWidth={4} /> : "Back"}
                </Button>
            </StyledExitButton>
        </StyledModalContainer>
    )
}

export default PostModal