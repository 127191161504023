import {useCallback, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import type { DraggableProvided, DraggableStateSnapshot, DroppableProvided, DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { ReactComponent as UploadMedia } from '../../icons/upload.svg';
import { StyledMediaUploadContainer, StyledMediaWrapper, StyledRemoveImageContainer, StyledUploadButtonContainer, StyledUploadContainer, StyledUploadTitleContainer, StyledUploadedMedia, StyledUploadedMediaContainer, StyledUploadedPreview, StyledUploadedPreviewContainer, StyledDeleteButton } from './styled'
import { Text } from '../Text';
import { Button } from '../Button';
import { Image } from '../Image';
import { useThemeStore } from '../../stores/web.store';
import { Video } from '../Video';
import React from 'react';
import { MediaEditor } from './MediaEditor';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../icons/alert.svg';
import { ReactComponent as DeleteIcon } from '../../icons/close.svg';

// Maximum number of images allowed
const MAX_IMAGES = 20;

// Styled component for the upload limit error message
const UploadLimitMessage = styled.div`
  display: flex;
  align-items: center;
  background-color: #FFF5F5;
  border: 1px solid #FED7D7;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 16px;
  color: #E53E3E;
  
  svg {
    margin-right: 12px;
    min-width: 20px;
  }
`;

interface ImageState {
    scale: number;
    position: { x: number; y: number };
    rotation: number;
}

export interface MediaItem {
    url: string;
    file: File;
    index: number;
    editState?: ImageState;
}

interface UploadedPreviewProps {
    file: MediaItem;
    media: MediaItem[];
    setMedia?: ((media: MediaItem[]) => void) | null;
    height?: string;
    width?: string;
    index?: number;
    onClick?: (index: number) => void;
}

export const UploadedPreview = ({file, media, setMedia, height, width, index = 0, onClick}: UploadedPreviewProps) => {
    const handleClick = () => {
        if (onClick) {
            onClick(index);
        }
    }
    
    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent triggering the parent click handler
        if (setMedia) {
            setMedia(media.filter((mf: MediaItem) => mf.file.name !== file.file.name));
        }
    }

    const preview = (
        <StyledUploadedPreview>
            {file.file?.type.includes('video') ? 
                <Video src={file.url} width={"100%"} height={"inherit"} objectFit={'cover'} borderRadius='12px' border='solid 1px #7C736B'/> : 
                <Image src={file.url} width={"100%"} height={"inherit"} objectFit={'cover'} borderRadius='12px' border='solid 1px #7C736B'/>
            }
        </StyledUploadedPreview>
    );

    // If no setMedia is provided, render without drag functionality
    if (!setMedia) {
        return (
            <StyledUploadedPreviewContainer height={height} width={width}>
                {preview}
            </StyledUploadedPreviewContainer>
        );
    }

    return (
        <Draggable draggableId={`media-${file.file.name}`} index={index}>
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                <StyledUploadedPreviewContainer 
                    height={height} 
                    width={width}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    isDragging={snapshot.isDragging}
                    onClick={handleClick}
                >
                    {preview}
                    <StyledDeleteButton 
                        className="delete-button"
                        onClick={handleDelete}
                        aria-label="Remove image"
                    >
                        <DeleteIcon stroke='#fff'/>
                    </StyledDeleteButton>
                </StyledUploadedPreviewContainer>
            )}
        </Draggable>
    )
}

interface MediaUploadProps {
    payload: any;
    setPayload: (payload: any) => void;
    height?: string;
}

const MediaUpload = ({payload, setPayload, height}: MediaUploadProps) => {
    const theme = useThemeStore((state: any) => state.theme)
    const [media, setMedia] = useState<MediaItem[]>([])
    const [isInitialized, setIsInitialized] = useState(false)
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [filesToEdit, setFilesToEdit] = useState<File[]>([]);
    const [editState, setEditState] = useState<ImageState | undefined>(undefined);
    const [editingIndex, setEditingIndex] = useState<number>(-1);
    const [isUploadLimitReached, setIsUploadLimitReached] = useState(false);

    useEffect(() => {
        if (!isInitialized && payload.media !== undefined) {
            const mediaItems = payload.media.map((file: any, index: number) => ({
                url: URL.createObjectURL(file),
                file,
                index,
            }));
            setMedia(mediaItems);
            setIsInitialized(true);
            
            // Check if upload limit is reached on initialization
            if (mediaItems.length >= MAX_IMAGES) {
                setIsUploadLimitReached(true);
            }
        } else if (!isInitialized && payload.media === undefined) {
            setIsInitialized(true)
        }
    }, [payload.media, isInitialized])

    // Update upload limit status whenever media changes
    useEffect(() => {
        setIsUploadLimitReached(media.length >= MAX_IMAGES);
    }, [media.length]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        // Check if adding these files would exceed the limit
        if (media.length + acceptedFiles.length > MAX_IMAGES) {
            // If we're already at the limit, don't open the editor
            if (media.length >= MAX_IMAGES) {
                setIsUploadLimitReached(true);
                return;
            }
            
            // If adding all files would exceed the limit, only take what we can
            const availableSlots = MAX_IMAGES - media.length;
            const filesToAdd = acceptedFiles.slice(0, availableSlots);
            
            setFilesToEdit(filesToAdd);
            setIsUploadLimitReached(true);
        } else {
            setFilesToEdit(acceptedFiles);
        }
        
        setEditState(undefined);
        setEditingIndex(-1);
        setIsEditorOpen(true);
    }, [media.length]);

    const handleEditorSave = (editedFiles: { file: File; preview: string; state: ImageState }[]) => {
        console.log('Edited files:', editedFiles); // Debug log

        setMedia(prevMedia => {
            const newMedia = [...prevMedia];
            if (editingIndex >= 0) {
                // Replace existing media
                newMedia[editingIndex] = {
                    url: editedFiles[0].preview,
                    file: editedFiles[0].file,
                    index: editingIndex,
                    editState: editedFiles[0].state
                };
            } else {
                // Add new media
                editedFiles.forEach((edited, index) => {
                    newMedia.push({
                        url: edited.preview,
                        file: edited.file,
                        index: prevMedia.length + index,
                        editState: edited.state
                    });
                });
            }

            // Get all files including previously edited ones
            const allFiles = newMedia.map(item => {
                if (item.editState) {
                    // If this item has edits, we need to apply them
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    if (!ctx) return item.file;

                    const img = document.createElement('img');
                    
                    // Need to wait for image to load before drawing
                    return new Promise<File>((resolve) => {
                        img.onload = () => {
                            canvas.width = img.naturalWidth || 800; // fallback size
                            canvas.height = img.naturalHeight || 600;

                            ctx.fillStyle = '#f5f5f5';
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                            
                            ctx.save();
                            ctx.translate(canvas.width / 2, canvas.height / 2);
                            ctx.rotate((item.editState!.rotation * Math.PI) / 180);
                            ctx.scale(item.editState!.scale, item.editState!.scale);
                            ctx.translate(
                                -canvas.width / 2 + item.editState!.position.x,
                                -canvas.height / 2 + item.editState!.position.y
                            );
                            
                            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                            ctx.restore();

                            // Create new file from canvas
                            canvas.toBlob((blob) => {
                                if (!blob) {
                                    resolve(item.file);
                                    return;
                                }
                                resolve(new File([blob], item.file.name, {
                                    type: 'image/jpeg',
                                    lastModified: new Date().getTime()
                                }));
                            }, 'image/jpeg', 1.0);
                        };
                        img.src = item.url;
                    });
                }
                return Promise.resolve(item.file);
            });

            // Wait for all files to be processed
            Promise.all(allFiles).then(processedFiles => {
                console.log('All files processed:', processedFiles); // Debug log
                setPayload({
                    ...payload,
                    type: 'media',
                    media: processedFiles
                });
            });

            return newMedia;
        });
        setIsEditorOpen(false);
        setEditingIndex(-1);
        setEditState(undefined);
    };

    const handlePreviewClick = (index: number) => {
        const item = media[index];
        setFilesToEdit([item.file]);
        setEditState(item.editState);
        setEditingIndex(index);
        setIsEditorOpen(true);
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        setMedia(prevMedia => {
            const items = Array.from(prevMedia);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination!.index, 0, reorderedItem);

            // Update indices after reordering
            const updatedItems = items.map((item, index) => ({
                ...item,
                index
            }));

            // Update payload with files in new order
            const files = updatedItems.map(item => item.file);
            console.log('Reordered files:', files); // Debug log

            setPayload({
                ...payload,
                type: 'media',
                media: files
            });

            return updatedItems;
        });
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        // Disable the dropzone if we've reached the limit
        disabled: isUploadLimitReached
    });

    return (
        <>
            {isUploadLimitReached && (
                <UploadLimitMessage>
                    <AlertIcon width="20" height="20" />
                    <Text>You've Reached The Upload Limit. Only {MAX_IMAGES} photos can be added.</Text>
                </UploadLimitMessage>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
                <StyledMediaWrapper>
                    <StyledMediaUploadContainer 
                        {...getRootProps()} 
                        height={height}
                        style={{ 
                            opacity: isUploadLimitReached ? 0.6 : 1,
                            cursor: isUploadLimitReached ? 'not-allowed' : 'pointer'
                        }}
                    >
                        <StyledUploadContainer>
                            <UploadMedia />
                        </StyledUploadContainer>
                        <StyledUploadTitleContainer>
                            <input {...getInputProps()} />
                        
                            {
                            isDragActive ?
                                <Text>Drop the files here ...</Text> :
                                <Text fontSize='12px'>
                                    {isUploadLimitReached 
                                        ? `Maximum ${MAX_IMAGES} images reached` 
                                        : `Max 12 MB, PNG, JPG, MP3, MP4 (${media.length}/${MAX_IMAGES})`}
                                </Text>
                            }
                            <StyledUploadButtonContainer>
                                <Button 
                                    noStyle 
                                    fontWeight='400'
                                    border={`1px solid #E5E5E5`}
                                    borderRadius='24px'
                                    padding='12px 16px'
                                    fontSize='16px'
                                    disabled={isUploadLimitReached}
                                    style={{
                                        opacity: isUploadLimitReached ? 0.6 : 1,
                                        cursor: isUploadLimitReached ? 'not-allowed' : 'pointer'
                                    }}
                                >Browse Files</Button>
                            </StyledUploadButtonContainer>
                        </StyledUploadTitleContainer>
                    </StyledMediaUploadContainer>
                    <StyledUploadedMediaContainer>
                        <Droppable droppableId="mediaList" direction="horizontal">
                            {(provided: DroppableProvided) => (
                                <StyledUploadedMedia 
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        overflowX: 'auto',
                                    }}
                                >
                                    {media.map((file: MediaItem, idx: number) => (
                                        <UploadedPreview 
                                            key={`media-${file.file.name}`} 
                                            media={media} 
                                            file={file} 
                                            setMedia={(updatedMedia) => {
                                                setMedia(updatedMedia);
                                                // Update payload with files in new order
                                                const files = updatedMedia.map(item => item.file);
                                                setPayload({
                                                    ...payload,
                                                    type: 'media',
                                                    media: files
                                                });
                                                // Check if we're still at the limit after removing an item
                                                setIsUploadLimitReached(updatedMedia.length >= MAX_IMAGES);
                                            }}
                                            index={idx}
                                            onClick={handlePreviewClick}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </StyledUploadedMedia>
                            )}
                        </Droppable>
                    </StyledUploadedMediaContainer>
                </StyledMediaWrapper>
            </DragDropContext>
            {isEditorOpen && (
                <MediaEditor
                    files={filesToEdit}
                    onSave={handleEditorSave}
                    onClose={() => {
                        setIsEditorOpen(false);
                        setEditingIndex(-1);
                        setEditState(undefined);
                    }}
                    isOpen={isEditorOpen}
                    initialState={editState}
                />
            )}
        </>
    )
}

export default MediaUpload