import { useState } from "react"
import { useLandingPageStateStore, useResponsiveStore, useSignupStore } from "../../stores/web.store"
import { Desktop, Laptop } from "../../utils/responsive.util"
import { Button } from "../Button"
import { EmailInput, PasswordInput, Providers, SignupButton } from "../Signup/Signup"
import { StyledBackToSignIn, StyledSignupForm, StyledSignupFormContainer, StyledSignupTitleContainer } from "../Signup/styled"
import { Text } from "../Text"
import { StyledRememberMeCheckboxContainer, StyledSignIn, StyledSignInContainer } from "./styled"
import { Input } from "../Input"
import { resetPassword } from "../../services/firebase.service"


const RememberMeCheckbox = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const setSignupStore = useSignupStore((state: any) => state.setSignupStore)

    const onChange = (e: any) => {
        setSignupStore({...signupStore, name: e.target.value})
    }
    
    return (
        <StyledRememberMeCheckboxContainer>
            <Input 
                type={'checkbox'}
                padding={[Desktop, Laptop].includes(mode) ? '12px 0px' : '16px 0px'}
                fontSize={'16px'}
                width={'90%'}
                value={signupStore.remember_me || ''}
                onChange={onChange}
                background={'unset'}
                border={'solid 1px #787878'}
                borderRadius="12px"
                color={'#787878'}
                outline="none"
            />
            <Text color={'#787878'} fontSize="16px" fontWeight="500">Remember me</Text>
        </StyledRememberMeCheckboxContainer>
    )
}

const SignupTitle = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    return (
        <StyledSignupTitleContainer>
            <Text 
                color={'#fff'} 
                padding={[Desktop, Laptop].includes(mode) ? '8px 0px 32px 0px' : '4px 0px 20px 0px'} 
                fontSize={[Desktop, Laptop].includes(mode) ? '24px' : '24px'} 
                fontWeight={'500'}
            >{'Sign in'}</Text>
        </StyledSignupTitleContainer>
    )
}

const SignupForm = () => {
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const [resetStatus, setResetStatus] = useState<string>("")

    const onClickForgotPassword = async () => {
        try {
            if (!signupStore.email) {
                setResetStatus("Please enter your email address")
                return
            }

            const response = await resetPassword(signupStore.email)
            setResetStatus(response.message)
        } catch (error: any) {
            setResetStatus("An error occurred. Please try again.")
        }
    }

    return (
        <StyledSignupFormContainer>
            <StyledSignupForm>
                {resetStatus && (
                    <Text 
                        color={resetStatus.includes("sent") ? "#4CAF50" : "#f44336"}
                        style={{
                            position: "absolute",
                            top: "-24px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: "100%",
                            textAlign: "center"
                        }}
                    >
                        {resetStatus}
                    </Text>
                )}
                <EmailInput />
                <PasswordInput />
                {/* <RememberMeCheckbox /> */}
                <SignupButton />
                <Button 
                    noStyle 
                    color="#1976d2" 
                    fontWeight="600" 
                    padding="8px 0px 0px 0px"
                    onClick={onClickForgotPassword}
                >
                    Forgot Password?
                </Button>
            </StyledSignupForm>
        </StyledSignupFormContainer>
    )
}


const SignIn = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const setActiveState = useLandingPageStateStore((state: any) => state.setActiveState)
    return (
        <StyledSignInContainer>
            <StyledSignIn>
                <SignupTitle />
                <SignupForm />
                <Text color="#fff" width="100%" textAlign={'center'} fontSize={'16px'} padding={[Desktop, Laptop].includes(mode) ? '24px 0px' : '0px'}>or</Text>
                <Providers />
                <StyledBackToSignIn>
                    <Text color="#958D8C" fontWeight="600" textAlign={[Desktop, Laptop].includes(mode) ? 'left' : 'center'}>{"Don't have a Primary Yellow Account yet?"}</Text>
                    <Button noStyle fontWeight="500" onClick={() => setActiveState('createAccount')} color="#fff">Create an account</Button>
                </StyledBackToSignIn>
            </StyledSignIn>
        </StyledSignInContainer>
    )
}

export default SignIn