import Button from "../Button";
import { StyledShare, StyledShareContainer } from "./styled"
import {ReactComponent as SendIcon} from '../../../icons/send.svg'
import { Text } from "../../Text";
import { SharePopOut } from "../../PopOuts/Share";
import { useEffect, useRef, useState } from "react";
import { ShareModal } from "../../Modal/ShareModal";

type ShareProps = {
    data?: any;
    noTitle?: boolean;
    noAdjust?: boolean;
}

const useOutsideAlerter = (ref: any, setModal: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const Share = ({data, noTitle, noAdjust}: ShareProps) => {
    const [isActive, setIsActive] = useState(false)

    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, setIsActive);

    return (
        <StyledShareContainer ref={wrapperRef}>
            <StyledShare>
                <Button onClick={() => setIsActive(!isActive)} noStyle>
                    <SendIcon />
                    {!noTitle && <Text>{`Share`}</Text>}
                </Button>
            </StyledShare>
            {isActive && <ShareModal data={data} setIsModal={setIsActive}/>}
        </StyledShareContainer>
    )
}

export default Share