import Button from "../Button";
import { StyledComment, StyledCommentContainer } from "./styled"
import {ReactComponent as ChatIcon} from '../../../icons/chat.svg'
import { useEffect, useRef, useState } from "react";
import { useLexicalContextStore } from "../../../stores/web.store";
import { $getRoot } from "lexical";
import { lexicalClear, lexicalFocus } from "../../../utils/app.util";

type ShareProps = {
    data?: any;
    noTitle?: boolean;
    uid?: string;
}

const useOutsideAlerter = (ref: any, setModal: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const Comment = ({data, noTitle, uid}: ShareProps) => {
    const lexicalContext = useLexicalContextStore((state: any) => state.lexicalContext)
    const [editor, setEditor] = useState<any>(null)

    // useEffect(() => {
    //     console.log('here', lexicalContext)
    //     if (uid && uid in lexicalContext) {
    //         setEditor(lexicalContext[uid])
    //     }
    // }, [lexicalContext])
    
    // const [editor] = useLexicalComposerContext()
    
    const [isActive, setIsActive] = useState(false)

    const onClick = () => {
        if (uid) {
            lexicalFocus(uid)
            lexicalClear(uid)
        }
        
        // if (editor) {
        //     console.log('editor', editor)
        //     editor.focus()
        // }
        
        setIsActive(!isActive)
    }

    return (
        <StyledCommentContainer>
            <StyledComment>
                <Button onClick={onClick} noStyle>
                    <ChatIcon />
                </Button>
            </StyledComment>
        </StyledCommentContainer>
    )
}

export default Comment