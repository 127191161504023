import styled from 'styled-components'


interface MarkdownContainerProps {
    border?: string;
    borderTop?: string;
    borderBottom?: string;
    borderLeft?: string;
    borderRight?: string;
    borderRadius?: string;
    singleLine?: boolean;
}


export const StyledMarkdownContainer = styled.div<MarkdownContainerProps>`
    position: relative;
    border: ${props => props.singleLine ? 'none' : 'solid 1px #e5e5e5'};
    border-radius: ${props => props.borderRadius || "10px"};
    margin: 0;

    ${props => props.borderTop && `
        border-top: ${props.borderTop};
    `}

    ${props => props.borderBottom && `
        border-bottom: ${props.borderBottom};
    `}

    ${props => props.borderLeft && `
        border-left: ${props.borderLeft};
    `}

    ${props => props.borderRight && `
        border-right: ${props.borderRight};
    `}
        

    ${props => props.border && `
        border: ${props.border};
    `}
`

interface MarkdownProps {
    singleLine?: boolean;
    noEmoji?: boolean;
}


export const StyledMarkdown = styled.div<MarkdownProps>`
    
    ${props => props.singleLine && `
        .editor-input {
            min-height: 24px !important;
            height: 24px;
            flex-grow: 1;
            padding: 8px 0px 4px 4px;
        }

        .editor-inner {
            display: inline-flex;
            width: 100%;
            align-items: center;
            border-radius: 24px;
            justify-content: middle;
        }

        .editor-placeholder {
            left: ${props.noEmoji ? '4px' : '20px'};
            top: 8px;
        }
    `}
     
`

interface EmojiContainerProps {
    singleLine?: boolean;
    emoji?: any;
}


export const StyledEmojiContainer = styled.div<EmojiContainerProps>`
    position: relative;
    padding: ${props => props.emoji?.padding || '0'};
    ${props => props.singleLine && `
        width: fit-content;
    `}
`

interface EmojiProps {
    isActive?: boolean;
    singleLine?: boolean;
}


export const StyledEmoji = styled.div<EmojiProps>`
    position: absolute;
    height: ${props => props.isActive ? 'auto' : '0'};
    left: 24px;
    top: 2px;
    overflow: hidden;
    z-index: 1;

    ${props => props.singleLine && `
        // position: fixed;
        top: unset;
        bottom: 40px;
        left: 0px;
    `}
`