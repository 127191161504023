import styled from 'styled-components'


interface DashboardContainerProps {

}

export const StyledDashboardContainer = styled.div<DashboardContainerProps>`
    background: rgba(15, 24, 31, 1);
    height: calc(100vh - 84px);
    position: absolute;
    top: 84px;
    left: 0;
    width: 100%;
    overflow-y: scroll;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
`

interface DashboardProps {

}

export const StyledDashboard = styled.div<DashboardProps>`


    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {

    }
`

interface ViewToggleContainerProps {

}

export const StyledViewToggleContainer = styled.div<ViewToggleContainerProps>`

`

interface ViewToggleProps {

}

export const StyledViewToggle = styled.div<ViewToggleProps>`
    & button {
        margin: 0px 4px;
    }
`

interface MetricsContainerProps {

}

export const StyledMetricsContainer = styled.div<MetricsContainerProps>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
`

interface MetricsProps {

}

export const StyledMetrics = styled.div<MetricsProps>`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
    padding: 0px 0px 60px 0px;
`

interface TotalContainerProps {

}

export const StyledTotalContainer = styled.div<TotalContainerProps>`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 32px;
    width: 100%;
    padding: 24px 0px;
`