import { Story } from "./Stories";

export const mockStories: Story[] = [
  {
    id: "1",
    userId: "user1",
    username: "Jordan Smith",
    userImage: "https://randomuser.me/api/portraits/men/32.jpg",
    media: [
      {
        type: "image",
        url: "https://images.pexels.com/photos/1266808/pexels-photo-1266808.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 3600000).toISOString() // 1 hour ago
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 3500000).toISOString()
      }
    ],
    viewed: false
  },
  {
    id: "2",
    userId: "user2",
    username: "Taylor Brown",
    userImage: "https://randomuser.me/api/portraits/men/22.jpg",
    media: [
      {
        type: "image",
        url: "https://images.pexels.com/photos/1918290/pexels-photo-1918290.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 7200000).toISOString() // 2 hours ago
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1183992/pexels-photo-1183992.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 7100000).toISOString()
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1646953/pexels-photo-1646953.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 7000000).toISOString()
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1366957/pexels-photo-1366957.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 6900000).toISOString()
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1212600/pexels-photo-1212600.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 6800000).toISOString()
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1616403/pexels-photo-1616403.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 6700000).toISOString()
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1573324/pexels-photo-1573324.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 6600000).toISOString()
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1292998/pexels-photo-1292998.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 6500000).toISOString()
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1674049/pexels-photo-1674049.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 6400000).toISOString()
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1269968/pexels-photo-1269968.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 6300000).toISOString()
      }
    ],
    viewed: false
  },
  {
    id: "3",
    userId: "user3",
    username: "Morgan Lee",
    userImage: "https://randomuser.me/api/portraits/women/44.jpg",
    media: [
      {
        type: "image",
        url: "https://images.pexels.com/photos/1579708/pexels-photo-1579708.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 10800000).toISOString() // 3 hours ago
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1376766/pexels-photo-1376766.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 10700000).toISOString()
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1426718/pexels-photo-1426718.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 10600000).toISOString()
      }
    ],
    viewed: true
  },
  {
    id: "4",
    userId: "user4",
    username: "Casey White",
    userImage: "https://randomuser.me/api/portraits/men/67.jpg",
    media: [
      {
        type: "image",
        url: "https://images.pexels.com/photos/1123982/pexels-photo-1123982.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 14400000).toISOString() // 4 hours ago
      }
    ],
    viewed: false
  },
  {
    id: "5",
    userId: "user5",
    username: "Riley Johnson",
    userImage: "https://randomuser.me/api/portraits/women/32.jpg",
    media: [
      {
        type: "image",
        url: "https://images.pexels.com/photos/1572386/pexels-photo-1572386.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 18000000).toISOString() // 5 hours ago
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1366630/pexels-photo-1366630.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 17900000).toISOString()
      }
    ],
    viewed: false
  },
  {
    id: "6",
    userId: "user6",
    username: "Alex Garcia",
    userImage: "https://randomuser.me/api/portraits/men/42.jpg",
    media: [
      {
        type: "image",
        url: "https://images.pexels.com/photos/1261731/pexels-photo-1261731.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 21600000).toISOString() // 6 hours ago
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1770809/pexels-photo-1770809.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 21500000).toISOString()
      }
    ],
    viewed: false
  },
  {
    id: "7",
    userId: "user7",
    username: "Jamie Wilson",
    userImage: "https://randomuser.me/api/portraits/women/56.jpg",
    media: [
      {
        type: "image",
        url: "https://images.pexels.com/photos/1591373/pexels-photo-1591373.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 25200000).toISOString() // 7 hours ago
      }
    ],
    viewed: true
  },
  {
    id: "8",
    userId: "user8",
    username: "Sam Parker",
    userImage: "https://randomuser.me/api/portraits/men/91.jpg",
    media: [
      {
        type: "image",
        url: "https://images.pexels.com/photos/1209843/pexels-photo-1209843.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 28800000).toISOString() // 8 hours ago
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1181316/pexels-photo-1181316.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 28700000).toISOString()
      }
    ],
    viewed: false
  },
  {
    id: "9",
    userId: "user9",
    username: "Quinn Thomas",
    userImage: "https://randomuser.me/api/portraits/women/79.jpg",
    media: [
      {
        type: "image",
        url: "https://images.pexels.com/photos/1684151/pexels-photo-1684151.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 32400000).toISOString() // 9 hours ago
      },
      {
        type: "image",
        url: "https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 32300000).toISOString()
      }
    ],
    viewed: false
  },
  {
    id: "10",
    userId: "user10",
    username: "Drew Martinez",
    userImage: "https://randomuser.me/api/portraits/men/55.jpg",
    media: [
      {
        type: "image",
        url: "https://images.pexels.com/photos/1266808/pexels-photo-1266808.jpeg?auto=compress&cs=tinysrgb&w=800",
        createdAt: new Date(Date.now() - 36000000).toISOString() // 10 hours ago
      }
    ],
    viewed: true
  }
];

export const currentUserMock = {
  id: "currentUser",
  username: "You",
  profileImage: "https://randomuser.me/api/portraits/women/68.jpg"
};
