import { Button } from "../../../components/Button";
import { Image } from "../../../components/Image";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View"
import { useResponsiveStore } from "../../../stores/web.store";


type CardProps = {
    title: string;
    description: string;
    image: string;
    onClick: any;
    isReverse?: boolean;
}


const Card = ({title, description, image, isReverse, onClick}: CardProps) => {
    const width = useResponsiveStore((state: any) => state.width)
    return (
        <View>
            <View 
                display="flex" 
                gap={width > 768 ? "64px" : "24px"}
                width="100%" 
                alignItems="center" 
                flexDirection={isReverse ? width > 768 ? 'row-reverse' : 'column-reverse' : width > 768 ? 'row': 'column-reverse'}
            >
                <View width={width > 768 ? "50%" : "100%"}>
                    <View display="flex" flexDirection="column" gap="24px">
                        <Text fontSize={width > 768 ? "36px" : "28px"} fontWeight="500">{title}</Text>
                        <Text fontSize={width > 768 ? "28px" : "16px"}>{description}</Text>
                        <View>
                            <Button 
                                onClick={onClick} 
                                background="#fff" 
                                border="solid 1px #000"
                                fontSize="16px"
                                padding="6px 12px"
                                borderRadius="24px"
                            >{'View'}</Button>
                        </View>
                    </View>
                </View>
                <View width={width > 768 ? "50%" : "100%"}>
                    {image ? <Image 
                        src={image}
                        width="100%" 
                        height="422px" 
                        borderRadius="10px" 
                        isNoEffect
                     /> : <View />}
                </View>
            </View>
        </View>
    )
}

export default Card