import { useAccountStore, usePostPayloadStore } from "../../../stores/web.store"
import { FeedPost } from "../../FeedPost"
import { ImageGallery } from "../../ImageGallery"
import { Post } from "../../Post"
import { ProfilePicture } from "../../ProfilePicture"

import { View } from "../../View"
import { StyledPreview, StyledPreviewContainer} from "./styled"


const Preview = () => {
    const account = useAccountStore((state: any) => state.account)
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)

    return (
        <StyledPreviewContainer>
            <StyledPreview>
                <View>
                    <FeedPost data={{...postPayload, owner: account, comments: []}}/>
                </View>
            </StyledPreview>
        </StyledPreviewContainer>
    )
}

export default Preview