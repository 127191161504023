import { useThemeStore } from "../../../stores/theme.store"
import Button from "../Button"
import {ReactComponent as BagIcon} from '../../../icons/bag.svg'

const ShoppingBag = () => {
    const theme = useThemeStore((state: any) => state.theme)

    const onClick = () => {
        
    }

    return (
        <Button 
            noStyle 
            border={`solid 1px ${theme.BorderColor}`} 
            padding="8px"
            borderRadius="50%"
            onClick={onClick}
        >
            <BagIcon />
        </Button>
    )
}

export default ShoppingBag