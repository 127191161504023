import { ReactNode, useEffect, useRef } from "react"
import { StyledModal, StyledModalContainer, StyledModalContent, StyledModalContentContainer } from "./styled"
import { useComponentVisible } from "../../../utils/app.util"
import { useModalStore, usePostStore, useProductDetailPageStore } from "../../../stores/web.store";


type MediaModalProps = {
    children: ReactNode;
    setModal: any;
    position?: string;
    height?: any;
    width?: any;
    modalHeight?: any;
    top?: string;
    left?: string;
    margin?: any;
    padding?: string;
    background?: string;
    borderRadius?: string;
}

const useOutsideAlerter = (ref: any, setModal: any) => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const pdp = useProductDetailPageStore((state: any) => state.pdp)
    const post = usePostStore((state: any) => state.post)
    
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            if (pdp === 'show') {
                setModal(post.parent_post)
            } else {
                setModal(false)
            }

            if (pdp === 'auction') {
                setModal(post)
            } else {
                setModal(false)
            }

            if (pdp === 'auctionItem') {
                setModal(post)
            } else {
                setModal(false)
            }

            if (pdp === 'artwork') {
                setModal(post)
            } else {
                setModal(false)
            }
            
            setIsModal(false)
            setModalKey('')
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const MediaModal = ({setModal, position, height, width, modalHeight, top, left, margin, background, padding, borderRadius, children}: MediaModalProps) => {
    // const { ref } = useComponentVisible(setModal);
    const wrapperRef = useRef<HTMLDivElement>(null);

    // Prevent scrolling on the body when modal is open
    useEffect(() => {
        // Save the original overflow style
        const originalOverflow = document.body.style.overflow;
        const originalPaddingRight = document.body.style.paddingRight;
        
        // Calculate scrollbar width to prevent layout shift
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        
        // Disable scrolling on the body
        document.body.style.overflow = 'hidden';
        
        // Add padding right to prevent layout shift when scrollbar disappears
        if (scrollbarWidth > 0) {
            document.body.style.paddingRight = `${scrollbarWidth}px`;
        }
        
        // Prevent touchmove events on mobile
        const preventTouchMove = (e: TouchEvent) => {
            // Allow scrolling within the modal content
            if (wrapperRef.current && wrapperRef.current.contains(e.target as Node)) {
                return;
            }
            e.preventDefault();
        };
        
        document.addEventListener('touchmove', preventTouchMove, { passive: false });
        
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = originalOverflow;
            document.body.style.paddingRight = originalPaddingRight;
            document.removeEventListener('touchmove', preventTouchMove);
        };
    }, []);

    useOutsideAlerter(wrapperRef, setModal);
    return (
        <StyledModalContainer position={position} top={top} left={left} >
            <StyledModal width={width} height={modalHeight} margin={margin}>
                <StyledModalContentContainer ref={wrapperRef}>
                    <StyledModalContent height={height} background={background} padding={padding} borderRadius={borderRadius}>
                        {children}
                    </StyledModalContent>
                </StyledModalContentContainer>
            </StyledModal>
        </StyledModalContainer>
    )
}

export default MediaModal