import React from "react";
import { StyledNavigator, StyledStep, StyledStepContainer, StyledStepLine } from "./styled";

export interface NavigatorProps {
  steps: number;
  activeStep: number;
  onStepClick?: (step: number) => void;
}

export const Navigator: React.FC<NavigatorProps> = ({ 
  steps, 
  activeStep, 
  onStepClick 
}) => {
  return (
    <StyledNavigator>
      {Array.from({ length: steps }).map((_, index) => {
        const stepNumber = index + 1;
        const isActive = stepNumber === activeStep;
        const isCompleted = stepNumber < activeStep;
        
        return (
          <React.Fragment key={stepNumber}>
            {index > 0 && (
              <StyledStepLine 
                isActive={stepNumber <= activeStep} 
              />
            )}
            <StyledStepContainer>
              <StyledStep 
                isActive={isActive}
                isCompleted={isCompleted}
                onClick={() => onStepClick && onStepClick(stepNumber)}
              >
                {stepNumber}
              </StyledStep>
            </StyledStepContainer>
          </React.Fragment>
        );
      })}
    </StyledNavigator>
  );
};

export default Navigator;
