import { StyledCreateDashboardContainer, StyledDashboardContent, StyledMainDashboardContainer } from "./styled";

import { Profile } from "../../sections/Profile";

import { DashboardNavigation } from "../../sections/DashboardNavigation";
import { AllPosts } from "../../sections/AllPosts";
import { useAccountStore, useAllMyPostsStore, useDashboardCreateModeStore, useDashboardNavigationStore, useProfileStore, useSelectedPostStore } from "../../stores/web.store";
import { Shows } from "../../sections/Shows";
import { Artworks } from "../../sections/Artworks";
import { Auctions } from "../../sections/Auctions";
import { Subscribers } from "../../sections/Subscribers";

import { Saved } from "../../sections/Saved";
import { CreatePost } from "../../sections/CreatePost";

import { useEffect } from "react";
import { DashboardLayout } from "../../sections/Layout/DashboardLayout";
import { StyledDashboard } from "../AdminDashboard/styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Media } from "../../sections/Media";
import api from "../../services/api.service";

import { PricingDatabase } from "../../sections/PricingDatabase";
import { PageLoader } from "../../components/Loader/PageLoader";
import { StyledHeader } from "../Artworks/styled"
import { View } from "../../components/View";
import { Text } from "../../components/Text";


const MainDashboard = ({profile}: any) => {
    const isCreateMode = useDashboardCreateModeStore((state: any) => state.isCreateMode)
    const dashboardNavigation = useDashboardNavigationStore((state: any) => state.dashboardNavigation)

    const account = useAccountStore((state: any) => state.account) 
    const allMyPosts = useAllMyPostsStore((state: any) => state.allMyPosts) 
    const setAllMyPosts = useAllMyPostsStore((state: any) => state.setAllMyPosts) 

    useEffect(() => {
        const fetchAllMyPosts = async (ownerId: string) => {
            const myPosts = await api.post(`/search/my-posts`, {query: {owner_id: ownerId}})
            setAllMyPosts(myPosts.data)
        }

        fetchAllMyPosts(profile.id)
    }, [profile])

    return (
        <StyledMainDashboardContainer isCreateMode={isCreateMode}>
            <Profile profile={profile}/>
            <DashboardNavigation profile={profile}/>
            <div style={{position: 'relative'}}>
                <StyledDashboardContent>
                    {dashboardNavigation === 'all' && <AllPosts data={allMyPosts}/>}
                    {dashboardNavigation === 'posts' && <Media data={allMyPosts}/>}
                    {dashboardNavigation === 'shows' && <Shows data={allMyPosts}/>}
                    {dashboardNavigation === 'artworks' && <Artworks data={allMyPosts}/>}
                    {dashboardNavigation === 'auctions' && <Auctions data={allMyPosts}/>}
                    {dashboardNavigation === 'pricing-database' && <PricingDatabase profile={profile}/>}
                    {account.id === profile.id && dashboardNavigation === 'subscribers' && <Subscribers data={allMyPosts}/>}
                    {account.id === profile.id && dashboardNavigation === 'saved' && <Saved />}
                    {/* {dashboardNavigation === 'insights' && <Insights />} */}
                </StyledDashboardContent>
            </div>
        </StyledMainDashboardContainer>
    )
}

const CreateDashboard = () => {
    const isCreateMode = useDashboardCreateModeStore((state: any) => state.isCreateMode)
    return (
        <StyledCreateDashboardContainer isCreateMode={isCreateMode}>
             <CreatePost />
        </StyledCreateDashboardContainer>
       
    )
}


export const UserProfile = ({profile}: any) => {
    return (
        <StyledDashboard>
            <MainDashboard profile={profile}/>
            <CreateDashboard />
        </StyledDashboard>
    )
}

const DashboardHeader = () => {
    const isCreateMode = useDashboardCreateModeStore((state: any) => state.isCreateMode)
    const selectedPost = useSelectedPostStore((state: any) => state.selectedPost)
    return (
        <StyledHeader>
            <View>
                {!isCreateMode && <Text fontWeight="500" fontSize="16px">Explore</Text>}
                {isCreateMode && <Text fontWeight="500" fontSize="16px">{`Create ${selectedPost.charAt(0).toUpperCase() + selectedPost.slice(1)}`}</Text>}
            </View>
        </StyledHeader>
    )
}

const UserDashboard = () => {
    const location = useLocation()
    const account = useAccountStore((state: any) => state.account) 
    const profile = useProfileStore((state: any) => state.profile)
    const setProfile = useProfileStore((state: any) => state.setProfile)
    const {username} = useParams()

    useEffect(() => {
        const fetch = async () => {
            const resp = await api.get(`/accounts/${username}`)
            setProfile(resp.data)
        }

        if (!profile?.id) {
            if (username) {
                fetch()
            } else {
                setProfile(account)
            }
        }
    }, [location])
    
    if (!profile || (profile && !profile?.id)) return <PageLoader />

    return (
        <DashboardLayout header={<DashboardHeader />}>
            <UserProfile profile={profile}/>
        </DashboardLayout>
    )
}

export default UserDashboard