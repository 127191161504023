import styled from "styled-components";


interface ShareContainerProps {
    left: number;
}

export const StyledShareContainer = styled.div<ShareContainerProps>`
    position: ${props => props.left !== 0 ? 'absolute' : 'relative'};
    left: ${props => props.left}px;
    z-index: 10;
    width: 100%;
`

interface ShareProps {

}

export const StyledShare = styled.div<ShareProps>`
    background: ${props => props.theme?.background || 'white'};
    border-radius: ${props => props.theme?.borderRadius || '0'};
    overflow: hidden;
    width: 100%;
`