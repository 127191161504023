import { View } from "../../View"
import { Text } from "../../Text"
import { Button } from "../../Button"
import { Image } from "../../Image"
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg"
import { StyledCartItem, StyledCartItemImage, StyledCartItemInfo } from "./styled"
import { toCurrency } from "../../../utils/app.util"

interface CartItemProps {
  item: {
    id: string
    title: string
    subtitle: string
    artist: string
    price: number
    image: string
  }
  onRemove: () => void
}

const CartItem = ({ item, onRemove }: CartItemProps) => {
  return (
    <StyledCartItem>
      <StyledCartItemImage>
        <Image 
          src={item.image} 
          width="100%" 
          height="100%" 
          objectFit="cover"
        />
      </StyledCartItemImage>
      
      <StyledCartItemInfo>
        <View>
          <Text fontSize="16px" fontWeight="600">{item.title}</Text>
          <Text fontSize="14px" fontStyle="italic" color="gray.600">{item.subtitle}</Text>
          <Text fontSize="14px" color="gray.800">{item.artist}</Text>
          <Text fontSize="14px" fontWeight="500">Current Bid: {toCurrency(item.price)}</Text>
        </View>
      </StyledCartItemInfo>
      
      <Button noStyle onClick={onRemove}>
        <TrashIcon />
      </Button>
    </StyledCartItem>
  )
}

export default CartItem 