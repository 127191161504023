import styled from "styled-components"

interface ShowsNavigationProps {

}

export const StyledShowsNavigation = styled.div<ShowsNavigationProps>`
    display: inline-flex;
    gap: 24px;
    width: 100%;
    width: max-content;
`