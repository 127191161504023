import { View } from "../../View"
import { SearchBar } from "../../SearchBar"
import { StyledShareModalContainer, StyledShareModal, StyledCloseModalContainer, StyledDivider, StyledProfilesGrid } from "./styled"
import {ReactComponent as XIcon} from '../../../img/x.svg'
import { ProfilePicture } from "../../ProfilePicture"
import { Text } from "../../Text"
import { Button } from "../../Button"
import Modal from "../Modal"
import Share from "../../PopOuts/Share/Share"
import { useEffect, useState } from "react"
import api from "../../../services/api.service"


const ShareModal = ({data, setIsModal}: any) => {
    const [filteredData, setFilteredData] = useState<any>([])
    const [accounts, setAccounts] = useState<any>([])

    useEffect(() => {
        const fetchAccounts = async () => {
            const resp = await api.get('/share-accounts')
            setAccounts(resp.data)
            setFilteredData(resp.data)
        }
        fetchAccounts()
    }, [])

    const onClick = (e: any, acct: any) => {
        // Handle click on profile
    }
    
    return (
        <Modal
            setModal={setIsModal} 
            width={'100%'} 
            height={'100vh'}
            background="rgba(0, 0, 0, .5)"
            noPadding={true}
        >
            <StyledShareModalContainer>
                <StyledShareModal>
                    <View padding="24px 24px 12px 24px">
                        <View display="inline-flex" alignItems="center" width="100%">
                            <View width="80%">
                                <Text fontWeight="500" fontSize="24px" textAlign="left">Share</Text>
                            </View>
                            <View width="20%" textAlign="right">
                                <Button noStyle onClick={() => setIsModal(false)}><XIcon strokeWidth={5} height={16} width={16}/></Button>
                            </View>
                        </View>
                    </View>
                    
                    <View padding="0px 24px 16px 24px">
                        <SearchBar 
                            setData={setFilteredData} 
                            data={filteredData} 
                            resetData={accounts}
                            placeholder="Type a name or gallery to begin sharing..."
                            borderRadius="24px"
                            background="#f5f5f5"
                            border="none"
                        />
                    </View>
                    
                    <StyledProfilesGrid>
                        {filteredData.map((acct: any, index: number) => (
                            <Button key={index} noStyle onClick={(e: any) => onClick(e, acct)} isNoEffect>
                                <View display="flex" flexDirection="column" alignItems="center" gap="8px">
                                    <ProfilePicture 
                                        height="64px" 
                                        width="64px" 
                                        account={acct} 
                                        photo 
                                        onClick={() => {}} 
                                        isNoEffect
                                    />
                                    <Text fontSize="14px" fontWeight="500">{acct.username || acct.name}</Text>
                                </View>
                            </Button>
                        ))}
                    </StyledProfilesGrid>
                    
                    <StyledDivider />
                    
                    <View padding="16px 24px 24px 24px">
                        <Share post={data} noAdjust={true} />
                    </View>
                </StyledShareModal>
            </StyledShareModalContainer>              
        </Modal>
    )
}

export default ShareModal