import { useEffect, useState } from "react"
import { Text } from "../Text"
import { View } from "../View"
import { StyledNotification, StyledNotificationContainer, StyledNotifications, StyledNotificationsContainer, StyledNotificationsWrapper } from "./styled"
import {ReactComponent as XIcon} from '../../img/x.svg'
import { Button } from "../Button"
import { ProfilePicture } from "../ProfilePicture"
import { toCurrency, toDateDiff } from "../../utils/app.util"
import { Image } from "../Image"
import api from "../../services/api.service"
import { RippleLoader } from "../Loader/Loaders/RippleLoader"
import { useAccountStore, useModalStore, usePostStore, useProductDetailPageStore } from "../../stores/web.store"
import { Video } from "../Video"
import { Follow } from "../Button/Follow"
import { Meet } from "../Button/Meet"
import { useThemeStore } from "../../stores/theme.store"



export const OfferNotification = ({data}: any) => {
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const account = useAccountStore((state: any) => state.account)
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    
    const onClickPost = () => {
        // setPost(data.post)
        // if (data.post.type === 'media') {
        //     setModalKey('post')
        // } else {
            
        //     setPdp(data.post.type)
        // }
    }

    const getPostImage = () => {
        if (data.post.posts && data.post.posts.length > 0) {
            return data.post.posts[0].media[0]
        }
        if (data.post.media?.length > 0) {
            return data.post.media[0]
        }
        return {}
    }

    const postImage = getPostImage()

    return (
        <View display="inline-flex" width="100%" alignItems="center">
            <View width="80%">
                <View>
                    <View display="inline-flex" alignItems="center" width="100%" gap="0">
                        <ProfilePicture height="40px" width="40px" fontSize="16px" textAlign="left" account={data.owner} photo />
                        <StyledNotificationContainer>
                            <Text lineHeight="16px" fontSize="14px">
                                <ProfilePicture height="40px" width="40px" fontSize="14px" account={data.source_owner} username/> offered <b>{toCurrency(5000, 'usd')}</b> for {data.post.title}
                            </Text>
                            <Text fontSize="14px" color="#848484">{toDateDiff(data.created_date)}</Text>
                        </StyledNotificationContainer>
                        
                        
                    </View>
                </View>
            </View>
            <View flexGrow="1" textAlign="right">
                <Button noStyle onClick={onClickPost}>
                    {postImage?.content_type?.includes('video') ? 
                        <Video src={postImage?.url} width="40px" height="40px" borderRadius="4px" objectFit="cover"/> : 
                        <Image src={postImage?.url} width="40px" height="40px" borderRadius="4px" objectFit="cover"/>
                    }
                </Button>
            </View>
        </View>
    )
}


export const PurchasedNotification = ({data}: any) => {
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const onClickPost = () => {
        // setPost(data.post)
        // if (data.post.type === 'media') {
        //     setModalKey('post')
        // } else {
            
        //     setPdp(data.post.type)
        // }
    }
    return (
        <View display="inline-flex" width="100%" alignItems="center">
            <View width="80%">
                <View>
                    <View display="inline-flex" alignItems="center" width="100%" gap="0">
                        <ProfilePicture height="40px" width="40px" fontSize="16px" textAlign="left" account={data} photo />
                        <StyledNotificationContainer>
                            <Text lineHeight="16px" fontSize="14px">
                                <ProfilePicture height="40px" width="40px" fontSize="14px" account={data} username/> bought your artwork for <b>{toCurrency(5000, 'usd')}</b>
                            </Text>
                            <Text fontSize="14px" color="#848484">{toDateDiff(data.created_date)}</Text>
                        </StyledNotificationContainer>
                    </View>
                </View>
            </View>
            <View flexGrow="1" textAlign="right">
                <Button noStyle onClick={onClickPost}>
                    {data.post?.media.length > 0 && data.post.media[0].content_type.includes('video') ? 
                        <Video src={data.post.media[0].url} width="40px" height="40px" borderRadius="4px" objectFit="cover"/> : 
                        <Image src={data.post.media[0].url} width="40px" height="40px" borderRadius="4px" objectFit="cover"/>
                    }
                </Button>
            </View>
        </View>
    )
}

export const FollowedNotification = ({data}: any) => {
    const account = useAccountStore((state: any) => state.account)
    const [isFollowing, setIsFollowing] = useState(account.following.filter((following: any) => following.owner_id === data.source_owner?.id).length > 0)
    
    return (
        <View display="inline-flex" width="100%" alignItems="center">
            <View width="70%">
                <View>
                    <View display="inline-flex" alignItems="center" width="100%" gap="0">
                        <ProfilePicture height="40px" width="40px" fontSize="16px" textAlign="left" account={data.source_owner} photo />
                        <StyledNotificationContainer>
                            <Text lineHeight="16px" fontSize="14px">
                                {account?.id === data.source_owner?.id ? 'You' : <ProfilePicture height="40px" width="40px" fontSize="14px" account={data.source_owner} username/>} started following {account?.id === data.owner?.id ? 'you' : <ProfilePicture height="40px" width="40px" fontSize="14px" account={data.owner} username/>}
                            </Text>
                            <Text fontSize="14px" color="#848484">{toDateDiff(data.created_date)}</Text>
                        </StyledNotificationContainer>
                        
                    </View>
                </View>
            </View>
            {account?.id === data.owner?.id && <View flexGrow="1" textAlign="right">
                <Follow title={isFollowing ? 'Unfollow' : 'Follow Back'} data={data.owner} noStyle isFollowing={isFollowing}/>
            </View>}
        </View>
    )
}

export const MessageNotification = ({data}: any) => {
    return (
        <View display="inline-flex" width="100%" alignItems="center">
            <View width="70%">
                <View>
                    <View display="inline-flex" alignItems="center" width="100%" gap="0">
                        <ProfilePicture height="40px" width="40px" fontSize="16px" textAlign="left" account={data.owner} photo />
                        <StyledNotificationContainer>
                            <Text lineHeight="16px" fontSize="14px">
                                <ProfilePicture height="40px" width="40px" fontSize="14px"account={data} username/> sent you a message
                            </Text>
                            <Text fontSize="14px" color="#848484">{toDateDiff(data.created_date)}</Text>
                        </StyledNotificationContainer>

                    </View>
                </View>
            </View>
            <View flexGrow="1" textAlign="right">
                <Button noStyle fontWeight="600">Message</Button>
            </View>
        </View>
    )
}

export const CommentNotification = ({data}: any) => {
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const account = useAccountStore((state: any) => state.account)
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)

    const onClickPost = () => {
        // setPost(data.post)
        // setModalKey('post')
    }

    const getPostImage = () => {
        if (data.post.posts && data.post.posts.length > 0) {
            return data.post.posts[0].media[0]
        }
        if (data.post.media?.length > 0) {
            return data.post.media[0]
        }
        return {}
    }

    const postImage = getPostImage()
    return (
        <View display="inline-flex" width="100%" alignItems="center">
            <View width="80%">
                <View>
                    <View display="inline-flex" alignItems="center" width="100%" gap="0">
                        <ProfilePicture height="40px" width="40px" fontSize="16px" textAlign="left" account={data.source_owner} photo />
                        <StyledNotificationContainer>
                            <Text lineHeight="16px" fontSize="14px">
                                {account?.id === data.source_owner?.id ? 'You' : <ProfilePicture height="40px" width="40px" fontSize="14px" account={data.source_owner} username/> } commented on {account?.id === data.owner?.id ? 'your' : <ProfilePicture height="40px" width="40px" fontSize="14px" account={data.owner} username/>} post
                            </Text>
                            <Text fontSize="14px" color="#848484">{toDateDiff(data.created_date)}</Text>
                        </StyledNotificationContainer>
                    </View>
                </View>
            </View>
            <View flexGrow="1" textAlign="right">
                <Button noStyle onClick={onClickPost}>
                    {postImage?.content_type?.includes('video') ? 
                        <Video src={postImage?.url} width="40px" height="40px" borderRadius="4px" objectFit="cover"/> : 
                        <Image src={postImage?.url} width="40px" height="40px" borderRadius="4px" objectFit="cover"/>
                    }
                </Button>
            </View>
        </View>
    )
}

export const MeetingNotification = ({data}: any) => {
    const account = useAccountStore((state: any) => state.account)
    return (
        <View display="inline-flex" width="100%" alignItems="center">
            <View width="80%">
                <View>
                    <View display="inline-flex" alignItems="center" width="100%" gap="0">
                        <ProfilePicture height="40px" width="40px" fontSize="16px" textAlign="left" account={data.source_owner} photo />
                        <StyledNotificationContainer>
                            <Text lineHeight="16px" fontSize="14px">
                                <ProfilePicture height="40px" width="40px" fontSize="14px" account={data.source_owner} username/> wants to meet
                            </Text>
                            <Text fontSize="14px" color="#848484">{toDateDiff(data.created_date)}</Text>
                        </StyledNotificationContainer>
                    </View>
                </View>
            </View>
            {<View flexGrow="1" textAlign="right">

                <Meet data={account}/>
            </View>}
        </View>
    )
}

export const LikedNotification = ({data}: any) => {
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const account = useAccountStore((state: any) => state.account)
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    
    const onClickPost = () => {
        // setPost(data.post)
        // if (data.post.type === 'media') {
        //     setModalKey('post')
        // } else {
            
        //     setPdp(data.post.type)
        // }
    }

    const getPostImage = () => {
        if (data.post.posts && data.post.posts.length > 0) {
            return data.post.posts[0].media[0]
        }
        if (data.post.media?.length > 0) {
            return data.post.media[0]
        }
        return {}
    }

    const postImage = getPostImage()

    return (
        <View display="inline-flex" width="100%" alignItems="center">
            <View width="80%">
                <View>
                    <View display="inline-flex" alignItems="center" width="100%" gap="0">
                        <ProfilePicture height="40px" width="40px" fontSize="16px" textAlign="left" account={data.source_owner} photo />
                        <StyledNotificationContainer>
                            <Text lineHeight="16px" fontSize="14px">
                                {account?.id === data.source_owner?.id ? 'You' : <ProfilePicture height="40px" width="40px" fontSize="14px" account={data.source_owner} username/>} liked {account?.id === data.owner?.id ? account?.id === data.owner?.id ? 'your' : <ProfilePicture height="40px" width="40px" fontSize="14px" account={data.owner} username/> : <ProfilePicture height="40px" width="40px" fontSize="14px" account={data.owner} username/>} {data.post.type} Post
                            </Text>
                            <Text fontSize="14px" color="#848484">{toDateDiff(data.created_date)}</Text>
                        </StyledNotificationContainer>
                        
                        
                    </View>
                </View>
            </View>
            <View flexGrow="1" textAlign="right">
                <Button noStyle onClick={onClickPost}>
                    {postImage?.content_type?.includes('video') ? 
                        <Video src={postImage?.url} width="40px" height="40px" borderRadius="4px" objectFit="cover"/> : 
                        <Image src={postImage?.url} width="40px" height="40px" borderRadius="4px" objectFit="cover"/>
                    }
                </Button>
            </View>
        </View>
    )
}

export const AuctionCompleteNotification = ({data}: any) => {
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)

    const onClickPost = () => {
        // setPost(data.post)
        // if (data.post.type === 'media') {
        //     setModalKey('post')
        // } else {
            
        //     setPdp(data.post.type)
        // }
    }
    return (
        <View display="inline-flex" width="100%" alignItems="center">
            <View width="80%">
                <View>
                    <View display="inline-flex" alignItems="center" width="100%" gap="0">
                        <ProfilePicture height="40px" width="40px" fontSize="16px" textAlign="left" account={data.source_owner} photo />
                        <StyledNotificationContainer>
                            <Text lineHeight="16px" fontSize="14px">
                                Your auction <ProfilePicture height="40px" width="40px" fontSize="14px" account={data} username/> has finished
                            </Text>
                            <Text fontSize="14px" color="#848484">{toDateDiff(data.created_date)}</Text>
                        </StyledNotificationContainer>
                    </View>
                </View>
            </View>
            <View flexGrow="1" textAlign="right">
                <Button noStyle onClick={onClickPost}>
                    {data.post?.media.length > 0 && data.post.media[0].content_type.includes('video') ? 
                        <Video src={data.post.media[0].url} width="40px" height="40px" borderRadius="4px" objectFit="cover"/> : 
                        <Image src={data.post.media[0].url} width="40px" height="40px" borderRadius="4px" objectFit="cover"/>
                    }
                </Button>
            </View>
        </View>
    )
}

export const Notification = ({data}: any) => {
    return (
       <StyledNotification>
            <View padding="12px 16px">
                {data.source === 'follow' && <FollowedNotification data={data} />}
                {data.source === 'like' && <LikedNotification data={data} />}
                {data.source === 'meeting' && <MeetingNotification data={data} />}
                {data.source === 'purchase' && <PurchasedNotification data={data} />}
                {data.source === 'auction_ended' && <AuctionCompleteNotification data={data} />}
                {data.source === 'message' && <MessageNotification data={data} />}
                {data.source === 'comment' && <CommentNotification data={data} />}
                {data.source === 'offer' && <OfferNotification data={data} />}
            </View>
       </StyledNotification> 
    )
}

const Notifications = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const [data, setData] = useState<any>([])
    const [apiResp, setApiResp] = useState<any>({})
    
    useEffect(() => {
        const fetch = async () => {
            const resp = await api.get('/recent/notifications')
            setData(resp.data)
            setApiResp(resp)
        }

        fetch()
    }, [])

    if (!apiResp.is_loaded) return <RippleLoader />

    return (
        <StyledNotificationsContainer theme={theme}>
            <StyledNotifications>
                <View padding="24px 24px 24px 20px">
                    <Text fontSize="20px" fontWeight="500" width="fit-content">Notifications</Text>
                </View>
                <View>
                    <View display="inline-flex" flexDirection="column" gap="24px" width="100%">
                        <View>
                            <View padding="0px 24px 12px 20px">
                                {data.length > 0 ? <Text fontSize="18px">{`New`}</Text> :
                                <Text fontSize="18px">No notifications</Text>}
                            </View>
                            <StyledNotificationsWrapper>
                                {data.map((notification: any) => {
                                    return (
                                        <Notification data={notification} />
                                    )
                                })}
                            </StyledNotificationsWrapper>
                        </View>
                    </View>
                </View>
            </StyledNotifications>
        </StyledNotificationsContainer>
    )
}

export default Notifications