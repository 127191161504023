export interface PasswordValidation {
    isValid: boolean;
    criteria: {
        minLength: boolean;
        hasUpperCase: boolean;
        hasLowerCase: boolean;
        hasNumber: boolean;
        hasSymbol: boolean;
    };
}

export const validatePassword = (password: string): PasswordValidation => {
    const criteria = {
        minLength: password.length >= 8,
        hasUpperCase: /[A-Z]/.test(password),
        hasLowerCase: /[a-z]/.test(password),
        hasNumber: /[0-9]/.test(password),
        hasSymbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };

    const isValid = Object.values(criteria).every(Boolean);

    return {
        isValid,
        criteria,
    };
};

export const getCriteriaMessage = (criteriaKey: keyof PasswordValidation['criteria']): string => {
    const messages = {
        minLength: 'At least 8 characters',
        hasUpperCase: 'At least one uppercase letter',
        hasLowerCase: 'At least one lowercase letter',
        hasNumber: 'At least one number',
        hasSymbol: 'At least one special character (!@#$%^&*(),.?":{}|<>)',
    };

    return messages[criteriaKey];
};
