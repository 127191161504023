import { ReactComponent as LogoIcon } from '../../../img/logo.svg';
import { ReactComponent as HomeIconOld } from '../../../img/menuHome.svg';
import { ReactComponent as SearchIcon } from '../../../img/menuSearch.svg';
import { ReactComponent as BookmarkIcon } from '../../../img/menuSaved.svg';
import { ReactComponent as MailIcon } from '../../../img/menuMessage.svg';
import { ReactComponent as BellIcon } from '../../../img/menuBell.svg';
import { ReactComponent as CreateIcon } from '../../../img/menuCreate.svg';
import { ReactComponent as MenuIcon } from '../../../img/menuMenu.svg';
import { ReactComponent as ExploreIcon } from '../../../img/menuExplore.svg';
import { ReactComponent as ArtIcon } from '../../../img/menuArtwork.svg';
import { ReactComponent as ShowsIcon } from '../../../img/menuShow.svg';
import { ReactComponent as AuctionsIcon } from '../../../img/menuAuction.svg';
import { ReactComponent as SettingsIcon } from '../../../img/settings.svg';
import { StyledDashabordMenu, StyledDashabordMenuContainer, StyledDashboardMenuItem, StyledDashboardMenuItemContainer, StyledDashboardMenuSection, StyledExpandMenu, StyledExpandMenuContainer, StyledMenuIcon, StyledMenuItemHover, StyledMenuItemHoverContainer, StyledMenuText } from "./styled"
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { useEffect, useRef, useState } from 'react';
import { useAccountStore, useDashboardCreateModeStore, useDashboardMenuExpandedStore, useDashboardNavigationStore, useModalStore, usePostStore, useProductDetailPageStore, useResponsiveStore, useSlideMenuStore } from '../../../stores/web.store';
import { useLocation, useNavigate } from 'react-router-dom';
import { signout } from '../../../services/auth.service';
import { View } from '../../../components/View';
import { useComponentVisible } from '../../../utils/app.util';
import { StyledProfilePicture } from '../../../components/ProfilePicture/styled';
import { Image } from '../../../components/Image';
import { useThemeStore } from '../../../stores/theme.store';

import {ReactComponent as AuctionIcon} from '../../../icons/auction.svg'
import {ReactComponent as CompassIcon} from '../../../icons/compass.svg'
import {ReactComponent as ShowIcon} from '../../../icons/shows.svg'
import {ReactComponent as GalleryIcon} from '../../../icons/gallery.svg'
import {ReactComponent as HomeIcon} from '../../../icons/home.svg'
import {ReactComponent as InboxIcon} from '../../../icons/inbox.svg'
import {ReactComponent as MonetAIIcon} from '../../../icons/monet-ai.svg'
import {ReactComponent as PlusIcon} from '../../../icons/plus.svg'
import {ReactComponent as LogoFullIcon} from '../../../icons/logoFull.svg'
import {ReactComponent as ChevronUpIcon} from '../../../icons/chevronUp.svg'
import {ReactComponent as ChevronDownIcon} from '../../../icons/chevronDown.svg'
import { UpgradePremium } from '../../../components/Banners/UpgradePremium';
import { ProfilePicture } from '../../../components/ProfilePicture';
import { ReactComponent as ProfileIcon } from '../../../icons/profile.svg'
import { ReactComponent as ListIcon } from '../../../icons/question.svg'
import { ReactComponent as LogoutIcon } from '../../../icons/logout.svg'
import { ReactComponent as GearIcon } from '../../../icons/gear.svg'
import { createGlobalStyle } from 'styled-components'

const MenuItem = ({ setIsHover, isHover, isActive, padding, margin, isExpanded, children}: any) => {
    const theme = useThemeStore((state: any) => state.theme)
    const onMouseEnter = () => {
        setIsHover(true)
    }

    const onMouseLeave = () => {
        setIsHover(false)
    }

    return (
        <StyledDashboardMenuItemContainer 
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave} 
            padding={padding} 
            isExpanded={isExpanded} 
            isHover={isHover} 
            isActive={isActive}
            margin={margin}
            theme={theme}
        >
            <StyledDashboardMenuItem>
                {children}
            </StyledDashboardMenuItem>
        </StyledDashboardMenuItemContainer>
    )
}

const SearchItem = () => {
    const navigate = useNavigate()
    const setSlideMenu = useSlideMenuStore((state: any) => state.setSlideMenu)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsExpanded = useDashboardMenuExpandedStore((state: any) => state.setIsExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(slideMenu.key === 'search' && slideMenu.isActive)
    }, [slideMenu])

    const onClick = () => {
        if (slideMenu.key === 'search' && slideMenu.isActive) {
            resetSlideMenu()
            setIsExpanded(true)
        } else {
            setSlideMenu({...slideMenu, isActive: true, key: 'search'})
            setIsExpanded(false)
        }
    }
    

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive} id="MenuSearchButton" data-search-item="true">
            <div data-search-item="true">
                <Button noStyle onClick={onClick} width='100%' height='42px' data-search-item="true">
                    <StyledMenuIcon isExpanded={isExpanded} data-search-item="true">
                        <SearchIcon 
                            height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            strokeWidth={isActive ? '4' : '2'}
                            stroke={isActive ? '#000' : '#000'}
                            fill={isActive ? '#fff' : '#fff'}
                            data-search-item="true"
                        />
                        {isExpanded && <StyledMenuText isActive={isActive} data-search-item="true">Search</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const ExploreItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/explore' && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {
        navigate('/explore')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px' padding='0px 10px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <CompassIcon stroke={isActive ? 'rgba(10, 10, 10, 1)' : 'rgba(115, 115, 115, 1)'}/>
                    
                        {isExpanded && <StyledMenuText isActive={isActive}>Explore</StyledMenuText>}
                    </StyledMenuIcon>
                </Button>
            </div>
        </MenuItem>
    )
}

const ArtItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/artworks' && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {
        navigate('/artworks')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px' padding='0px 10px'>
                    <StyledMenuIcon isExpanded={isExpanded}>  
                        <GalleryIcon stroke={isActive ? 'rgba(10, 10, 10, 1)' : 'rgba(115, 115, 115, 1)'}/>
                        {isExpanded && <StyledMenuText isActive={isActive}>Artworks</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const ShowsItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/shows' && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {
        navigate('/shows')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px' padding='0px 10px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <ShowIcon stroke={isActive ? 'rgba(10, 10, 10, 1)' : 'rgba(115, 115, 115, 1)'}/>
                        {isExpanded && <StyledMenuText isActive={isActive}>Shows</StyledMenuText>}
                        {isExpanded && <View color="rgba(10, 10, 10, 1)" background='rgba(245, 245, 245, 1)' padding='4px 12px' borderRadius='12px'>Beta</View>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const MonetAItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/monet-ai' && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {
        navigate('/monet-ai')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px' padding='0px 10px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <MonetAIIcon stroke={isActive ? 'rgba(10, 10, 10, 1)' : 'rgba(115, 115, 115, 1)'}/>
                        {isExpanded && <StyledMenuText isActive={isActive}>Monet AI</StyledMenuText>}
                        {isExpanded && <View color="rgba(10, 10, 10, 1)" background='rgba(245, 245, 245, 1)' padding='4px 12px' borderRadius='12px'>Beta</View>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}


const AuctionsItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/auctions' && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {
        navigate('/auctions')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px' padding='0px 10px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <AuctionIcon stroke={isActive ? 'rgba(10, 10, 10, 1)' : 'rgba(115, 115, 115, 1)'}/>
                        {isExpanded && <StyledMenuText isActive={isActive}>Auctions</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const SavedItem = () => {
    const location = useLocation()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/saved' && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {

    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <BookmarkIcon 
                            height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            stroke-width={isHover ? '2' : '1.5'}
                        />
                        {isExpanded && <StyledMenuText isActive={isActive}>Saved</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const MessagesItem = () => {
    const setSlideMenu = useSlideMenuStore((state: any) => state.setSlideMenu)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsExpanded = useDashboardMenuExpandedStore((state: any) => state.setIsExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(slideMenu.key === 'inbox')
    }, [slideMenu])

    const onClick = () => {
        if (slideMenu.key === 'inbox' && slideMenu.isActive) {
            resetSlideMenu()
            setIsExpanded(true)
        } else {
            setSlideMenu({...slideMenu, isActive: true, key: 'inbox'})
            setIsExpanded(false)
        }
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive} data-message-item="true">
            <div data-message-item="true">
                <Button noStyle onClick={onClick} width='100%' height='42px' data-message-item="true">
                    <StyledMenuIcon isExpanded={isExpanded} data-message-item="true">
                        <MailIcon 
                            height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            stroke-width={'2'}
                            stroke={isActive ? '#fff' : '#000'}
                            fill={isActive ? '#000' : '#fff'}
                            data-message-item="true"
                        />
                        {isExpanded && <StyledMenuText isActive={isActive} data-message-item="true">Messages</StyledMenuText>}
                    </StyledMenuIcon>
                </Button>
            </div>
        </MenuItem>
    )
}

const NotificationsItem = () => {
    const setSlideMenu = useSlideMenuStore((state: any) => state.setSlideMenu)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsExpanded = useDashboardMenuExpandedStore((state: any) => state.setIsExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(slideMenu.key === 'notifications')
    }, [slideMenu])
    

    const onClick = () => {
        
        if (slideMenu.key === 'notifications' && slideMenu.isActive) {
            resetSlideMenu()
            // setIsExpanded(true)
        } else {
            setSlideMenu({...slideMenu, isActive: true, key: 'notifications'})
            // setIsExpanded(false)
        }
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive} data-notifications-item="true">
            <div data-notifications-item="true">
                <Button noStyle onClick={onClick} width='100%' height='42px' data-notifications-item="true" padding='0px 10px'>
                    <StyledMenuIcon isExpanded={isExpanded} data-notifications-item="true">
                        <View position='relative'>
                            <InboxIcon 
                                data-notifications-item="true"
                                stroke={isActive ? 'rgba(10, 10, 10, 1)' : 'rgba(115, 115, 115, 1)'}
                            />
                            <View 
                                position='absolute' 
                                right={isExpanded ? '0px' : '10px'}
                                top='0px' 
                                height='8px' 
                                width='8px' 
                                borderRadius='50%' 
                                background='#FFDC39'
                                border='solid 1px #fff'
                            />
                        </View>
                        {isExpanded && <StyledMenuText isActive={isActive} data-notifications-item="true">Notifications</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const CreateItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const isCreateMode = useDashboardCreateModeStore((state: any) => state.isCreateMode)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/profile' && !slideMenu.isActive && isCreateMode)
    }, [location, slideMenu, isCreateMode])

    const onClick = () => {
        // setIsCreateMode(true)
        // navigate('/profile')
        setModalKey('create')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px' padding='0px 10px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <PlusIcon stroke={isActive ? 'rgba(10, 10, 10, 1)' : 'rgba(115, 115, 115, 1)'}/>
                        {isExpanded && <StyledMenuText isActive={isActive}>Create</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const ProfilePopout = ({ isOpen, onClose, account }: any) => {
    const navigate = useNavigate()
    const theme = useThemeStore((state: any) => state.theme)
    
    const handleProfileClick = () => {
        navigate('/profile')
        onClose()
    }
    
    const handleSettingsClick = () => {
        navigate('/settings')
        onClose()
    }
    
    const handleListClick = () => {
        navigate('/lists')
        onClose()
    }
    
    const handleLogoutClick = async () => {
        await signout()
        onClose()
    }
    
    if (!isOpen) return null
    
    return (
        <View
            position="fixed"
            bottom="72px"
            left="12px"
            width="200px"
            background="white"
            borderRadius="12px"
            boxShadow="0 4px 12px rgba(0,0,0,0.1)"
            zIndex="1000"
            overflow="hidden"
            style={{
                animation: "fadeIn 0.2s ease-in-out",
            }}
        >
            <View padding="8px 0">
                <Button 
                    noStyle 
                    width="100%" 
                    padding="8px 16px"
                    alignItems="center"
                    gap="12px"
                    flexDirection='start'
                    onClick={handleProfileClick}
                >
                    <View>
                        <ProfileIcon />
                    </View>
                    <View flexGrow='1' textAlign='left'>
                        <Text fontSize="15px">Profile</Text>
                    </View>
                </Button>
                
                <Button 
                    noStyle 
                    width="100%" 
                    padding="8px 16px"
                    display="flex"
                    alignItems="center"
                    gap="12px"
                    onClick={handleSettingsClick}
                >
                    <View>
                        <GearIcon />
                    </View>
                    <View flexGrow='1' textAlign='left'>
                        <Text fontSize="15px">Settings</Text>
                    </View>
                </Button>
                
                <Button 
                    noStyle 
                    width="100%" 
                    padding="8px 16px"
                    display="flex"
                    alignItems="center"
                    gap="12px"
                    onClick={handleListClick}
                >
                    <View>
                        <ListIcon />
                    </View>
                    <View flexGrow='1' textAlign='left'>
                        <Text fontSize="15px">List</Text>
                    </View>
                </Button>
            </View>
            
            <View 
                padding="4px 0" 
                borderTop="1px solid #eee"
            >
                <Button 
                    noStyle 
                    width="100%" 
                    padding="8px 16px"
                    display="flex"
                    alignItems="center"
                    gap="12px"
                    onClick={handleLogoutClick}
                >
                    <View>
                       <LogoutIcon />
                    </View>
                    <View flexGrow='1' textAlign='left'>
                        <Text fontSize="15px">Log out</Text>
                    </View>
                </Button>
            </View>
        </View>
    )
}

const ProfileItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const account = useAccountStore((state: any) => state.account)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const isCreateMode = useDashboardCreateModeStore((state: any) => state.isCreateMode)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)
    const [isPopoutOpen, setIsPopoutOpen] = useState(false)
    const [isActive, setIsActive] = useState(false)
    
    const popoutRef = useRef<HTMLDivElement>(null)
    
    useEffect(() => {
        setIsActive(location.pathname === '/profile' && !slideMenu.isActive && !isCreateMode)
    }, [location, slideMenu, isCreateMode])
    
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popoutRef.current && !popoutRef.current.contains(event.target as Node)) {
                setIsPopoutOpen(false)
            }
        }
        
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])
    
    const togglePopout = () => {
        setIsPopoutOpen(!isPopoutOpen)
    }
    
    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <View position="relative" ref={popoutRef}>
                <Button 
                    noStyle 
                    onClick={togglePopout} 
                    width='100%' 
                    height='42px'
                    background={isPopoutOpen ? '#f5f5f5' : 'transparent'}
                    borderRadius="8px"
                    border={isPopoutOpen ? '1px solid #e0e0e0' : 'none'}
                >
                    <View display="inline-flex" alignItems='center' width="100%">
                        <StyledMenuIcon isExpanded={isExpanded}>
                            {account.profile_picture ?
                                <View>
                                    <View padding='8px 0px 12px 6px'>
                                        <ProfilePicture account={account} photo username noHover/>
                                    </View>
                                </View> :
                                <ProfileIcon 
                                    height={width > 768 ? (isHover ? 40 :  isActive ? 40 : 36) : (isHover ? 34 :  isActive ? 34 : 28)} 
                                    width={width > 768 ? (isHover ? 40 : isActive ? 40 : 36) : (isHover ? 34 : isActive ? 34 : 28)} 
                                    strokeWidth={2}
                                    stroke={isActive ? '#fff' : '#000'}
                                    fill={isActive ? '#000' : '#fff'}
                                />
                            }
                        </StyledMenuIcon>
                        <View padding='0px 4px 0px 0px'>
                            <View display='flex' flexDirection='column' gap='0' justifyContent='center'>
                                <ChevronUpIcon height={14} />
                                <ChevronDownIcon height={14} />
                            </View>
                        </View>
                    </View>
                </Button>
                
                <ProfilePopout 
                    isOpen={isPopoutOpen} 
                    onClose={() => setIsPopoutOpen(false)} 
                    account={account} 
                />
            </View>
        </MenuItem>
    )
}

const LogoItem = () => {
    const navigate = useNavigate()
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const [isHover, setIsHover] = useState(false)

    const onClick = () => {
        navigate('/')
    }

    return (
        <MenuItem setIsHover={setIsHover} isExpanded={isExpanded}>
            <View padding='6px'>
                <Button noStyle onClick={onClick} width='100%' height='42px' gap="0px">
                    <StyledMenuIcon isExpanded={isExpanded}>
                        {isExpanded ? <LogoFullIcon /> : <Image src={`/img/pyLogo.jpeg`} height={'32px'} width={'32px'} />}
                        {isExpanded && <View color="rgba(10, 10, 10, 1)" background='rgba(245, 245, 245, 1)' padding='4px 12px' borderRadius='12px'>Beta</View>}
                    </StyledMenuIcon>
                    
                </Button>
            </View>
        </MenuItem>
    )
}

const HomeItem = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive((location.pathname === '/' || location.pathname === '') && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {
        navigate('/')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px' padding='0px 10px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <HomeIcon stroke={isActive ? 'rgba(10, 10, 10, 1)' : 'rgba(115, 115, 115, 1)'}/>
                        {isExpanded && <StyledMenuText isActive={isActive}>Home</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const SignoutItem = () => {
    const navigate = useNavigate()
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const onClick = () => {
        setIsCreateMode(false)
        navigate('/profile')
    }

    const onClickLogout = async () => {
        await signout()
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} margin="0">
            <Button noStyle onClick={onClickLogout} width='100%' height='42px' padding='0px 20px 0px 4px'>
                <StyledMenuIcon isExpanded>
                    <LogoutIcon 
                        height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        stroke-width={isHover ? '2' : '1.5'}
                    />
                    <StyledMenuText>Signout</StyledMenuText>
                </StyledMenuIcon>
                
            </Button>
        </MenuItem>
    )
}

const SettingsItem = () => {
    const navigate = useNavigate()
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const onClick = () => {
        setIsCreateMode(false)
        navigate('/settings')
    }


    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} margin="0">
            <Button noStyle onClick={onClick} width='100%' height='42px' padding='0px 20px 0px 4px'>
                <StyledMenuIcon isExpanded>
                    <SettingsIcon 
                        height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        stroke-width={isHover ? '2' : '1.5'}
                    />
                    <StyledMenuText>Settings</StyledMenuText>
                </StyledMenuIcon>
                
            </Button>
        </MenuItem>
    )
}

const useOutsideAlerter = (ref: any, setIsExpandedMenu: any) => {

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsExpandedMenu(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const ExpandMenu = ({setIsExpandedMenu, isExpandedMenu}: any) => {
    const theme = useThemeStore((state: any) => state.theme)
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, setIsExpandedMenu);
    
    return (
        <StyledExpandMenuContainer isActive={isExpandedMenu} ref={wrapperRef}>
            <StyledExpandMenu theme={theme}>
                <View background={theme.BackgroundColorPrimary} display='flex' flexDirection='column'>
                    <SettingsItem />
                </View>
                <View background={theme.BackgroundColorPrimary} display='flex' flexDirection='column'>
                    <SignoutItem />
                </View>
            </StyledExpandMenu>
        </StyledExpandMenuContainer>
    )
}

const ExpandItem = ({setIsExpandedMenu, isExpandedMenu}: any) => {
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsExpanded = useDashboardMenuExpandedStore((state: any) => state.setIsExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const onClick = () => {
        setIsExpandedMenu(!isExpandedMenu)
        // setIsExpanded(!isExpanded)
    }

    return (
        <MenuItem setIsHover={setIsHover} isExpanded={isExpanded} isHover={isHover}>
            <Button noStyle onClick={onClick} width='100%' height='42px'>
                <StyledMenuIcon isExpanded={isExpanded}>
                    <MenuIcon 
                        height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        stroke-width={isHover ? 2 : 1.5}
                    />
                    {isExpanded && <StyledMenuText>Menu</StyledMenuText>}
                </StyledMenuIcon>
                
            </Button>
        </MenuItem>
    )
}

const GlobalStyle = createGlobalStyle`
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const DashboardMenu = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsExpanded = useDashboardMenuExpandedStore((state: any) => state.setIsExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isExpandedMenu, setIsExpandedMenu] = useState(false)
    
    const onMouseEnter = () => {
        setIsExpanded(true)
    }

    const onMouseLeave = () => {
        setIsExpanded(true)
    }

    return (
        <StyledDashabordMenuContainer 
            isExpanded={isExpanded} 
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave}
            theme={theme}
        >
            <StyledDashabordMenu>
                {width > 768 && <StyledDashboardMenuSection padding='0px 0px 8px 0px'>
                    <LogoItem />
                </StyledDashboardMenuSection>}
                <StyledDashboardMenuSection 
                    height={width > 768  ? 'calc(100vh - 126px)' : 'fit-content'} 
                    overflowY={width > 768 ? "scroll": "hidden"} 
                    isExpanded={isExpanded} 
                    top={width > 768 ? '12px' : '0'}
                >
                    {/* {width > 768 && <NotificationsItem />} */}
                    {/* {width > 768  && <SearchItem />} */}
                    <HomeItem />
                    <NotificationsItem />
                    <ExploreItem />
                    <MonetAItem />
                    <ArtItem />
                    <ShowsItem />
                    <AuctionsItem />
                    <CreateItem />
                    {/* {width > 768 && <ArtItem />}
                    {width > 768 && <ShowsItem />}
                    {width > 768 && <AuctionsItem />} */}
                    {/* <SavedItem /> */}
                    {/* {width > 768 ? <MessagesItem /> : <CreateItem /> } */}
                    
                    {/* {width > 768 ? <CreateItem /> : <MessagesItem />} */}
                    
                </StyledDashboardMenuSection>
                <StyledDashboardMenuSection position='absolute' bottom="20px">
                    {isExpanded && <UpgradePremium />}
                    <ProfileItem />
                </StyledDashboardMenuSection>
            </StyledDashabordMenu>
        </StyledDashabordMenuContainer>
    )
}

export default DashboardMenu