import { ReactNode } from "react"
import { TopNav } from "../TopNav"
import { StyledLayout } from "./styled"




type LayoutProps = {
    includeNavigation?: boolean;
    children: ReactNode;
}

const Layout = ({includeNavigation, children}: LayoutProps) => {
    return (
        <StyledLayout>
            <TopNav includeNavigation={includeNavigation}/>
            {children}
        </StyledLayout>
    )
}

export default Layout