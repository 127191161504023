import styled from "styled-components";

interface AccountActionsContainerProps {

}

export const StyledAccountActionsContainer = styled.div<AccountActionsContainerProps>`
    width: 250px;
    background: #fff;
    border-radius: 12px;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-top: 8px;
`

interface AccountActionsProps {
    
}

export const StyledAccountActions = styled.div<AccountActionsProps>`
    display: flex;
    flex-direction: column;
    padding: 8px 0;
`

export const StyledAccountActionItem = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    text-align: left;
    gap: 8px;
    width: 100%;
    
    &:hover {
        background-color: #f9f9f9;
    }
    
`

export const StyledAccountActionText = styled.span`
    font-size: 14px;
    font-weight: 400;
`

export const StyledAccountActionDanger = styled(StyledAccountActionText)`
    color: #FF3B30;
`

export const StyledSettingsIconContainer = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    
    svg {
        width: 20px;
        height: 20px;
    }
`