import { useRef, useState } from "react";
import { $getRoot } from "lexical"
import { MediaUpload } from "../../../components/MediaUpload"
import { CreateMediaPostContent } from "./CreateMediaPostContent"
import { StyledCreateMediaPost, StyledCreateMediaPostContainer, StyledPrivacyOptions, StyledPrivacyOption } from "./styled"
import { usePostPayloadStore } from "../../../stores/web.store";
import { LoadingModal } from "../../../components/Modal/LoadingModal";
import { Text } from "../../../components/Text";
import { Button } from "../../../components/Button";
import {ReactComponent as LoaderIcon} from '../../../img/loader.svg'
import { View } from "../../../components/View";
import { useThemeStore } from "../../../stores/theme.store";
import { Navigator } from "../Navigtor";
import { Switch } from "../../../components/Switch";
import { Markdown } from "../../../components/MarkDown";
import { Preview } from "../../../components/Media/Preview";

const MainDetails = () => {
    const editorStateRef = useRef();
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)


    const onChangeEditorState = (editorState: any) => {
        editorStateRef.current = editorState
        setPostPayload({...postPayload, 
            caption: editorState.read(() => $getRoot().getTextContent()),
            lexical_caption: JSON.stringify(editorStateRef.current)
        })

    }
    
    return (
        <View padding="24px 0px">
            <Text fontSize="24px" fontWeight="bold" margin="0px 0px 20px 0px">Main Details</Text>
            
            <View margin="0px 0px 15px 0px">
                <Text fontSize="16px" margin="0px 0px 10px 0px">Description</Text>
                <Markdown editorStateRef={editorStateRef} data={null} onChange={onChangeEditorState} emoji={{padding: '0px 8px'}}/>
            </View>
        </View>
    );
};

const CreateMediaStepOne = () => {
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)

    return (
        <View padding="24px">
            <View>
                <Text fontSize="14px" fontWeight="500" margin="0px 0px 16px 4px">Upload File</Text>
                <MediaUpload setPayload={setPostPayload} payload={postPayload}/>
            </View>
            <MainDetails />
        </View>
    )
}

const CreateMediaStepTwo = () => {
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)
    
    const handlePrivacyChange = (privacy: string) => {
        setPostPayload({
            ...postPayload,
            privacy: privacy
        });
    };
    
    const handleToggleChange = (setting: string, value: boolean) => {
        setPostPayload({
            ...postPayload,
            [setting]: value
        });
    };
    
    return (
        <View padding="20px">
            <Text fontSize="24px" fontWeight="bold" margin="0px 0px 20px 0px">Privacy Settings</Text>
            
            <StyledPrivacyOptions>
                {/* Public Option */}
                <StyledPrivacyOption>
                    <Button 
                        noStyle
                        width="100%"
                        onClick={() => handlePrivacyChange('public')}
                    >
                        <View display="flex" alignItems="center" flexDirection="column" width="100%" gap="8px">
                            <View display="inline-flex" width="100%" justifyContent="center" alignItems="center">
                                <View width="32px" textAlign="left">
                                    <input 
                                        type="radio" 
                                        id="public" 
                                        name="privacy" 
                                        checked={postPayload?.privacy === 'public'} 
                                        onChange={() => {}} // Handled by Button onClick
                                        style={{ 
                                            width: "24px", 
                                            height: "24px",
                                            accentColor: "#0000FF",
                                            margin: "0px"
                                        }}
                                    />
                                </View>
                                <View flexGrow="1">
                                    <Text fontSize="20px" fontWeight="600">Public</Text>
                                </View>
                            </View>
                            <View>
                                <Text fontSize="14px" color="#666" textAlign="left">
                                    Accessible to everyone, including non-followers.
                                </Text>
                            </View>
                        </View>
                    </Button>
                </StyledPrivacyOption>
                
                {/* Close Friends Option */}
                <StyledPrivacyOption>
                    <Button 
                        noStyle
                        width="100%"
                        onClick={() => handlePrivacyChange('close_friends')}
                    >
                        <View display="flex" alignItems="center" flexDirection="column" width="100%" gap="8px">
                            <View width="100%" display="inline-flex" justifyContent="center" alignItems="center">
                                <View width="32px" textAlign="left">
                                    <input 
                                        type="radio" 
                                        id="close_friends" 
                                        name="privacy" 
                                        checked={postPayload?.privacy === 'close_friends'} 
                                        onChange={() => {}} // Handled by Button onClick
                                    style={{ 
                                        width: "24px", 
                                        height: "24px",
                                        margin: "0px"
                                    }}
                                />
                                </View>
                                <View flexGrow="1">
                                    <Text fontSize="20px" fontWeight="600" textAlign="left">Close Friends</Text>
                                </View>
                            </View>
                            <View>
                                <Text fontSize="14px" color="#666" textAlign="left">
                                    Only your selected close friends can view this photo.
                                </Text>
                            </View>
                        </View>
                    </Button>
                </StyledPrivacyOption>
                
                {/* Subscribers Option */}
                <StyledPrivacyOption>
                    <Button 
                        noStyle
                        width="100%"
                        onClick={() => handlePrivacyChange('subscribers')}
                    >
                        <View display="flex" alignItems="center" flexDirection="column" width="100%" gap="8px">
                            <View width="100%" display="inline-flex" justifyContent="center" alignItems="center">
                                <View width="32px" textAlign="left">
                                    <input 
                                        type="radio" 
                                        id="subscribers" 
                                        name="privacy" 
                                        checked={postPayload?.privacy === 'subscribers'} 
                                        onChange={() => {}} // Handled by Button onClick
                                        style={{ 
                                            width: "24px", 
                                            height: "24px",
                                            margin: "0px"
                                        }}
                                    />
                                </View>
                                <View flexGrow="1">
                                    <Text textAlign="left" fontSize="20px" fontWeight="600">Subscribers</Text>
                                </View>
                            </View>
                            <View>
                                
                                <Text fontSize="14px" color="#666" textAlign="left">
                                    Visible only to your subscribers.
                                </Text>
                            </View>
                        </View>
                    </Button>
                </StyledPrivacyOption>
            </StyledPrivacyOptions>
            
            {/* Toggle Options */}
            <View margin="30px 0px">
                <View display="flex" justifyContent="space-between" alignItems="center" margin="0px 0px 15px 0px">
                    <Text fontSize="16px">Hide like and view counts on this post</Text>
                    <Switch 
                        isChecked={postPayload?.hideCounts || false}
                        onChange={(checked: boolean) => handleToggleChange('hideCounts', checked)}
                    />
                </View>
                
                <View display="flex" justifyContent="space-between" alignItems="center" margin="0px 0px 15px 0px">
                    <Text fontSize="16px">Disable commenting</Text>
                    <Switch 
                        isChecked={postPayload?.disableComments || false}
                        onChange={(checked: boolean) => handleToggleChange('disableComments', checked)}
                    />
                </View>
                
                <View display="flex" justifyContent="space-between" alignItems="center" margin="0px 0px 15px 0px">
                    <Text fontSize="16px">Schedule post</Text>
                    <Switch 
                        isChecked={postPayload?.schedulePost || false}
                        onChange={(checked: boolean) => handleToggleChange('schedulePost', checked)}
                    />
                </View>
            </View>
        </View>
    );
};

const CreateMediaPost = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const [isSubmittingPost, setIsSubmittingPost] = useState(false)
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const [activeStep, setActiveStep] = useState(1);

    const handleStepClick = (step: number) => {
        if (step > activeStep && !canProceedToNextStep()) {
            return;
        }
        setActiveStep(step);
    };

    const canProceedToNextStep = () => {
        if (activeStep === 1) {
            return postPayload && postPayload.media && postPayload.media.length > 0;
        }
        return true;
    };

    const handleNextStep = () => {
        if (activeStep < 2 && canProceedToNextStep()) {
            setActiveStep(activeStep + 1);
        }
    };

    const handlePrevStep = () => {
        if (activeStep > 1) {
            setActiveStep(activeStep - 1);
        }
    };

    const handleSubmit = async () => {
        if (canProceedToNextStep()) {
            setIsSubmittingPost(true);
        }
    };

    const renderStepContent = () => {
        switch (activeStep) {
            case 1:
                return (
                    <CreateMediaStepOne />
                );
            case 2:
                return (
                    <CreateMediaStepTwo />
                );
            default:
                return null;
        }
    };

    return (
        <StyledCreateMediaPostContainer>
            <StyledCreateMediaPost>
                <View 
                    width="60%" 
                    background={theme.CardPrimaryBackgroundColor} 
                    borderRadius="20px"
                    boxShadow="0px 0px 8px 0px rgba(213, 213, 205, 0.25)"
                >
                    <View padding="24px 0px 0px 0px">
                        <Navigator 
                            steps={2} 
                            activeStep={activeStep} 
                            onStepClick={handleStepClick} 
                        />
                    </View>
                    <View>
                        {renderStepContent()}
                    </View>
                </View>
                <View width="40%" background={theme.CardPrimaryBackgroundColor} borderRadius="20px" boxShadow="0px 0px 8px 0px rgba(213, 213, 205, 0.25)">
                    <View>
                        <View display="flex" flexDirection="column" gap="20px"> 
                            <View padding="24px 24px 0px 24px">
                                <Text fontWeight="500" fontSize="14px">Preview Post</Text>
                            </View>
                            {postPayload && (postPayload.media && postPayload.media.length > 0 || postPayload.caption) && <View margin="12px 0px">
                                <Preview />
                            </View>}
                        </View>
                    </View>
                </View>
            </StyledCreateMediaPost>
            {isSubmittingPost && <LoadingModal setModal={setIsSubmittingPost}>
                <Text fontSize="60px">Uploading Post</Text>
                <Text textAlign="center" width="100%" padding="32px 0px 60px 0px"><LoaderIcon /></Text>
                <Text textAlign="center" width="100%"><Button noStyle fontSize="32px" onClick={() => setIsSubmittingPost(false)}>Cancel</Button></Text>
            </LoadingModal>}
        </StyledCreateMediaPostContainer>
    )
}

export default CreateMediaPost