import styled from 'styled-components';
import { View } from '../View';

interface StyledNavigatorButtonProps {
  position: 'top' | 'bottom';
  height?: string;
}

export const StyledNavigator = styled(View)`
  position: fixed;
  right: 48px;
  top: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10;

  ${props => props.height && `height: ${props.height};`}
`;

export const StyledNavigatorButton = styled(View)<StyledNavigatorButtonProps>`
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;
