import { ReactNode, useState } from "react";
import { StyledText } from "./styled"
import { useThemeStore } from "../../stores/web.store";
import { Button } from "../Button";
import { View } from "../View";

type TextProps = {
    as?: string;
    children?: ReactNode;
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    textAlign?: string;
    lineHeight?: string;
    justifyContent?: string;
    textDecoration?: string;
    textTransform?: string;
    padding?: string;
    width?: string;
    margin?: string;
    flexGrow?: string;
    textOverflow?: string;
    fontStyle?: string;
    isReadMore?: boolean;
    aiify?: boolean;
    ref?: any;
    aiText?: boolean;
    aiButtonSubtle?: boolean;
    style?: any;
    variant?: string;
}


const Text = (props: TextProps) => {
    const theme = useThemeStore((state: any) => state.theme)
    const [isExpanded, setIsExpanded] = useState(false)
    return (
        <View position="relative" width={props.width} ref={props.ref}>
            <StyledText 
                as={props.as || 'div'}
                theme={theme}
                color={props.color}
                fontSize={props.fontSize}
                fontWeight={props.fontWeight}
                padding={props.padding}
                textAlign={props.textAlign}
                textTransform={props.textTransform}
                justifyContent={props.justifyContent}
                lineHeight={props.lineHeight}
                width={'100%'}
                margin={props.margin}
                textDecoration={props.textDecoration}
                flexGrow={props.flexGrow}
                textOverflow={props.textOverflow}
                fontStyle={props.fontStyle}
                isReadMore={props.isReadMore}
                isExpanded={isExpanded}
                aiify={props.aiify}
                aiText={props.aiText}
                aiButtonSubtle={props.aiButtonSubtle}
                style={props.style}
                variant={props.variant}
            >
                {props.children} 
                
            </StyledText>
            {props.isReadMore && !isExpanded && <View><Button noStyle onClick={() => setIsExpanded(!isExpanded)} color="grey">{'more'}</Button></View>}
        </View>
    )
}

export default Text