import styled from 'styled-components'

interface AuctionsContainerProps {

}

export const StyledAuctionsContainer = styled.div<AuctionsContainerProps>`

`

interface AuctionsProps {

}


export const StyledAuctions = styled.div<AuctionsProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 32px;
    width: 100%;
`