import { View } from "../../View"
import { Text } from "../../Text"
import { Button } from "../../Button"
import { ReactComponent as CloseIcon } from "../../../icons/close.svg"
import { StyledCartHeader } from "./styled"
import { useSlideMenuStore } from "../../../stores/web.store"


interface CartHeaderProps {
  title: string
}

const CartHeader = ({ title }: CartHeaderProps) => {
  const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)

  return (
    <StyledCartHeader>
      <Text fontSize="16px" fontWeight="600">{title}</Text>
      <Button noStyle onClick={() => resetSlideMenu()}>
        <CloseIcon />
      </Button>
    </StyledCartHeader>
  )
}

export default CartHeader 