import { Text } from "../Text"
import { StyledComment, StyledCommentContainer, StyledDateLikesContainer, StyledPhotoNameContainer } from "./styled"
import { UserInfo } from "../UserInfo";
import { toDateDiff } from "../../utils/app.util";
import { LikeButton } from "../Button/Like";
import { ProfilePicture } from "../ProfilePicture";
import { useRef } from "react";
import { ReadOnlyMarkdown } from "../MarkDown/ReadOnlyMarkdown";
import { View } from "../View";

const Comment = ({post, data}: any) => {
    const editorStateRef = useRef();
    if (!data) return <div />
    return (
        <StyledCommentContainer>
            <StyledComment>
                <StyledPhotoNameContainer>
                    <ProfilePicture account={data.owner} username photo height="40px" width="40px" fontSize="14px"/>
                </StyledPhotoNameContainer>
                <View padding="0px 0px 0px 52px" margin="-12px 0px 0px 0px">
                    <Text padding="4px 0px" fontWeight="400" fontSize="14px">{data.comment}</Text>
                    <StyledDateLikesContainer>
                        <Text width="fit-content" color="#737373" fontSize="14px">{toDateDiff(data.created_date)}</Text>
                    </StyledDateLikesContainer>
                </View>
            </StyledComment>
        </StyledCommentContainer>
    )
}

export default Comment