//https://nucleoapp.com/social-media-icons
import { Text } from "../../Text"
import { StyledShare, StyledShareContainer } from "./styled"
import {ReactComponent as InstagramIcon} from '../../../icons/instagram.svg'
import {ReactComponent as WhatsAppIcon} from '../../../icons/whatsApp.svg'
import {ReactComponent as FacebookIcon} from '../../../icons/facebook.svg'
import {ReactComponent as MessengerIcon} from '../../../icons/messenger.svg'
import {ReactComponent as LinkIcon} from '../../../icons/link.svg'
import {ReactComponent as EmailIcon} from '../../../icons/email.svg'
import {ReactComponent as CheckIcon} from '../../../icons/check.svg'
import { View } from "../../View"
import { Button } from "../../Button"
import { useEffect, useRef, useState } from "react"

// Notification component for successful share
const ShareNotification = ({ message, visible, onClose }: { message: string, visible: boolean, onClose: () => void }) => {
    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                onClose();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [visible, onClose]);

    if (!visible) return null;

    return (
        <View 
            position="fixed" 
            top="20px" 
            right="20px" 
            zIndex="1000"
            background="white" 
            borderRadius="8px" 
            padding="12px 16px" 
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.1)"
            display="flex"
            alignItems="flex-start"
            gap="8px"
            width="auto"
        >
            <View margin="3px 0px 0px 0px">
                <CheckIcon fill="#4CAF50" width={16} height={16} />
            </View>
            <View width="100%">
                <Text fontWeight="500" fontSize="14px" color="#4CAF50" textAlign="left">{message}</Text>
                <Text fontSize="12px" color="#666" textAlign="left">Share it with your friends and audience.</Text>
            </View>
        </View>
    );
};

const ShareLink = ({post, showNotification}: any) => {
    const postUrl = window.location.protocol + '//' + window.location.host + '/posts/' + post.id

    const onClick = () => {
        navigator.clipboard.writeText(postUrl)
        showNotification();
    }

    return (
        <View display="flex" flexDirection="column" alignItems="center" gap="8px">
            <Button 
                noStyle 
                onClick={onClick} 
                background="#f5f5f5" 
                borderRadius="50%" 
                width="48px" 
                height="48px" 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
            >
                <LinkIcon />
            </Button>
            <Text fontSize="14px" fontWeight="500">Copy link</Text>
        </View>
    )
}

const ShareInstagram = ({post, showNotification}: any) => {
    const postUrl = window.location.protocol + '//' + window.location.host + '/posts/' + post.id

    const onClick = () => {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                url: postUrl
            }).catch(console.error);
        } else {
            alert('Sharing to Instagram is typically done via mobile apps.');
        }
        showNotification();
    };

    return (
        <View display="flex" flexDirection="column" alignItems="center" gap="8px">
            <Button 
                noStyle 
                onClick={onClick} 
                background="#f5f5f5" 
                borderRadius="50%" 
                width="48px" 
                height="48px" 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
            >
                <InstagramIcon />
            </Button>
            <Text fontSize="14px" fontWeight="500">Instagram</Text>
        </View>
    )
}

const ShareWhatsApp = ({post, showNotification}: any) => {
    const postUrl = window.location.protocol + '//' + window.location.host + '/posts/' + post.id

    const onClick = () => {
        const url = encodeURIComponent(postUrl);
        const whatsappShareUrl = `https://api.whatsapp.com/send?text=${url}`;
        window.open(whatsappShareUrl, '_blank', 'noopener,noreferrer');
        showNotification();
    }

    return (
        <View display="flex" flexDirection="column" alignItems="center" gap="8px">
            <Button 
                noStyle 
                onClick={onClick} 
                background="#f5f5f5" 
                borderRadius="50%" 
                width="48px" 
                height="48px" 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
            >
                <WhatsAppIcon />
            </Button>
            <Text fontSize="14px" fontWeight="500">WhatsApp</Text>
        </View>
    )
}

const ShareFacebook = ({post, showNotification}: any) => {
    const postUrl = window.location.protocol + '//' + window.location.host + '/posts/' + post.id

    const onClick = () => {
        const url = encodeURIComponent(postUrl);
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        window.open(facebookShareUrl, '_blank', 'noopener,noreferrer');
        showNotification();
    }

    return (
        <View display="flex" flexDirection="column" alignItems="center" gap="8px">
            <Button 
                noStyle 
                onClick={onClick} 
                background="#f5f5f5" 
                borderRadius="50%" 
                width="48px" 
                height="48px" 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
            >
                <FacebookIcon />
            </Button>
            <Text fontSize="14px" fontWeight="500">Facebook</Text>
        </View>
    )
}

const ShareMessenger = ({post, showNotification}: any) => {
    const postUrl = window.location.protocol + '//' + window.location.host + '/posts/' + post.id

    const onClick = () => {
        const url = encodeURIComponent(postUrl);
        const messengerShareUrl = `fb-messenger://share?link=${url}`;
        window.open(messengerShareUrl, '_blank', 'noopener,noreferrer');
        showNotification();
    }

    return (
        <View display="flex" flexDirection="column" alignItems="center" gap="8px">
            <Button 
                noStyle 
                onClick={onClick} 
                background="#f5f5f5" 
                borderRadius="50%" 
                width="48px" 
                height="48px" 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
            >
                <MessengerIcon />
            </Button>
            <Text fontSize="14px" fontWeight="500">Messenger</Text>
        </View>
    )
}

const ShareEmail = ({post, showNotification}: any) => {
    const postUrl = window.location.protocol + '//' + window.location.host + '/posts/' + post.id

    const onClick = () => {
        const url = encodeURIComponent(postUrl);
        const emailShareUrl = `mailto:?subject=Check out this post&body=${url}`;
        window.open(emailShareUrl, '_blank', 'noopener,noreferrer');
        showNotification();
    }
    return (
        <View display="flex" flexDirection="column" alignItems="center" gap="8px">
            <Button 
                noStyle 
                onClick={onClick} 
                background="#f5f5f5" 
                borderRadius="50%" 
                width="48px" 
                height="48px" 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
            >
                <EmailIcon />
            </Button>
            <Text fontSize="14px" fontWeight="500">Email</Text>
        </View>
    )
}

const Share = ({post, noAdjust}: any) => {
    const postUrl = window.location.protocol + '//' + window.location.host + '/posts/' + post.id

    const [notification, setNotification] = useState({ visible: false, message: "" });

    const popoutRef = useRef<HTMLDivElement>(null);
    const [left, setLeft] = useState(0);

    useEffect(() => {
        if (popoutRef.current) {
            const width = popoutRef.current.offsetWidth;
            setLeft(noAdjust ? 0 : -width);
        }
    }, []);

    // Create a context for sharing notification state
    const shareContext = {
        showNotification: (message: string = "Link Copied Successfully!") => {
            setNotification({ visible: true, message });
        },
        hideNotification: () => {
            setNotification({ visible: false, message: "" });
        }
    };

    return (
        <>
            <ShareNotification 
                message={notification.message} 
                visible={notification.visible} 
                onClose={() => setNotification({ visible: false, message: "" })} 
            />
            
            <StyledShareContainer left={left} ref={popoutRef}>
                <StyledShare>
                    <View padding="0" width="100%">
                        <View 
                            display="grid" 
                            gridTemplateColumns="repeat(6, 1fr)" 
                            gap="8px" 
                            width="100%" 
                            justifyContent="center"
                        >
                            <ShareLink post={post} showNotification={shareContext.showNotification} />
                            <ShareInstagram post={post} showNotification={shareContext.showNotification} />
                            <ShareFacebook post={post} showNotification={shareContext.showNotification} />
                            <ShareMessenger post={post} showNotification={shareContext.showNotification} />
                            <ShareWhatsApp post={post} showNotification={shareContext.showNotification} />
                            <ShareEmail post={post} showNotification={shareContext.showNotification} />
                        </View>
                    </View>
                </StyledShare>
            </StyledShareContainer>
        </>
    )
}

export default Share