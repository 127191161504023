import { StyledImpressions, StyledImpressionsContainer } from "./styled"
import {ReactComponent as ChartIcon} from '../../../icons/chart.svg'
import { Text } from "../../Text";
import Button from "../Button";

type ImpressionsProps = {
    data?: any;
}


const Impressions = ({data}: ImpressionsProps) => {
    return (
        <StyledImpressionsContainer>
            <StyledImpressions>
                <Button onClick={() => {}} noStyle cursor="unset" isNoEffect>
                    <ChartIcon />
                    <Text>{data.total_views}</Text>
                </Button>
            </StyledImpressions>
        </StyledImpressionsContainer>
    )
}

export default Impressions