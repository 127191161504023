import styled from 'styled-components'

interface MediaUploadWrapperProps {

}

export const StyledMediaWrapper  = styled.div<MediaUploadWrapperProps>`

`

interface MediaUploadContainerProps {
    height?: string;
}

export const StyledMediaUploadContainer  = styled.div<MediaUploadContainerProps>`
    height: 368px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: dashed 1px  #D9D9D9;
    border-radius: 24px;
    background: #F5F5F5;

    @media (min-width: 768px) {
        height: ${props => props.height || '368px'}
    }

    @media (min-width: 1024px) {

    }
`

interface MediaUploadProps {

}

export const StyledMediaUpload  = styled.div<MediaUploadProps>`
    height: 360px;

    @media (min-width: 768px) {
        height: 600px;
    }

    @media (min-width: 1024px) {

    }
`

interface UploadIconContainerProps {

}

export const StyledUploadContainer  = styled.div<UploadIconContainerProps>`

`

interface UploadTitleContainerProps {

}

export const StyledUploadTitleContainer  = styled.div<UploadTitleContainerProps>`
    padding: 12px;
    text-align: center;
`

interface UploadButtonContainerProps {

}

export const StyledUploadButtonContainer  = styled.div<UploadButtonContainerProps>`
    padding: 12px 0px;
`

interface UploadedMediaContainerProps {
    padding?: string;
}

export const StyledUploadedMediaContainer  = styled.div<UploadedMediaContainerProps>`
    padding: ${props => props.padding || '12px 0px'};
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
`

interface UploadedMediaProps {
    gridGap?: string;
    total?: number;
}

export const StyledUploadedMedia  = styled.div<UploadedMediaProps>`
    display: flex;
    gap: ${props => props.gridGap || '16px'};
    min-width: min-content;
    padding: 8px;
`

interface UploadedPreviewContainerProps {
    height?: string;
    width?: string;
    isDragging?: boolean;
}

export const StyledUploadedPreviewContainer  = styled.div<UploadedPreviewContainerProps>`
    background: #D9D9D9;
    position: relative;
    border-radius: 10px;
    cursor: grab;
    transition: transform 0.2s ease, box-shadow 0.3s ease, opacity 0.2s ease;
    user-select: none;
    
    &:active {
        cursor: grabbing;
    }

    ${props => props.isDragging && `
        transform: scale(1.05);
        box-shadow: 0 8px 20px rgba(0,0,0,0.2);
        opacity: 0.9;
        z-index: 999;
    `}
    
    &:hover {
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        
        .delete-button {
            opacity: 1;
        }
    }

    @media (min-width: 768px) {
        height: ${props => props.height || '120px'};
        width: ${props => props.width || '120px'};
    }
`

interface UploadedPreviewProps {

}

export const StyledUploadedPreview = styled.div<UploadedPreviewProps>`
    height: inherit;
    width: inherit;
    border-radius: 10px;
    overflow: hidden;
`

interface RemoveImageContainerProps {

}

export const StyledRemoveImageContainer = styled.div<RemoveImageContainerProps>`
    position: absolute;
    top: 4px;
    right: 4px;
`

export const StyledDeleteButton = styled.button`
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease, background-color 0.2s ease;
    z-index: 10;
    
    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
    
    svg {
        width: 14px;
        height: 14px;
    }
`
