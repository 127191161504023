import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../../services/firebase.service';
import { Text } from '../Text';
import { Input } from '../Input';
import { Button } from '../Button';
import { StyledResetPasswordContainer, StyledResetPasswordForm, StyledStatusMessage } from './styled';
import { StyledLayout } from '../../sections/Layout/styled';
import { Footer } from '../../sections/Layout/Footer';
import { PasswordInput } from '../Signup/Signup';
import { Header } from '../../sections/Landing/MainContent/Header';
import { StyledMainContentContainer } from '../../sections/Landing/MainContent/styled';
import { useSignupStore } from '../../stores/web.store';

const ResetPassword = () => {
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const setSignupStore = useSignupStore((state: any) => state.setSignupStore)
    const [status, setStatus] = useState<{ message: string; isError: boolean } | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [oobCode, setOobCode] = useState<string | null>(null);
    const [disabled, setDisabled] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('oobCode');
        
        if (!code) {
            setStatus({ message: 'Invalid password reset link', isError: true });
            return;
        }

        verifyPasswordResetCode(auth, code)
            .then((email) => {
                setEmail(email);
                setOobCode(code);
            })
            .catch((error) => {
                setStatus({ 
                    message: error.message.replace('Firebase:', 'Primary Yellow:'), 
                    isError: true 
                });
            });
    }, [location]);

    useEffect(() => {
        if (signupStore.newPassword && signupStore.confirmPassword && signupStore.newPassword === signupStore.confirmPassword) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [signupStore])

    const onClickReset = async () => {
        if (!oobCode) {
            setStatus({ message: 'Invalid reset code', isError: true });
            return;
        }

        if (!signupStore.newPassword || !signupStore.confirmPassword) {
            setStatus({ message: 'Please fill in both password fields', isError: true });
            return;
        }

        if (signupStore.newPassword !== signupStore.confirmPassword) {
            setStatus({ message: 'Passwords do not match', isError: true });
            return;
        }

        try {
            await confirmPasswordReset(auth, oobCode, signupStore.newPassword);
            setStatus({ message: 'Password reset successful! Redirecting to login...', isError: false });
            // Clear passwords from store
            setSignupStore({ ...signupStore, newPassword: '', confirmPassword: '' });
            setTimeout(() => navigate('/'), 2000);
        } catch (error: any) {
            setStatus({ 
                message: error.message.replace('Firebase:', 'Primary Yellow:'), 
                isError: true 
            });
        }
    };

    return (
        <StyledLayout>
            <StyledMainContentContainer>
                <Header />
                <StyledResetPasswordContainer>
                    <StyledResetPasswordForm>
                        {status && (
                            <StyledStatusMessage isError={status.isError}>
                                {status.message}
                            </StyledStatusMessage>
                        )}

                        <Text 
                            variant="h2" 
                            color="#fff"
                            fontSize="24px"
                            fontWeight="500"
                            style={{ textAlign: 'center' }}
                        >
                            Reset Password
                        </Text>

                        {email && (
                            <Text 
                                color="#787878"
                                fontSize="16px"
                                style={{ textAlign: 'center' }}
                            >
                                Enter a new password for {email}
                            </Text>
                        )}

                        <PasswordInput 
                            placeholder="New Password" 
                            storeKey="newPassword" 
                            isCreatePassword
                        />
                        <PasswordInput 
                            placeholder="Confirm Password" 
                            storeKey="confirmPassword"
                            matchValue={signupStore.newPassword}
                        />

                        <Button 
                            height={'48px'}
                            background={'#0938F0'}
                            color={'#fff'}
                            borderRadius={'32px'}
                            fontSize={'16px'}
                            onClick={onClickReset}
                            hoverEffect={{background: '#2f36b1'}}
                            disabled={disabled}
                        >
                            Reset Password
                        </Button>
                    </StyledResetPasswordForm>
                </StyledResetPasswordContainer>
            </StyledMainContentContainer>
            <Footer />
        </StyledLayout>
    );
};

export default ResetPassword;