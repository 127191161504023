import { useThemeStore } from "../../../stores/theme.store"
import { Button } from "../../Button"
import { Text } from "../../Text"
import { View } from "../../View"


const UpgradePremium = () => {
    const theme = useThemeStore((state: any) => state.theme)
    return (
        <View background={`${theme.PrimaryYellow}10`} border={`1px solid ${theme.PrimaryYellow}40`} borderRadius="10px">
            <View padding="12px 8px">
                <View display="flex" flexDirection="column" gap="8px" width="100%">
                    <View width="100%" textAlign="left">
                        <Text fontSize="16px" fontWeight="600">{'Upgrade to Premium'}</Text>
                    </View>
                    <View width="100%" textAlign="left">
                        <Text fontSize="14px">
                            {'Unlock exclusive features like private sales, lower transaction fees, and more.'}
                        </Text>
                    </View>
                    <View width="100%">
                        <Button
                            width="100%"
                            padding="8px 20px"
                            borderRadius="24px"
                            background={theme.PrimaryYellow}
                            color={theme.TextColorPrimary}
                            noStyle
                        >{'Upgrade Now'}</Button>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default UpgradePremium