import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import api from "../../../services/api.service"
import { usePostStore, useProductDetailPageStore, useViewNavigatorStore } from "../../../stores/web.store"
import { View } from "../../../components/View"
import { Button } from "../../../components/Button"
import {ReactComponent as ChevronLeftIcon} from '../../../img/chevronLeft.svg'
import { Text } from "../../../components/Text"
import { ImageGallery } from "../../ImageGallery"
import { StyledHeader, StyledHeaderContainer, StyledMainContent, StyledMainContentContainer } from "./styled"
import { key2Value, toCurrency } from "../../../utils/app.util"
import {ReactComponent as ArtworkIcon} from '../../../img/artwork.svg'
import { Divider } from "../../Divider"
import { ShippingAndFees } from "../../../sections/ShippingAndFees"
import GalleryInfo from "../../GalleryInfo/GalleryInfo"
import { SaveButton } from "../../Button/Save"
import { ViewInRoomButton } from "../../Button/ViewInRoom"
import { ShareButton } from "../../Button/Share"
import { AboutTheWork } from "../../../sections/AboutTheWork"
import { ArtistOtherWorks } from "../../../sections/OtherWorks/Artists"
import { GalleryOtherWorks } from "../../../sections/OtherWorks/Gallery"
import { RecentlyViewed } from "../../../sections/RecentlyViewed"
import { Purchase } from "../../Button/Purchase"
import { OfferModal } from "../../Modal/OfferModal"
import { Offer } from "../../Button/Offer"
import { useThemeStore } from "../../../stores/theme.store"


const Artwork = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const post = usePostStore((state: any) => state.post)
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const [isOfferModal, setIsOfferModal] = useState(false)
    const {id} = useParams()
    
    useEffect(() => {
        setActiveView('overview')
        const fetch = async () => {
            const resp = await api.get(`/posts/${id}`)
            setPost(resp.data.data)
        }

        if (!post && id)
            fetch()
        
    }, [])

    const onClickBack = () => {
        setPost(null)
        setPdp(null)
    }

    if (post === null) return <div />

    return (
        <View>
            <StyledHeaderContainer theme={theme}>
                <StyledHeader>
                    <Button noStyle onClick={onClickBack}>
                        <ChevronLeftIcon height={16} width={16}/> 
                        <Text fontWeight="500">{`Artworks page`}</Text>
                    </Button>
                </StyledHeader>
            </StyledHeaderContainer>
            <View background={theme.BackgroundColorTheme} padding="24px" >
                <View background={theme.CardPrimaryBackgroundColor} borderRadius="10px" boxShadow="0px 0px 8px 0px rgba(213, 213, 205, 0.25)">
                    <StyledMainContentContainer>
                        <StyledMainContent>
                            <View>
                                <ImageGallery data={post} setPost={setPost} height="600px" borderRadius="10px"/>
                            </View>
                            <View position="relative">
                                <Text fontSize="24px" fontWeight="500">{post.owner.first_name} {post.owner.last_name}</Text>
                                <View color="grey" padding="12px 0px">
                                    <Text fontSize="24px" fontWeight="500">{post.title}, {post.date || '2024'}</Text>
                                    <View padding="12px 0px" display="flex" flexDirection="column" gap="4px">
                                        <View display="inline-flex" alignItems="center">
                                            <Text textTransform="capitalize">{post.metadata.medium}</Text>
                                            <Text></Text>
                                        </View>
                                        <Text>{post.metadata.dimensions || '00 x 00 x 00'}</Text>
                                        <View display="inline-flex" alignItems="center" gap="4px">
                                            <ArtworkIcon stroke="grey" fill="grey" height={15} width={15}/>
                                            <Text textTransform="capitalize">{key2Value(post.metadata.rarity)}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View>
                                    <Text fontWeight="500" fontSize="24px">{toCurrency(post.state === 'auction' ? post.metadata.starting_bid : post.metadata.price, post.metadata.currency)}</Text>
                                </View>
                                <View padding="12px 0px">
                                    <div style={{padding: '12px 0px 0px 0px'}}>
                                        <Purchase />
                                    </div>
                                    <div style={{padding: '12px 0px'}}>
                                        <Offer isModal={isOfferModal} setIsModal={setIsOfferModal}/>
                                    </div>
                                </View>
                                <Divider />
                                <View position="absolute" width="calc(100% - 24px)">
                                    <View>
                                        <ShippingAndFees />
                                    </View>
                                    <Divider />
                                    <View>
                                        <GalleryInfo data={post.owner} />
                                    </View>
                                    <Divider />
                                    <View padding="12px 0px">
                                        <Text 
                                            fontWeight="500"
                                            color="#808080"
                                        >{`Want to sell a work on Primary Yellow?`} <Button noStyle textDecoration="underline" fontWeight="500" color="#808080">Sell with Primary Yellow</Button>
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View>
                                <AboutTheWork />
                            </View>
                        </StyledMainContent>
                        <View padding="48px 0px">
                            <ArtistOtherWorks post={post} />
                        </View>
                        {post.gallery && <View padding="0px 0px 48px 0px">
                            <GalleryOtherWorks post={post} />
                        </View>}
                        <View>
                            <RecentlyViewed />
                        </View>
                    </StyledMainContentContainer>
                </View>
                {isOfferModal && <OfferModal setModal={setIsOfferModal}/>}
            </View>
        </View>
    )
}

export default Artwork