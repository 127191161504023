import styled from 'styled-components'


interface DashboardContainerProps {

}

export const StyledDashboardContainer = styled.div<DashboardContainerProps>`

`

interface DashboardProps {

}

export const StyledDashboard = styled.div<DashboardProps>`

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {

    }
`

interface DashboardContentProps {

}

export const StyledDashboardContent = styled.div<DashboardContentProps>`
    display: block;

    @media (min-width: 768px) {
        display: inline-flex;
        width: 100%;
        gap: 24px;
    }

    @media (min-width: 1024px) {

    }
`

interface InfoSectionProps {

}

export const StyledInfoSection = styled.div<InfoSectionProps>`
    display: none;

    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: calc(100vh - 48px);
        overflow-y: scroll;
        width: 100%;
    }

    @media (min-width: 1024px) {

    }
    

`
