import styled from 'styled-components'

interface PostDetailsContainerProps {

}


export const StyledPostDetailsContainer  = styled.div<PostDetailsContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

`

interface PostDetailsProps {
    isPost?: boolean;
    modalDimension?: any;
}

export const StyledPostDetails = styled.div<PostDetailsProps>`
    width: 100%;
    margin: auto;
    background: #fff;
    
    @media (min-width: 768px) {
        height: 600px;
        ${props => props.isPost && `
            display: grid;
            grid-template-columns: ${props.modalDimension.ratio < 1 ? `${props.modalDimension.modalWidth}px` : 'calc(100% - 600px)'} ${props.modalDimension.ratio < 1 ? `calc(100% - ${props.modalDimension.modalWidth}px)` : '600px'};
            grid-gap: 0;
        `}
    }

    @media (min-width: 1024px) {

    }

    
`

interface PostPhotoContainerProps {

}

export const StyledPostPhotoContainer = styled.div<PostPhotoContainerProps>`
    background: #D9D9D9;
    display: flex;
    flex-direction: column;
    
    background: #fff;

    @media (min-width: 768px) {
        height: 600px;
    }

    @media (min-width: 1024px) {

    }
`

interface DetailsContainerProps {

}

export const StyledDetailsContainer = styled.div<DetailsContainerProps>`
    height: 100vh;
`

interface DetailsProps {

}

export const StyledDetails = styled.div<DetailsProps>`
    // padding: 8px;
    position: relative;
    -webkit-box-shadow: -5px 4px 11px -12px rgba(0,0,0,0.75);
    -moz-box-shadow: -5px 4px 11px -12px rgba(0,0,0,0.75);
    box-shadow: -5px 4px 11px -12px rgba(0,0,0,0.75);
    

    @media (min-width: 768px) {
        height: calc(600px);
    }

    @media (min-width: 1024px) {

    }
`

interface CommentContainerProps {

}

export const StyledCommentContainer = styled.div<CommentContainerProps>`
    position: absolute;
    bottom: 0px;
    left: 0;
    margin: auto;
    background: #fff;
    box-shadow: 0px -3px 4px 0px rgba(191, 191, 191, 0.25);
    padding: 12px 0px;

    @media (min-width: 768px) {
        width: calc(100%);
    }

    @media (min-width: 1024px) {

    }
`

export const StyledSettingsIconContainer = styled.div<{ isActive?: boolean }>`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    background-color: ${props => props.isActive ? 'rgba(0, 0, 0, 0.05)' : 'transparent'};
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    
    svg {
        width: 20px;
        height: 20px;
    }
`
