import styled from 'styled-components'


interface ArtworksContainerProps {

}


export const StyledArtworksContainer = styled.div<ArtworksContainerProps>`

`

interface ArtworksProps {

}


export const StyledArtworks = styled.div<ArtworksProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 12px;
`


