import React, { useState, useEffect, useMemo, memo } from 'react';
import { useEffectOnce } from "../../utils/app.util";
import {
  StyledContainer,
  StyledHeader,
  StyledTitle,
  StyledResultCount,
  StyledSortSelect,
  StyledAuctionGrid,
  StyledAuctionItem,
  StyledImageContainer,
  StyledArtworkImage,
  StyledArtworkInfo,
  StyledArtworkTitle,
  StyledArtworkDetails,
  StyledSaleInfo,
  StyledPrice,
  StyledEstimate,
  StyledEstimateChange,
  StyledSaleDate,
  StyledLotNumber,
  StyledAuctionHouse
} from './styled';
import { format, parseISO } from 'date-fns';
import api from "../../services/api.service";
import { useThemeStore } from '../../stores/theme.store';
import { usePostStore, useProductDetailPageStore } from '../../stores/web.store';
import { Button } from '../../components/Button';

interface Sale {
  id: string;
  name: string;
  start_at: string;
  auction_state: string;
  time_zone: string;
}

interface SaleArtwork {
  id: string;
  artwork_id: string;
  sale_id: string;
  artist_ids: string[];
  sale_name: string;
  sale_date: string;
  bidder_positions_count: number;
  currency: string;
  estimate_cents: number;
  display_estimate_dollars: string;
  display_high_estimate_dollars: string;
  display_highest_bid_amount_dollars: string;
  display_low_estimate_dollars: string;
  display_opening_bid_dollars: string;
  high_estimate_cents: number;
  highest_bid_amount_cents: number;
  lot_number: string;
  low_estimate_cents: number;
  reserve_cents: number;
  reserve_status: string;
  high_bidder_id: string;
  bidders_count: number;
  high_bid_at: string;
  highest_bidder_position_id: string;
  created_at: string;
  updated_at: string;
  _links: {
    permalink: { href: string };
    artwork: { href: string };
    sale: { href: string };
    collection_items: { href: string };
  };
  artwork: {
    id: string;
    slug: string;
    title: string;
    display: string;
    category: string;
    medium: string;
    date: string;
    dimensions: {
      in: string;
      cm: string;
    };
    width: string;
    height: string;
    depth: string;
    diameter: string;
    width_cm: number;
    height_cm: number;
    depth_cm: number;
    diameter_cm: number;
    metric: string;
    size_score: number;
    size_bucket: string;
    signature: string;
    provenance: string;
    literature: string;
    exhibition_history: string;
    collecting_institution: string;
    additional_information: string;
    image_rights: string;
    blurb: string;
    price: string;
    price_hidden: boolean;
    price_currency: string;
    price_cents: number;
    sale_message: string;
    availability: string;
    images: any;
  };
  sale: {
    id: string;
    name: string;
    start_at: string;
    auction_state: string;
    time_zone: string;
  };
}

interface PricingDatabaseProps {
  profile: any;
}



const ArtworkItem = memo(({ artwork }: { artwork: SaleArtwork }) => {
  const setPost = usePostStore((state: any) => state.setPost)
  const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
  
  const formatDate = (dateString: string | undefined): string => {
    if (!dateString) return '';
    try {
        const date = parseISO(dateString);
        return format(date, 'MMM d, yyyy');
    } catch (e) {
        console.error('Invalid date:', dateString);
        return '';
    }
  };
  
  const saleDate = formatDate(artwork.sale_date);
  const artworkDetails = artwork.artwork || {};

  const onClickArtwork = () => {
    setPost(artwork);
    setPdp('saleArtwork');
  }

  // Format auction house name from sale_name (e.g., "Phillips • New York")
  const [auctionHouse, location] = artwork.sale_name?.split(' • ') || ['', ''];

  const theme = useThemeStore((state: any) => state.theme);

  const calculateEstimateChange = (artwork: SaleArtwork) => {
    if (!artwork.highest_bid_amount_cents || !artwork.high_estimate_cents) return null;
    
    const change = ((artwork.highest_bid_amount_cents - artwork.high_estimate_cents) / artwork.high_estimate_cents) * 100;
    return {
      value: Math.abs(change).toFixed(0),
      isPositive: change >= 0
    };
  };

  return (
    <Button noStyle onClick={onClickArtwork} width="100%">
      <StyledAuctionItem>
        <StyledImageContainer>
          <StyledArtworkImage 
            src={artworkDetails.images?.[0]?.image_urls?.main || artworkDetails.images?.[0]?.image_urls?.large || artworkDetails.images?.[0]?.image_urls?.four_thirds || artworkDetails.images?.[0]?.image_urls?.medium || artworkDetails.images?.[0]?.image_urls?.small || artworkDetails.images?.[0]?.image_urls?.square || artworkDetails.images?.[0]?.image_urls?.tall } 
            alt={artworkDetails.title}
          />
        </StyledImageContainer>

        <StyledArtworkInfo>
          <StyledArtworkTitle>
            {artworkDetails.title}
          </StyledArtworkTitle>
          <StyledArtworkDetails>
            <div>{artworkDetails.medium}</div>
            <div>{artworkDetails.dimensions?.in}</div>
          </StyledArtworkDetails>
          <div style={{ marginTop: '1rem' }}>
            <div style={{ fontSize: '1rem', color: theme.TextColorPrimary }}>{saleDate}</div>
            <div style={{ color: theme.TextColorPrimaryLighter, fontSize: '0.875rem', marginTop: '0.25rem' }}>{auctionHouse}</div>
            <div style={{ color: theme.TextColorPrimaryLighter, fontSize: '0.875rem', lineHeight: 1.4, marginTop: '0.25rem' }}>
              {artwork.sale.name}
            </div>
            <div style={{ color: theme.TextColorPrimaryLighter, fontSize: '0.875rem', marginTop: '0.5rem' }}>
              Lot {artwork.lot_number}
            </div>
          </div>
        </StyledArtworkInfo>

        <StyledSaleInfo>
          <StyledPrice>
            {artwork.display_highest_bid_amount_dollars || 
             (artwork.display_low_estimate_dollars && artwork.display_high_estimate_dollars ? 
               `${artwork.display_low_estimate_dollars}–${artwork.display_high_estimate_dollars} (est)` : 
               'Price not available'
             )
            }
          </StyledPrice>
          <StyledEstimate>
            {artwork.display_highest_bid_amount_dollars && artwork.display_low_estimate_dollars && artwork.display_high_estimate_dollars && 
              `${artwork.display_low_estimate_dollars}–${artwork.display_high_estimate_dollars} (est)`
            }
          </StyledEstimate>
          {(() => {
            const estimate = calculateEstimateChange(artwork);
            if (!estimate) return null;
            return (
              <StyledEstimateChange isPositive={estimate.isPositive}>
                {estimate.isPositive ? '+' : '-'}{estimate.value}% est
              </StyledEstimateChange>
            );
          })()}
        </StyledSaleInfo>
      </StyledAuctionItem>
    </Button>
  );
});

const PricingDatabase: React.FC<PricingDatabaseProps> = ({ profile }) => {
  const [saleArtworks, setSaleArtworks] = useState<SaleArtwork[]>([]);
  const [sortBy, setSortBy] = useState<string>('date');
  const [loading, setLoading] = useState(true);
  const theme = useThemeStore((state: any) => state.theme);

  useEffectOnce(() => {
    fetchSaleArtworks();
  });

  const fetchSaleArtworks = async () => {
    try {
      const response = await api.post(`/search/pricing-database/auctions`, {artist_id: profile.id, external_id: profile.external_id});
      console.log('Fetched artworks:', response.data.length);
      setSaleArtworks(response.data);
    } catch (error) {
      console.error('Error fetching sale artworks:', error);
    } finally {
      setLoading(false);
    }
  };

  const sortedArtworks = useMemo(() => {
    return [...saleArtworks].sort((a, b) => {
      switch (sortBy) {
        case 'date':
          return new Date(b.sale_date).getTime() - new Date(a.sale_date).getTime();
        case 'price':
          return (b.highest_bid_amount_cents || 0) - (a.highest_bid_amount_cents || 0);
        case 'estimate':
          return (b.high_estimate_cents || 0) - (a.high_estimate_cents || 0);
        default:
          return 0;
      }
    });
  }, [saleArtworks, sortBy]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <StyledContainer>
      <StyledHeader>
        <div>
          <StyledTitle>Past Auctions</StyledTitle>
          <StyledResultCount>{saleArtworks.length} results</StyledResultCount>
        </div>
        <StyledSortSelect value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
          <option value="date">Sale Date (Most recent)</option>
          <option value="price">Price (Highest)</option>
          <option value="estimate">Estimate (Highest)</option>
        </StyledSortSelect>
      </StyledHeader>

      <StyledAuctionGrid>
        {sortedArtworks.map((artwork) => (
          <ArtworkItem key={artwork.id} artwork={artwork} />
        ))}
      </StyledAuctionGrid>
    </StyledContainer>
  );
};

export default PricingDatabase;