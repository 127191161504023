import { StyledCreatePostContainer, StyledCreatePostTitleSection, StyledSelectedPostContainer } from "./styled"
import { Text } from "../../components/Text";
import { useDashboardCreateModeStore, useResponsiveStore, useSelectedPostStore } from "../../stores/web.store";
import { CreateMediaPost } from "./CreateMediaPost";
import { CreateArtworkPost } from "./CreateArtworkPost";
import { CreateShowPost } from "./CreateShowPost";
import { CreateAuctionPost } from "./CreateAuctionPost";
import { useThemeStore } from "../../stores/theme.store";


const SelectedPost = () => {
    const selectedPost = useSelectedPostStore((state: any) => state.selectedPost)
    return (
        <StyledSelectedPostContainer>
            {selectedPost === 'media' && <CreateMediaPost />}
            {selectedPost === 'artwork' && <CreateArtworkPost />}
            {selectedPost === 'show' && <CreateShowPost />}
            {selectedPost === 'auction' && <CreateAuctionPost />}
        </StyledSelectedPostContainer>
    )
}

const CreatePost = () => {
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const selectedPost = useSelectedPostStore((state: any) => state.selectedPost)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const width = useResponsiveStore((state: any) => state.width)
    const onClickBack = () => {
        setIsCreateMode(false)
        setSelectedPost(null)
        
    }
    
    return (
        <StyledCreatePostContainer>
            <SelectedPost />
        </StyledCreatePostContainer>
    )
}

export default CreatePost