import { useState, useRef, useEffect } from "react"
import { StyledImage, StyledImageContainer } from "./styled"

type ImageProps = {
    src: string;
    display?: string;
    height?: string;
    maxHeight?: string;
    width?: string;
    borderRadius?: string;
    objectFit?: string;
    border?: string;
    zoom?: number;
    aspectRatio?: string;
    isNoEffect?: boolean;
    imageRef?: any;
    style?: any;
    enablePanZoom?: boolean;
}

const Image = ({
    src, 
    imageRef, 
    height, 
    maxHeight, 
    width, 
    border, 
    objectFit, 
    borderRadius, 
    zoom, 
    aspectRatio, 
    isNoEffect, 
    style,
    enablePanZoom = false
}: ImageProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isPanning, setIsPanning] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(1);
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });
    
    // Reset position and scale when image changes
    useEffect(() => {
        setPosition({ x: 0, y: 0 });
        setScale(1);
    }, [src]);
    
    const handleWheel = (e: React.WheelEvent) => {
        if (!enablePanZoom) return;
        
        e.preventDefault();
        
        // Calculate new scale with limits
        const delta = e.deltaY * -0.01;
        const newScale = Math.min(Math.max(scale + delta, 1), 5);
        
        // Get mouse position relative to image
        const rect = containerRef.current?.getBoundingClientRect();
        if (!rect) return;
        
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;
        
        // Calculate new position to zoom toward mouse position
        let newPosition = {
            x: position.x - ((mouseX - position.x) * (newScale - scale)) / scale,
            y: position.y - ((mouseY - position.y) * (newScale - scale)) / scale
        };
        
        // Reset position if zooming back to 1
        if (newScale <= 1) {
            newPosition = { x: 0, y: 0 };
        } else {
            // Apply constraints to prevent dragging too far
            const maxX = (newScale - 1) * rect.width;
            const maxY = (newScale - 1) * rect.height;
            
            newPosition.x = Math.min(Math.max(newPosition.x, -maxX), 0);
            newPosition.y = Math.min(Math.max(newPosition.y, -maxY), 0);
        }
        
        setScale(newScale);
        setPosition(newPosition);
    };
    
    const handleMouseDown = (e: React.MouseEvent) => {
        if (!enablePanZoom || scale <= 1) return;
        
        e.preventDefault(); // Prevent image dragging behavior
        setIsPanning(true);
        setStartPos({ x: e.clientX - position.x, y: e.clientY - position.y });
    };
    
    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isPanning || !enablePanZoom) return;
        
        e.preventDefault();
        const newPosition = {
            x: e.clientX - startPos.x,
            y: e.clientY - startPos.y
        };
        
        // Apply constraints to prevent dragging too far
        const containerRect = containerRef.current?.getBoundingClientRect();
        if (containerRect) {
            const maxX = (scale - 1) * containerRect.width;
            const maxY = (scale - 1) * containerRect.height;
            
            newPosition.x = Math.min(Math.max(newPosition.x, -maxX), 0);
            newPosition.y = Math.min(Math.max(newPosition.y, -maxY), 0);
        }
        
        setPosition(newPosition);
    };
    
    const handleMouseUp = () => {
        setIsPanning(false);
    };
    
    const handleDoubleClick = () => {
        if (!enablePanZoom) return;
        
        // Reset zoom and position on double click
        setScale(1);
        setPosition({ x: 0, y: 0 });
    };
    
    // Add touch support with improved pinch zoom
    const lastTouchDistance = useRef<number | null>(null);
    
    const handleTouchStart = (e: React.TouchEvent) => {
        if (!enablePanZoom) return;
        
        if (e.touches.length === 1 && scale > 1) {
            // Single touch for panning when zoomed in
            setIsPanning(true);
            setStartPos({ 
                x: e.touches[0].clientX - position.x, 
                y: e.touches[0].clientY - position.y 
            });
        } else if (e.touches.length === 2) {
            // Two touches for pinch zoom
            const dx = e.touches[0].clientX - e.touches[1].clientX;
            const dy = e.touches[0].clientY - e.touches[1].clientY;
            lastTouchDistance.current = Math.sqrt(dx * dx + dy * dy);
        }
    };
    
    const handleTouchMove = (e: React.TouchEvent) => {
        if (!enablePanZoom) return;
        
        e.preventDefault(); // Prevent scrolling while interacting with image
        
        if (e.touches.length === 1 && isPanning) {
            // Handle panning with single touch
            const newPosition = {
                x: e.touches[0].clientX - startPos.x,
                y: e.touches[0].clientY - startPos.y
            };
            
            // Apply constraints
            const containerRect = containerRef.current?.getBoundingClientRect();
            if (containerRect) {
                const maxX = (scale - 1) * containerRect.width;
                const maxY = (scale - 1) * containerRect.height;
                
                newPosition.x = Math.min(Math.max(newPosition.x, -maxX), 0);
                newPosition.y = Math.min(Math.max(newPosition.y, -maxY), 0);
            }
            
            setPosition(newPosition);
        } else if (e.touches.length === 2 && lastTouchDistance.current !== null) {
            // Handle pinch zoom
            const dx = e.touches[0].clientX - e.touches[1].clientX;
            const dy = e.touches[0].clientY - e.touches[1].clientY;
            const distance = Math.sqrt(dx * dx + dy * dy);
            
            // Calculate new scale based on pinch distance change
            const delta = (distance - lastTouchDistance.current) * 0.01;
            const newScale = Math.min(Math.max(scale + delta, 1), 5);
            
            // Get center point between the two touches
            const centerX = (e.touches[0].clientX + e.touches[1].clientX) / 2;
            const centerY = (e.touches[0].clientY + e.touches[1].clientY) / 2;
            
            // Get container position
            const rect = containerRef.current?.getBoundingClientRect();
            if (rect) {
                const touchX = centerX - rect.left;
                const touchY = centerY - rect.top;
                
                // Calculate new position to zoom toward pinch center
                const newPosition = {
                    x: position.x - ((touchX - position.x) * (newScale - scale)) / scale,
                    y: position.y - ((touchY - position.y) * (newScale - scale)) / scale
                };
                
                setScale(newScale);
                setPosition(newPosition);
            }
            
            lastTouchDistance.current = distance;
        }
    };
    
    const handleTouchEnd = () => {
        setIsPanning(false);
        lastTouchDistance.current = null;
    };
    
    if (!enablePanZoom) {
        return (
            <StyledImage 
                ref={imageRef}
                src={src} 
                height={height} 
                maxHeight={maxHeight}
                width={width}
                border={border}
                objectFit={objectFit}
                borderRadius={borderRadius}
                zoom={zoom}
                aspectRatio={aspectRatio}
                isNoEffect={isNoEffect}
                style={style}
            />
        );
    }
    
    return (
        <StyledImageContainer
            ref={containerRef}
            height={height}
            width={width}
            borderRadius={borderRadius}
            border={border}
            style={{
                overflow: 'hidden',
                cursor: isPanning ? 'grabbing' : scale > 1 ? 'grab' : 'default',
                userSelect: 'none', // Prevent text selection during drag
                touchAction: 'none', // Disable browser handling of touch gestures
                ...style
            }}
            onWheel={handleWheel}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onDoubleClick={handleDoubleClick}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <StyledImage 
                ref={imageRef}
                src={src} 
                height="100%"
                width="100%"
                objectFit={objectFit || "contain"}
                style={{
                    transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
                    transformOrigin: '0 0',
                    transition: isPanning ? 'none' : 'transform 0.1s ease-out',
                    pointerEvents: 'none', // Prevent image from capturing mouse events
                    userSelect: 'none', // Prevent text selection
                }}
                draggable="false" // Prevent default image drag behavior
            />
            {scale > 1 && (
                <div style={{
                    position: 'absolute',
                    bottom: '16px',
                    right: '16px',
                    background: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    pointerEvents: 'none'
                }}>
                    {Math.round(scale * 100)}%
                </div>
            )}
        </StyledImageContainer>
    );
};

export default Image;