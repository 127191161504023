import { Button } from "../../../components/Button"
import { Text } from "../../../components/Text"
import { View } from "../../../components/View"
import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { FooterAd } from "../../../sections/Layout/Footer/FooterAd"
import { Card } from "../Card"
import { StyledHeroContainer } from "./styled"


type CardProps = {
    isReverse?: boolean;
}

const BuyArt = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Buy art easily"}
            description={"A simple, secure, way to buy art on both the primary and secondary market. From discovery to fulfillment. Primary Yellow is for art collecting."}
            image={""}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const SellArt = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Sell art easily"}
            description={"Sell with Primary Yellow is the modern solution to adjust your collection to your changing tastes and desires. Sell discreetly with controlled privacy settings or curate public shows from your own collection."}
            image={""}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const Invest = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Invest in art"}
            description={"One of our goals at Primary Yelliow is to open up access to the art world for everyone. We’re building the investment products and infrastructure to make that happen."}
            image={""}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const Manage = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Manage your collection"}
            description={"Manage everything art related in one place and create a visual catalog of your collection with controlled privacy settings."}
            image={""}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const Build = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Build your own museum in VR"}
            description={"We’re building out features so you can create your own museum and people can visit it in VR on the Apple Vision Pro."}
            image={""}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const ForCollectors = () => {
    return (
        <DashboardLayout>
            <StyledHeroContainer>
                <View display="flex" alignItems="center" height="678px">
                    <View width="100%">
                        <Text padding="0px 0px 0px 64px" fontSize="4vw" color="#fff" fontWeight="500" width="70%">
                            {"Buying and selling made easy, on Primary Yellow."}
                        </Text>
                        <View width="100%" padding="24px 0px 0px 64px">
                            <Button 
                                height="48px" 
                                width="160px" 
                                borderRadius="24px" 
                                background="#fff" 
                                border="solid 1px #000"
                                fontSize="20px"
                                fontWeight="500"
                                noStyle
                            >Get Started</Button>
                        </View>
                    </View>
                </View>
            </StyledHeroContainer>
            <View padding="64px 48px">
                <View display="flex" flexDirection="column" gap="120px">
                    <BuyArt />
                    <SellArt isReverse/>
                    <Invest />
                    <Manage isReverse />
                    <Build />
                    <View>
                        <FooterAd image={''} />
                    </View>
                </View>
            </View>
        </DashboardLayout>
    )
}

export default ForCollectors