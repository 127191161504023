import { useEffect, useState } from "react"
import { useActiveFeedStore, useResponsiveStore } from "../../stores/web.store"
import { Button } from "../Button"
import { Text } from "../Text"
import { StyledFeedNavigation, StyledFeedNavigationContainer } from "./styled"
import { useThemeStore } from "../../stores/theme.store"
import { View } from "../View"


const FeedNavigation = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const activeFeed = useActiveFeedStore((state: any) => state.activeFeed)
    const setActiveFeed = useActiveFeedStore((state: any) => state.setActiveFeed)
    const width = useResponsiveStore((state: any) => state.width)

    const onClick = (value: string) => {
        if (activeFeed !== value) {
            setActiveFeed(value)
        }
    }
    
    return (
        <StyledFeedNavigation>
            <Button 
                onClick={() => onClick('following')} 
                width="100%"
                fontSize="14px"
                padding={'8px'}
                color={activeFeed === 'following' ? theme.TextColorPrimary : theme.TextColorPrimaryLighter}
                fontWeight={activeFeed === 'following' ? '500' : '400'}
                borderBottom={activeFeed === 'following' ? `solid 1px ${theme.PrimaryYellow}` : 'none'}
                noStyle
            ><Text fontSize="14px" fontWeight="500" width="max-content">Following</Text></Button>
            <Button 
                onClick={() => onClick('forYou')} 
                width="100%"
                fontSize="14px"
                padding={'8px'}
                color={activeFeed === 'forYou' ? theme.TextColorPrimary : theme.TextColorPrimaryLighter}
                fontWeight={activeFeed === 'forYou' ? '500' : '400'}
                borderBottom={activeFeed === 'forYou' ? `solid 1px ${theme.PrimaryYellow}` : 'none'}
                noStyle
            ><Text fontSize="14px" fontWeight="500" width="max-content">For You</Text></Button>
        </StyledFeedNavigation>
    )
}

export default FeedNavigation