import { StyledAILensIcon, StyledSearchBar, StyledSearchBarContainer, StyledSearchBarExitIcon, StyledSearchBarIcon, StyledSearchResultsDropdown, StyledSearchResultItem } from "./styled"
import { Button } from "../Button"
import _debounce from "lodash/debounce"
import {ReactComponent as SearchIcon} from '../../img/search.svg'
import {ReactComponent as XIcon} from '../../img/x.svg'
import {ReactComponent as AISearchIcon} from '../../img/aiSearch.svg'
import {ReactComponent as AILensIcon} from '../../img/aiLens.svg'
import { useCallback, useState } from "react";
import api from "../../services/api.service";
import { Text } from "../Text";
import { useFiltersStore } from "../../stores/web.store";
import { getDisplayName } from "../../utils/app.util";
import { ProfilePicture } from "../ProfilePicture"

type SearchBarProps = {
    placeholder?: string;
    onInput?: any;
    textIndent?: string; 
    onSearch?: any;
    id?: any;
    setData?: any;
    data?: any;
    filter?: any;
    setQuery?: any;
    setIsSearched?: any;
    resetData?: any;
    width?: string;
    borderRadius?: string;
    background?: string;
    border?: string;
    color?: string;
    prefix?: string;
    fetch?: any;
    imageSearch?: boolean;
    isAISearch?: boolean;
    noImageSearch?: boolean;
    searchAsType?: boolean;
    valuePath?: string | string[];
    searchFilters?: any;
    fontSize?: string;
    padding?: string;
    setSearchFilters?: (filters: any) => void;
}

const SearchBar = ({
    id, placeholder, background, border, prefix, color, textIndent, onInput, 
    setData, data, width, isAISearch, resetData, filter, setQuery, setIsSearched, 
    borderRadius, imageSearch, fetch, noImageSearch, searchAsType, valuePath, fontSize, padding,
    searchFilters, setSearchFilters
}: SearchBarProps) => {
    const [isFocus, setIsFocus] = useState(false)
    const [value, setValue] = useState<any>()
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    

    const handleDebounceFn = async (value: any, filter: any) => {
        if (value.length === 0) {
            if (resetData) {
                setData(resetData)
            } else {
                setData([])
            }
            
        } else {
            let resp;
            if (fetch) {
                fetch(value)
            } else {
                resp = await api.post('/search/accounts', {query: {query: value, ...filter}})
                setData(resp.data)
            }
            
            
        }

        return true
        
    }

    const debounceFn = useCallback(_debounce(handleDebounceFn, 500), [filterStore]);

    const handleSearch = async (searchValue: string) => {
        if (searchValue.length === 0) {
            if (resetData) {
                setData(resetData)
            } else {
                setData([])
            }
        } else {
            let resp;
            if (fetch) {
                fetch(searchValue)
            } else {
                resp = await api.post('/search/accounts', {query: {query: searchValue, ...filter}})
                setData(resp.data)
            }
        }
    }

    const onBlur = (e: any) => {
        // Add setTimeout to allow click events on dropdown items to fire before hiding
        setTimeout(() => {
            const inputVal = e.target.value
            if (!inputVal) {
                setIsFocus(false)
            }
        }, 200)
    }

    const onChange = async (e: any) => {
        const inputVal = e.target.value
        setValue(inputVal)
        if (setQuery) {
            setQuery(inputVal)
        }

        if (setIsSearched) {
            setIsSearched(inputVal.length > 0)
            
        }

        if (searchAsType) {
            handleSearch(inputVal);
        } else {
            debounceFn(inputVal, filter);
        }
    }
    
    const onClickClear = () => {
        setData(resetData)
        setValue('')
        setIsFocus(false)
    }

    return (
        <StyledSearchBarContainer isFocus={isFocus} width={width} isAISearch={isAISearch}>
            <StyledSearchBarIcon>
                {prefix ? 
                    <Text fontSize="16px" lineHeight="20px">{prefix}</Text> : 
                    <Button noStyle color={'#000'}>
                        {isAISearch ? <AISearchIcon height={24} width={24}/> : <SearchIcon height={24} width={24} stroke="#737373"/>}
                    </Button>
                }
            </StyledSearchBarIcon>
            <StyledSearchBar 
                id={id} 
                fontSize={fontSize}
                padding={padding}
                background={background}
                color={color}
                border={border}
                placeholder={placeholder || "Search"} 
                borderRadius={borderRadius}
                onInput={onInput} 
                textIndent={textIndent}
                data-lpignore="true"
                onFocus={() => setIsFocus(true)}
                onBlur={(e: any) => onBlur(e)}
                onChange={onChange}
                value={value}
            />
            {isAISearch && !isFocus && !noImageSearch && <StyledAILensIcon>
                {prefix ? <Text fontSize="16px" lineHeight="20px">{prefix}</Text> : <Button noStyle color={'#000'}>
                    <AILensIcon height={20} width={20}/>
                </Button>}
            </StyledAILensIcon>}
            {isFocus && <StyledSearchBarExitIcon>
                <Button noStyle onClick={onClickClear} height="16px" width="16px" background="#C7C7C7" borderRadius="50%">
                    <XIcon height={12} width={12} stroke="#EDEDED" strokeWidth={6}/>
                </Button>
            </StyledSearchBarExitIcon>}
            {searchAsType && <StyledSearchResultsDropdown show={isFocus && data && data.length > 0}>
                {data?.map((item: any, index: number) => (
                    <StyledSearchResultItem 
                        key={index}
                        onClick={() => {
                            if (onInput) onInput(item);
                            setValue(getDisplayName(item, valuePath));
                            if (searchAsType && setSearchFilters) {
                                setSearchFilters({
                                    ...searchFilters,
                                    selected: item
                                });
                            }
                            setIsFocus(false);
                        }}
                    >
                        <ProfilePicture account={item} photo username onClick={() => {}}/>
                    </StyledSearchResultItem>
                ))}
            </StyledSearchResultsDropdown>}
        </StyledSearchBarContainer>
    )
}

export default SearchBar