import styled from 'styled-components'

interface ResetPasswordContainerProps {
}

export const StyledResetPasswordContainer = styled.div<ResetPasswordContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    padding: 20px;
`

interface ResetPasswordFormProps {
}

export const StyledResetPasswordForm = styled.div<ResetPasswordFormProps>`
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    background: rgba(0, 0, 0, 0.7);
    padding: 32px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        padding: 40px;
    }
`

interface StatusMessageProps {
    isError?: boolean;
}

export const StyledStatusMessage = styled.div<StatusMessageProps>`
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    color: ${props => props.isError ? '#f44336' : '#4CAF50'};
    font-size: 0.875rem;
    padding: 8px;
    border-radius: 4px;
`
