import { View } from "../../View"
import { SearchBar } from "../../SearchBar"
import api from "../../../services/api.service"
import { useState } from "react"


const ArtistSearch = ({searchData, setSearchData, searchFilters, setSearchFilters}: any) => {
    

    const fetch = async (value: any) => {
        const resp = await api.post('/search/artists', {query: {query: value}})
        setSearchData(resp.data)
    }

    return (
        <View>
            <SearchBar 
                placeholder="Search by artist name..."
                fontSize="16px"
                setData={setSearchData} 
                data={searchData} 
                searchAsType 
                fetch={fetch}
                searchFilters={searchFilters}
                setSearchFilters={setSearchFilters}
                isAISearch
            />
        </View>
    )
}

export default ArtistSearch