import styled from 'styled-components'


interface CreateMediaPostContainerProps {

}


export const StyledCreateMediaPostContainer  = styled.div<CreateMediaPostContainerProps>`

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {

    }
`

interface CreateMediaPost {

}

export const StyledCreateMediaPost = styled.div<CreateMediaPost>`
    display: block;
    width: 100%;

    @media (min-width: 768px) {
        display: inline-flex;
        gap: 24px;
    }

    @media (min-width: 1024px) {

    }
`

export const StyledPrivacyOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
  gap: 15px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledPrivacyOption = styled.div`
  flex: 1;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  background-color: #fff;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;

