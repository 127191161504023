import styled from 'styled-components'
import { Button } from '../../../components/Button'

export const StyledCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`

export const StyledCartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eee;
`

export const StyledCartItemsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0;
`

export const StyledCartItem = styled.div`
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #eee;
  align-items: center;
`

export const StyledCartItemImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 16px;
`

export const StyledCartItemInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledCartFooter = styled.div`
  padding: 16px;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledCheckoutButton = styled(Button)`
  width: 100%;
  padding: 12px;
  background: #000;
  color: white;
  border-radius: 24px;
  font-weight: 600;
  margin-top: 8px;
` 