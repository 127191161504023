import styled from 'styled-components'


interface ImageProps {
    maxHeight?: string;
    height?: string;
    width?: string;
    border?: string;
    objectFit?: string;
    borderRadius?: string;
    zoom?: number;
    aspectRatio?: string;
    isNoEffect?: boolean;
    display?: string;
}


export const StyledImage = styled.img<ImageProps>`
    display: ${props => props.display || 'block'};
    height: ${props => props.height || 'auto'};
    max-height: ${props => props.maxHeight || 'auto'};
    width: ${props => props.width || 'auto'};
    border: ${props => props.border || 'none'};
    object-fit: ${props => props.objectFit || 'cover'};
    border-radius: ${props => props.borderRadius || '0'};
    zoom: ${props => props.zoom || 1};
    aspect-ratio: ${props => props.aspectRatio || 'auto'};
    transition: ${props => props.isNoEffect ? 'none' : 'all 0.2s ease-in-out'};

    transform-origin: top left;
    display: block;

    @media (min-width: 768px) {
        height: ${props => props.height || 'auto'};
        width: ${props => props.width || 'auto'};
        transform: scale(${props => props.zoom || 1});
    }

    @media (min-width: 1024px) {

    }
`

export const StyledImageContainer = styled.div<ImageProps>`
    position: relative;
    height: ${props => props.height || 'auto'};
    width: ${props => props.width || 'auto'};
    border-radius: ${props => props.borderRadius || '0'};
    border: ${props => props.border || 'none'};
    display: flex;
    align-items: center;
    justify-content: center;
`