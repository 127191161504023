import { create } from 'zustand'


const _setTheme = (data: any) => {
  localStorage.setItem('theme', data)
  
  
  if (data === 'dark') {
    return {
      mode: 'dark',
      BackgroundColorTheme: '#1E1E1E',
      HoverPrimaryBackgroundColor: '#EFEFEF',
      CardPrimaryBackgroundColor: '#252526',
      BackgroundColorPrimary: '#252526',
      TextColorPrimary: '#FFFFFF',
      TextColorPrimaryLighter: '#737373',
      TextColorOffset: '#fafafa',
      FontFamilyPrimary: `"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif`,
      PlaceholderColor: '#64748B',
      PrimaryYellow: '#FAE04C',
      SearchBarBackgroundColor: '#f5f5f5',
      BorderColor: '#3A3A3A',
      ButtonBackgroundPrimary: '#0a0a0a',
      ButtonBackgroundPrimaryLight: '#EFEFEF',
    }
  }
  return {
    mode: 'light',
    BackgroundColorTheme: '#FCFBF8',
    BackgroundColorPrimary: '#FFFFFF',
    HoverPrimaryBackgroundColor: '#EFEFEF',
    CardPrimaryBackgroundColor: '#FFFFFF',
    TextColorPrimary: '#000',
    TextColorPrimaryLighter: '#737373',
    TextColorOffset: '#fafafa',
    FontFamilyPrimary: `"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif`,
    PlaceholderColor: '#64748B',
    PrimaryYellow: '#FAE04C',
    SearchBarBackgroundColor: '#f5f5f5',
    BorderColor: '#e5e5e5',
    ButtonBackgroundPrimary: '#0a0a0a',
    ButtonBackgroundPrimaryLight: '#EFEFEF',
  }
}

export const useThemeStore = create((set) => ({
  theme: _setTheme(localStorage.getItem('theme')),
  setTheme: (data: any) => set((state: any) => ({theme: (_setTheme(data))})),
}))