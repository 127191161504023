import styled from 'styled-components'



interface HeaderProps {

}

export const StyledHeader = styled.div<HeaderProps>`
    display: inline-flex;
    gap: 8px;
    width: 100%;
    width: max-content;
    padding: 12px 0px;
`