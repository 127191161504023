import { useState } from "react"
import { StyledCartContainer, StyledCartItemsContainer } from "./styled"
import CartHeader from "./CartHeader"
import CartItem from "./CartItem"
import CartFooter from "./CartFooter"

// Mock data for demonstration
const mockCartItems = [
  {
    id: "1",
    title: "Lot 217",
    subtitle: "Brooch, c. 1967",
    artist: "Alexander Calder",
    price: 5000,
    image: "/img/sample-art.jpg"
  },
  {
    id: "2",
    title: "Lot 217",
    subtitle: "Brooch, c. 1967",
    artist: "Alexander Calder",
    price: 5000,
    image: "/img/sample-art.jpg"
  },
  {
    id: "3",
    title: "Lot 217",
    subtitle: "Brooch, c. 1967",
    artist: "Alexander Calder",
    price: 5000,
    image: "/img/sample-art.jpg"
  }
]

const Cart = () => {
  const [cartItems, setCartItems] = useState(mockCartItems)
  
  const handleRemoveItem = (id: string) => {
    setCartItems(cartItems.filter(item => item.id !== id))
  }
  
  
  const subtotal = cartItems.reduce((sum, item) => sum + item.price, 0)
  
  return (
    <StyledCartContainer>
      <CartHeader 
        title={`Your Cart (${cartItems.length})`} 
      />
      
      <StyledCartItemsContainer>
        {cartItems.map(item => (
          <CartItem 
            key={item.id}
            item={item}
            onRemove={() => handleRemoveItem(item.id)}
          />
        ))}
      </StyledCartItemsContainer>
      
      <CartFooter 
        subtotal={subtotal}
      />
    </StyledCartContainer>
  )
}

export default Cart