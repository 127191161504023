import api from "../../services/api.service"
import { useModalStore, usePostStore, useResponsiveStore } from "../../stores/web.store"
import { Button } from "../Button"
import { Impressions } from "../Button/Impressions"
import { LikeButton } from "../Button/Like"
import { SaveButton } from "../Button/Save"
import { ShareButton } from "../Button/Share"
import { Comments } from "../Comments"
import { ImageGallery } from "../ImageGallery"
import { Text } from "../Text"
import { UserInfo } from "../UserInfo"
import { View } from "../View"
import { StyledCommentContainer, StyledDetails, StyledDetailsContainer, StyledPostDetails, StyledPostDetailsContainer, StyledPostPhotoContainer, StyledSettingsIconContainer } from "./styled"
import {ReactComponent as SettingsIcon} from '../../img/horizontalSettingsDots.svg'

import { useEffect, useRef, useState } from "react"
import { Markdown } from "../MarkDown"
import { $createParagraphNode, $getRoot } from "lexical"

import { Follow } from "../Button/Follow"

import { useViewed } from "../../utils/analytics.util"
import { AccountActions } from "../Profile/AccountActions"
import { ProfilePicture } from "../ProfilePicture"


type PostDetialsProps = {
    data?: any;
    isModal?: boolean;
    posts?: any;
    modalDimensions?: any;
    setModalDimensions?: any;
}


const AccountActionsModal = ({setIsModal}: any) => {
    return (
        <AccountActions setIsModal={setIsModal} />
    )
}


const PostDetails = ({data, isModal, posts, modalDimensions, setModalDimensions}: PostDetialsProps) => {
    const [comment, setComment] = useState<any>({})
    const post = usePostStore((state: any) => state.post)
    const setPost = usePostStore((state: any) => state.setPost)
    const modalKey = useModalStore((state: any) => state.modalKey)
    const width = useResponsiveStore((state: any) => state.width)
    const setModalKey = useModalStore((state: any) => state.setModalKey)

    const [isSettingsModal, setIsSettingsModal] = useState(false)
    const settingsRef = useRef<HTMLDivElement>(null);

    const editorStateRef = useRef<any>();

    useViewed({
        source: 'post',
        source_id: data?.id
    });

    const onChangeEditorState = (editorState: any) => {
        
        editorStateRef.current = editorState
        const msg = editorState.read(() => $getRoot().getTextContent())
        setComment({
            comment: msg,
            lexical_comment: JSON.stringify(editorStateRef.current)

        })

    }
    
    const onClickSend = async () => {
        const resp = await api.post('/comments', {
            ...comment,
            source: post.type,
            source_id: post.id

        })
        setPost({
            ...post,
            comments: [
                ...post.comments,
                resp.data
            ]
        })

        if (editorStateRef.current) {
            editorStateRef.current.update(() => {
                const root = $getRoot();
                root.clear();
                root.append($createParagraphNode());
            });
        }
        
    }

    const handleEnterCommand = async (event: any, editor: any) => {
        event.preventDefault();
        // const serializedContent = $generateHtmlFromNodes(editor, null);

        const resp = await api.post('/comments', {
            ...comment,
            source: post.type,
            source_id: post.id
        })
        
        setPost({
            ...post,
            comments: [
                ...post.comments,
                resp.data
            ]
        })
        return true
    };

    const onClickSettings = () => {
        setIsSettingsModal(!isSettingsModal)
    }
    

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (settingsRef.current && !settingsRef.current.contains(event.target as Node)) {
                setIsSettingsModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    console.log((comment.comment || '').length)

    return (
        <StyledPostDetailsContainer>
            <StyledPostDetails isPost={modalKey === 'post'} modalDimension={modalDimensions}>
                {width <= 768 && <View padding="48px 8px 12px 8px">
                    <View display="inline-flex" alignItems="center" width="100%" gap="24px">
                        <View width="70%">
                            <UserInfo account={post.owner}/>
                        </View>
                        <View textAlign="right" width="30%">
                            <Follow data={post.owner} noStyle/>
                        </View>
                    </View>
                </View>}
                <StyledPostPhotoContainer>
                    <ImageGallery 
                        modalDimensions={modalDimensions} 
                        setModalDimensions={setModalDimensions} 
                        data={post} height={modalKey === 'post' ? '600px' : "auto"} 
                        isModal={isModal} 
                        objectFit={modalKey === 'post' ? modalDimensions.objectFit : 'contain'} 
                    />
                </StyledPostPhotoContainer>
                {modalKey === 'post' && <StyledDetailsContainer>
                    <StyledDetails>
                        <View padding="12px 24px 0px 12px">
                            {width > 768 && <View padding="0px 0px 4px 0px">
                                <View display="inline-flex" alignItems="center" width="100%" gap="0px">
                                    <View flexGrow="1">
                                        <ProfilePicture account={post.owner} username photo height="40px" width="40px" fontSize="14px"/>
                                    </View>
                                    <View width="max-content">
                                        <View 
                                            display='inline-flex' 
                                            alignItems='center' 
                                            justifyContent='flex-end'
                                            position="relative"
                                            gap="12px"
                                            ref={settingsRef}
                                        >
                                            <Follow data={post.owner} />
                                            <StyledSettingsIconContainer 
                                                onClick={onClickSettings}
                                                isActive={isSettingsModal}
                                            >
                                                <SettingsIcon height={20} width={20}/>
                                            </StyledSettingsIconContainer>
                                            {isSettingsModal && <AccountActionsModal setIsModal={setIsSettingsModal}/>}
                                        </View>
                                    </View>
                                </View>
                            </View>}
                            
                        </View>
                        <View padding="12px">
                            <Text fontWeight="500" fontSize="16px">{`Comments (${post.comments?.length})`}</Text>
                        </View>
                        <View padding="0px 12px">
                            <Comments post={post}/>
                        </View>
                        
                        <StyledCommentContainer>
                            <View padding="0px 12px 12px 12px">
                                <View padding="4px 0px">
                                    <View display="inline-flex" alignItems="center" width="100%">
                                        <View display="inline-flex" alignItems="center" width="100%" gap="12px">
                                            <LikeButton data={post}/>
                                            <Impressions data={post} />
                                        </View>
                                        <View display="inline-flex" alignItems="center" width="100%" justifyContent="right" gap="12px">
                                            <SaveButton data={post} noTitle/>
                                            <ShareButton data={post} noTitle/>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View position="relative" padding="0px 12px">
                                {!post.privacy_settings.is_comments_disabled ? <View display="inline-flex" alignItems="center" width="100%">
                                    <View flexGrow="1" key={`postDetails-${post.id}`}>
                                        <View display='inline-flex' alignItems='center' gap='4px' width='100%'>
                                            <View width='40px'>
                                                <ProfilePicture 
                                                    account={post.owner}
                                                    photo 
                                                    height='40px'
                                                    width='40px'
                                                />
                                            </View>

                                            <View flexGrow='1'>
                                                <Markdown
                                                    uid={`postDetails-${post.id}`}
                                                    editorStateRef={editorStateRef} 
                                                    data={null} 
                                                    singleLine 
                                                    onChange={onChangeEditorState} 
                                                    placeholder={'Write a comment'} 
                                                    onClickSend={onClickSend}
                                                    border="solid 1px #e5e5e5"
                                                    borderRadius="24px"
                                                    noEmoji
                                                    handleEnterCommand={handleEnterCommand}
                                                    editable
                                                    noTools
                                                />
                                            </View>
                                            <Button 
                                                background={(comment.comment || '').length === 0 ? "rgba(132, 132, 132, 0.5)" : "#848484"}
                                                padding="10px 12px" 
                                                borderRadius="24px"
                                                onClick={onClickSend} 
                                                disabled={(comment.comment || '').length === 0}
                                                noStyle
                                            >
                                                <Text color="#fff">Send</Text>
                                            </Button>                            
                                        </View>
                                    </View>
                                    
                                    </View> : <View>
                                    <Text padding="0px 0px 24px 0px">Commenting Disabled</Text>    
                                </View>}
                            </View>
                        </StyledCommentContainer> 
                    </StyledDetails>
                </StyledDetailsContainer>}
            </StyledPostDetails>
        </StyledPostDetailsContainer>
    )
}

export default PostDetails