import { useState } from "react";
import { 
  StyledStoryCreationContainer, 
  StyledStoryCreationHeader, 
  StyledStoryCreationContent,
  StyledStoryCreationFooter
} from "./styled";
import { useThemeStore } from "../../stores/theme.store";
import { View } from "../View";
import { Text } from "../Text";
import { Button } from "../Button";
import { ReactComponent as XIcon } from "../../img/x.svg";
import { StoryMedia } from "./Stories";

interface StoryCreatorProps {
  onClose: () => void;
  onSubmit: (media: StoryMedia[]) => void;
}

export const StoryCreator = ({ onClose, onSubmit }: StoryCreatorProps) => {
  const theme = useThemeStore((state: any) => state.theme);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      setSelectedFiles(prev => [...prev, ...files]);
      
      // Create preview URLs
      const newPreviewUrls = files.map(file => URL.createObjectURL(file));
      setPreviewUrls(prev => [...prev, ...newPreviewUrls]);
    }
  };

  const handleSubmit = () => {
    // In a real app, you would upload the files to a server here
    // For now, we'll just use the preview URLs as placeholders
    const media: StoryMedia[] = previewUrls.map(url => ({
      type: 'image',
      url,
      createdAt: new Date().toISOString()
    }));
    
    onSubmit(media);
  };

  const removeFile = (index: number) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
    
    // Revoke the object URL to avoid memory leaks
    URL.revokeObjectURL(previewUrls[index]);
    setPreviewUrls(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <StyledStoryCreationContainer theme={theme}>
      <StyledStoryCreationHeader theme={theme}>
        <Button onClick={onClose} noStyle>
          <XIcon style={{ width: "24px", height: "24px" }} />
        </Button>
        <Text fontWeight="bold">Create Story</Text>
        <View width="24px" /> {/* Empty space for balance */}
      </StyledStoryCreationHeader>
      
      <StyledStoryCreationContent theme={theme}>
        {previewUrls.length > 0 ? (
          <View 
            display="flex" 
            flexDirection="column" 
            gap="16px" 
            width="100%"
            maxWidth="400px"
          >
            {previewUrls.map((url, index) => (
              <View key={index} position="relative">
                <img 
                  src={url} 
                  alt={`Preview ${index}`} 
                  style={{ 
                    width: '100%', 
                    borderRadius: '8px',
                    maxHeight: '300px',
                    objectFit: 'contain'
                  }} 
                />
                <Button 
                  position="absolute" 
                  top="8px" 
                  right="8px"
                  onClick={() => removeFile(index)}
                  noStyle
                >
                  <View 
                    width="24px" 
                    height="24px" 
                    borderRadius="50%" 
                    background="rgba(0, 0, 0, 0.5)"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <XIcon style={{ width: "16px", height: "16px", stroke: "white" }} />
                  </View>
                </Button>
              </View>
            ))}
          </View>
        ) : (
          <View 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            gap="16px"
          >
            <Text>Select photos or videos to add to your story</Text>
            <label>
              <Button 
                padding="12px 24px"
                borderRadius="8px"
                background={theme.PrimaryYellow}
                noStyle
              >
                <Text color="black" fontWeight="bold">Select from device</Text>
              </Button>
              <input 
                type="file" 
                accept="image/*,video/*" 
                multiple 
                onChange={handleFileChange} 
                style={{ display: 'none' }} 
              />
            </label>
          </View>
        )}
      </StyledStoryCreationContent>
      
      <StyledStoryCreationFooter theme={theme}>
        <View /> {/* Empty space for balance */}
        {previewUrls.length > 0 && (
          <Button 
            padding="12px 24px"
            borderRadius="8px"
            background={theme.PrimaryYellow}
            onClick={handleSubmit}
            noStyle
          >
            <Text color="black" fontWeight="bold">Share to Story</Text>
          </Button>
        )}
      </StyledStoryCreationFooter>
    </StyledStoryCreationContainer>
  );
};
