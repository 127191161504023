import React from 'react';
import { format, parseISO } from 'date-fns';
import { View } from "../../../components/View";
import { usePostStore, useProductDetailPageStore } from "../../../stores/web.store";
import { useThemeStore } from '../../../stores/theme.store';
import { ThemeProvider } from 'styled-components';
import {ReactComponent as ChevronLeftIcon} from '../../../img/chevronLeft.svg';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import {
  StyledContainer,
  StyledImageSection,
  StyledInfoSection,
  StyledHeader,
  StyledArtist,
  StyledTitle,
  StyledDateLocation,
  StyledPriceSection,
  StyledPriceRow,
  StyledPriceLabel,
  StyledPrice,
  StyledEstimateChange,
  StyledDetailsGrid,
  StyledDetailLabel,
  StyledDetailValue,
} from './styled';

const SaleArtwork = () => {
  const post = usePostStore((state: any) => state.post);
  const setPost = usePostStore((state: any) => state.setPost);
  const setPdp = useProductDetailPageStore((state: any) => state.setPdp);
  const artwork = post?.artwork || {};
  const theme = useThemeStore((state: any) => state.theme);

  const calculateEstimateChange = () => {
    if (!post.highest_bid_amount_cents || !post.high_estimate_cents) return null;
    const change = ((post.highest_bid_amount_cents - post.high_estimate_cents) / post.high_estimate_cents) * 100;
    return {
      value: Math.abs(change).toFixed(0),
      isPositive: change >= 0
    };
  };

  const formattedDate = post.created_at ? format(parseISO(post.created_at), 'MMM dd, yyyy') : '';
  const estimateChange = calculateEstimateChange();

  const onClickBack = () => {
    setPost(null);
    setPdp(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <View>
        <Button noStyle onClick={onClickBack}>
          <ChevronLeftIcon height={16} width={16}/> 
          <Text fontWeight="500">Back to {}</Text>
        </Button>
      </View>
      <StyledContainer>
        <StyledImageSection>
          <img 
            src={artwork.images?.[0]?.image_urls?.main || artwork.images?.[0]?.image_urls?.large || artwork.images?.[0]?.image_urls?.four_thirds || artwork.images?.[0]?.image_urls?.medium || artwork.images?.[0]?.image_urls?.small || artwork.images?.[0]?.image_urls?.square || artwork.images?.[0]?.image_urls?.tall } 
            alt={artwork.title} 
          />
        </StyledImageSection>

        <StyledInfoSection>
          <StyledHeader>
            <StyledArtist>{artwork.artist?.name || 'Leonardo da Vinci'}</StyledArtist>
            <StyledTitle>{artwork.title || 'A rearing horse'}</StyledTitle>
            <StyledDateLocation>{formattedDate} • Christie's</StyledDateLocation>
          </StyledHeader>

          <StyledPriceSection>
            <StyledPriceRow>
              <StyledPriceLabel>Sale Price</StyledPriceLabel>
              <StyledPrice>
                {post.display_highest_bid_amount_dollars || 
                 (post.display_low_estimate_dollars && post.display_high_estimate_dollars ? 
                   `${post.display_low_estimate_dollars}–${post.display_high_estimate_dollars} (est)` : 
                   'Price not available'
                 )
                }
              </StyledPrice>
              {estimateChange && (
                <StyledEstimateChange isPositive={estimateChange.isPositive}>
                  {estimateChange.isPositive ? '+' : '-'}{estimateChange.value}% est
                </StyledEstimateChange>
              )}
            </StyledPriceRow>
            <StyledPriceRow>
              <StyledPriceLabel>Pre-sale estimate</StyledPriceLabel>
              <StyledPrice>
                {post.display_low_estimate_dollars && post.display_high_estimate_dollars && 
                  `${post.display_low_estimate_dollars}–${post.display_high_estimate_dollars} (est)`
                }
              </StyledPrice>
            </StyledPriceRow>
          </StyledPriceSection>

          <StyledDetailsGrid>
            <StyledDetailLabel>Medium</StyledDetailLabel>
            <StyledDetailValue>
              {artwork.medium || 'bronze, on an associated ebonised wood base'}
            </StyledDetailValue>
            
            <StyledDetailLabel>Dimensions</StyledDetailLabel>
            <StyledDetailValue>
              {artwork.dimensions?.in || '14.0 cm'}
            </StyledDetailValue>
            
            <StyledDetailLabel>Sale Date</StyledDetailLabel>
            <StyledDetailValue>{formattedDate || 'Jul 02, 2024'}</StyledDetailValue>
            
            <StyledDetailLabel>Auction house</StyledDetailLabel>
            <StyledDetailValue>Christie's</StyledDetailValue>
            
            <StyledDetailLabel>Sale location</StyledDetailLabel>
            <StyledDetailValue>---</StyledDetailValue>
            
            <StyledDetailLabel>Sale name</StyledDetailLabel>
            <StyledDetailValue>
              Live Auction 22669
              <div>Old Masters Part II: Paintings, Sculpture, Drawings and Watercolours</div>
            </StyledDetailValue>
            
            <StyledDetailLabel>Lot</StyledDetailLabel>
            <StyledDetailValue>{post.lot_number || '166'}</StyledDetailValue>
          </StyledDetailsGrid>
        </StyledInfoSection>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default SaleArtwork;