import { useState, useEffect, useRef } from "react";
import { 
  StyledStoryViewerContainer, 
  StyledStoryContent, 
  StyledStoryHeader,
  StyledStoryProgressContainer,
  StyledStoryProgressBar,
  StyledStoryImage2,
  StyledStoryControls,
  StyledStoryControlPrev,
  StyledStoryControlNext
} from "./styled";
import { useThemeStore } from "../../stores/theme.store";
import { View } from "../View";
import { Text } from "../Text";
import { Button } from "../Button";
import { ReactComponent as XIcon } from "../../img/x.svg";
import { Story } from "./Stories";

interface StoryViewerProps {
  story: Story;
  onClose: () => void;
}

export const StoryViewer = ({ story, onClose }: StoryViewerProps) => {
  const theme = useThemeStore((state: any) => state.theme);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [direction, setDirection] = useState<'next' | 'prev' | 'none'>('none');
  const [animationState, setAnimationState] = useState<'entering' | 'exiting' | 'active'>('active');
  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const animationTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const storyDuration = 5000; // 5 seconds per story
  const progressUpdateInterval = 100; // Update progress every 100ms
  const progressIncrement = (progressUpdateInterval / storyDuration) * 100;
  const animationDuration = 400; // 400ms for animation

  useEffect(() => {
    startProgressTimer();
    
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, [currentIndex]);

  const startProgressTimer = () => {
    setProgress(0);
    
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }
    
    progressIntervalRef.current = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress + progressIncrement;
        
        if (newProgress >= 100) {
          goToNextStory();
          return 0;
        }
        
        return newProgress;
      });
    }, progressUpdateInterval);
  };

  const goToPrevStory = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }
    
    if (currentIndex > 0) {
      setDirection('prev');
      setAnimationState('exiting');
      
      animationTimeoutRef.current = setTimeout(() => {
        setCurrentIndex(currentIndex - 1);
        setAnimationState('entering');
        
        animationTimeoutRef.current = setTimeout(() => {
          setAnimationState('active');
        }, animationDuration);
      }, animationDuration);
    } else {
      onClose();
    }
  };

  const goToNextStory = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }
    
    if (currentIndex < story.media.length - 1) {
      setDirection('next');
      setAnimationState('exiting');
      
      animationTimeoutRef.current = setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
        setAnimationState('entering');
        
        animationTimeoutRef.current = setTimeout(() => {
          setAnimationState('active');
        }, animationDuration);
      }, animationDuration);
    } else {
      onClose();
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      goToPrevStory();
    } else if (e.key === 'ArrowRight') {
      goToNextStory();
    } else if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentIndex]);

  const currentMedia = story.media[currentIndex];

  return (
    <StyledStoryViewerContainer theme={theme}>
      <StyledStoryContent theme={theme}>
        <StyledStoryHeader theme={theme}>
          <View display="flex" alignItems="center" gap="12px">
            <View 
              width="32px" 
              height="32px" 
              borderRadius="50%" 
              overflow="hidden"
            >
              <img 
                src={story.userImage} 
                alt={story.username} 
                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
              />
            </View>
            <Text color="white">{story.username}</Text>
          </View>
          <Button onClick={onClose} noStyle>
            <XIcon style={{ stroke: "white", width: "24px", height: "24px" }} />
          </Button>
        </StyledStoryHeader>
        
        <StyledStoryProgressContainer>
          {story.media.map((_, index) => (
            <StyledStoryProgressBar 
              key={index}
              isActive={index === currentIndex}
              isPast={index < currentIndex}
            >
              {index === currentIndex && (
                <div 
                  style={{ 
                    height: '100%', 
                    width: `${progress}%`, 
                    background: 'white',
                    transition: 'width 0.1s linear'
                  }} 
                />
              )}
            </StyledStoryProgressBar>
          ))}
        </StyledStoryProgressContainer>
        
        <StyledStoryImage2 
          isActive={true}
          direction={direction}
          state={animationState}
        >
          {currentMedia.type === 'image' ? (
            <img src={currentMedia.url} alt="Story" />
          ) : (
            <video 
              src={currentMedia.url} 
              autoPlay 
              muted 
              style={{ width: '100%', height: '100%', objectFit: 'contain' }} 
            />
          )}
        </StyledStoryImage2>
        
        <StyledStoryControls>
          <Button 
            as={StyledStoryControlPrev} 
            onClick={goToPrevStory}
            isNoEffect
            noStyle
          />
          <Button 
            as={StyledStoryControlNext} 
            onClick={goToNextStory}
            isNoEffect
            noStyle
          />
        </StyledStoryControls>
      </StyledStoryContent>
    </StyledStoryViewerContainer>
  );
};
