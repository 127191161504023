import React from 'react';
import { ReactComponent as EyeIcon } from '../../../icons/eye.svg';
import { ReactComponent as EnterShowIcon } from '../../../icons/enterShow.svg';
import { View } from '../../View';
import { Text } from '../../Text';

interface ShowEntryUIProps {
  onClick?: () => void;
}

/**
 * ShowEntryUI component displays the "Enter Show" button and center icon for show posts
 */
const EnterShowLabel: React.FC<ShowEntryUIProps> = ({ onClick }) => {
  return (
    <>
      <View 
        position="absolute" 
        bottom="24px" 
        right="20px" 
        zIndex="3"
        background="white" 
        padding="6px 12px" 
        borderRadius="20px"
        display="flex"
        alignItems="center"
        gap="8px"
      >
        <EyeIcon width={20} height={20} />
        <Text fontSize="14px" fontWeight="500">Enter Show</Text>
      </View>
      <View 
        position="absolute" 
        top="50%" 
        left="50%" 
        transform="translate(-50%, -50%)" 
        zIndex="2"
      >
        <EnterShowIcon width={60} height={60} />
      </View>
    </>
  );
};

export default EnterShowLabel;
