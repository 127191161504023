import {  useEffect, useRef, useState } from "react"
import { StyledModal, StyledModalContainer, StyledModalContent, StyledModalContentContainer } from "./styled"
import { useComponentVisible } from "../../../utils/app.util"
import { Text } from "../../Text";
import { View } from "../../View";
import { Button } from "../../Button";
import {ReactComponent as XIcon} from '../../../img/x.svg'
import { SearchBar } from "../../SearchBar";
import { ProfilePicture } from "../../ProfilePicture";
import { Input } from "../../Input";
import { Checkbox } from "../../Checkbox";
import { useThreadsStore, useThreadStore, useViewNavigatorStore } from "../../../stores/web.store";
import api from "../../../services/api.service";


type MessageModalProps = {
    setModal: any;
    setIsActive: any;
    position?: string;
    height?: any;
    width?: any;
    modalHeight?: any;
    top?: string;
    left?: string;
    margin?: any;
    padding?: string;
    background?: string;
    borderRadius?: string;
}

const useOutsideAlerter = (ref: any, setModal: any) => {
    const setThread = useThreadStore((state: any) => state.setThread)
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(false)
            setThread(null)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const Recipient = () => {
    return (
        <View></View>
    )
}

const UserResult = ({setThread, thread, user}: any) => {
    const [isChecked, setIsChecked] = useState(false)

    const onChange = (e: any) => {
        if (!isChecked) {
            if (thread.recipients?.filter((res: any) => res.id === user.id).length === 0) {
                setThread({...thread, recipients: [...thread.recipients, user]})
            }
        } else {
            setThread({...thread, recipients: thread.recipients.filter((res: any) => res.id !== user.id)})
        }
        setIsChecked(!isChecked)

    }

    const onClickProfile = () => {
        if (isChecked) {
            setThread({...thread, recipients: thread.recipients.filter((res: any) => res.id !== user.id)})
            
        } else {
            if (thread.recipients?.filter((res: any) => res.id === user.id).length === 0) {
                setThread({...thread, recipients: [...thread.recipients, user]})
            }
        }
        setIsChecked(!isChecked)
    }

    return (
        <View display="inline-flex" alignItems="center" width="100%">
            <View flexGrow="1">
                <ProfilePicture account={user} username photo role onClick={onClickProfile}/>

            </View>
            <View width="40px" textAlign="right">
                <Checkbox rounded onChange={onChange} checked={isChecked}/>
            </View>
        </View>
    )
}


const MessageModal = ({setModal, setIsActive, position, height, width, modalHeight, top, left, margin, background, padding, borderRadius}: MessageModalProps) => {
    const { ref } = useComponentVisible(setModal);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [users, setUsers] = useState<any>([])
    const [filteredUsers, setFilteredUsers] = useState<any>([])
    const setThread = useThreadStore((state: any) => state.setThread)
    const thread = useThreadStore((state: any) => state.thread)
    const setThreads = useThreadsStore((state: any) => state.setThreads)
    const threads = useThreadsStore((state: any) => state.threads)
    const activeView = useViewNavigatorStore((state: any) => state.activeView)

    useOutsideAlerter(wrapperRef, setModal);

    const onClickChat = async () => {
        setModal(false)
        setIsActive(true)

        const payload = {
            recipients: thread.recipients.map((recipient: any) => recipient.id),
            type: activeView === 'all' ? 'direct_message' : activeView,
            source: "thread",
            source_id: thread.id

        }

        const resp = await api.post('/threads', payload)
        setThreads([...threads, resp.data])
        
    }

    const onClickClose = () => {
        setThread(null)
        setModal(false)
    }

    return (
        <StyledModalContainer position={position} top={top} left={left}  ref={ref}>
            <StyledModal width={width} height={modalHeight} margin={margin}>
                <StyledModalContentContainer>
                    <StyledModalContent height={height} background={background} padding={padding} borderRadius={borderRadius} ref={wrapperRef}>
                        <View display="flex" flexDirection="column" gap="24px" width="100%" textAlign="center" padding="24px 0px">
                            <Text fontSize="20px" fontWeight="500" width="100%">New Message</Text>
                            <SearchBar 
                                setData={setUsers} 
                                width="100%" 
                                placeholder="Search" 
                                borderRadius="0" 
                                prefix="To:"
                            />            
                        </View>
                        <View minHeight="400px" width="100%">
                            <View display="flex" flexDirection="column" gap="12px">
                                {users.map((user: any) => {
                                    return (
                                        <View padding="0px 12px" key={`cb-message-${user.id}`}>
                                            <UserResult setThread={setThread} thread={thread} user={user} />
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                        <View width="100%">
                            <View padding="24px">
                                <Button
                                    border={`solid 1px ${'#BFCBFB'}`}
                                    background={'#DDE3FD'}
                                    color={'#0938F0'}
                                    padding="8px 20px"
                                    borderRadius="23px"
                                    fontSize="16px"
                                    width="100%"
                                    onClick={onClickChat}
                                    noStyle
                                >Chat</Button>
                            </View>
                        </View>
                        
                    </StyledModalContent>
                    <View position="absolute" top="20px" right="12px">
                        <Button noStyle onClick={onClickClose}><XIcon height={32} width={32}/></Button>
                    </View>
                </StyledModalContentContainer>
            </StyledModal>
        </StyledModalContainer>
    )
}

export default MessageModal